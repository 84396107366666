import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        background: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        // border: `1px solid #f3f3f3`
    }
}))

export default function WhiteContainer(props) {
    const classes = useStyles();

    return (
        <Grid className={classes.root} item xs={12}>
            {props.children}
        </Grid>
    )
}