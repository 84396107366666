import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import logo from '../../assets/orange-nano-icon.png'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto'
    },
    icon: {
        height: '30px',
        margin: theme.spacing(1),
        animation: `$rotate 1.5s linear infinite both`
    },
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    }
}));

export default function SpinningLoadingLogo() {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <img alt="nanocosmos-icon" src={logo} className={classes.icon} />
        </div>
    );
}
