import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Collapse, Toolbar } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';
import content from '../../utilities/helper/content';

import logo from '../../assets/white-logo.png';
import RedirectLink from '../../components/global/RedirectLink';
import PageHeader from '../../components/global/PageHeader';
import Player from './Player';
import Title from '../global/Title';
import SwitchStream from './SwitchStream';
import StreamInfoSection from './StreamInfoSection';
import TranscodedStreamInfoSection from './TranscodedStreamInfoSection';
import CouldNotFind from '../global/CouldNotFind';
import TokenExpirationInfo from '../player/TokenExpirationInfo';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        root: {
            display: 'flex',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerToggleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    logo: {
        [theme.breakpoints.up('xs')]: {
            "&:hover": {
                cursor: 'pointer'
            },
            height: '30px',
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: '100%',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('md')]: {
        },
    },
}))

export default function ExternalPlayer(props) {
    const classes = useStyles();
    let streams = props.streams;
    let secureToken = props.secureToken;
    let transcodingOptions = props.transcodingOptions;
    let startIndex = props.startIndex;

    const openInNewTab = (link) => (event) => {
        window.open(link, '_blank')
    }

    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <AppBar elevation={0} position="fixed" >
                    <Toolbar >
                        <div className={classes.drawerToggleWrapper}>
                            <RedirectLink to={link.DASHBOARD}>
                                <img
                                    alt="nanocosmos-logo"
                                    className={classes.logo}
                                    src={logo}
                                />
                            </RedirectLink>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            <div className={classes.content}>
                <Collapse in={streams.length > 0 && streams[0].state === "live"}>
                    <PageHeader title={content.playout.title} />
                    <Title
                        title={`${streams.length > 1 ? content.playout.player.title.transcoding : content.playout.player.title.singleStream}`}
                        button={content.playout.player.title.docs}
                        endIcon={<OpenInNew />}
                        clicked={openInNewTab(link.H5LIVE_DOCS)}
                    />
                    <Player streams={streams} secureToken={secureToken} />
                    <Collapse in={!(props.expirationDate.length === 0)}>
                        <TokenExpirationInfo expiration={props.expirationDate} />
                    </Collapse>
                    {
                        streams.length > 1 && streams[0].state === "live"
                            ?
                            <div>
                                <Title
                                    title={content.playout.abr.controls.title}
                                />
                                <SwitchStream
                                    startIndex={startIndex}
                                    streams={streams}
                                    transcodingOptions={transcodingOptions}
                                />
                            </div>
                            : null
                    }
                    {
                        streams.length === 1 && streams[0].state === "live"
                            ?
                            <div>
                                {
                                    streams.map((stream, index) => (
                                        <StreamInfoSection key={index} stream={stream} />
                                    ))
                                }
                            </div>
                            : null
                    }
                    {
                        streams.length > 1 && streams[0].state === "live"
                            ? <TranscodedStreamInfoSection
                                streams={streams}
                                transcodingOptions={transcodingOptions}
                            />
                            : null
                    }
                </Collapse>
                <Collapse in={streams.length < 1 || !(streams[0].state === "live")}>
                    <CouldNotFind
                        title={`${streams.length < 1 ? content.playout.player.notFound.notFound : content.playout.player.notFound.notLive}`}
                    />
                </Collapse>
            </div>
        </div>
    )
}