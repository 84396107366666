import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1)
    }
}));

export default function OutlinedTextfield(props) {
    const classes = useStyles();
    const [helperText] = useState(`Please enter a valid ${props.label}.`)
    const [icon] = useState(
        props.icon
            ?
            {
                startAdornment:
                    (<InputAdornment position="start">
                        {props.icon}
                    </InputAdornment>)
            }
            : {}
    );

    const handleKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: props.submit(event); break;
            default: break;
        }
    }

    return (
        <div className={classes.root}>
            <TextField
                color="primary"
                autoComplete={props.autoComplete}
                id={props.autoComplete}
                label={props.label}
                fullWidth
                InputProps={icon}
                error={props.error}
                helperText={props.error ? helperText : ""}
                type={props.type ? props.type : "text"}
                onChange={props.changed}
                onBlur={props.blured}
                onKeyPress={handleKeyPressed}
                disabled={props.disabled}
                value={props.value}
            />
        </div>
    );
}
