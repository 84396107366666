import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Context from '../../utilities/context/Context';
import * as type from '../..//utilities/context/type';
import * as link from '../../utilities/helper/link-config';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function Logout() {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);
    const [redirectRoute, setRedirectRoute] = useState("");

    const redirect = (link) => {
        setRedirectRoute(<Redirect push to={link} />);
    }

    useEffect(() => {
        if(!state.access) {
            redirect(link.AUTH)
        }
    }, [state.access])

    useEffect(() => {
        dispatch({ type: type.ACCESS, access: false})
        localStorage.clear();
    }, [])

    return (
        <div className={classes.root}>
            {redirectRoute}
        </div>
    );
}
