import React from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icon } from '@material-ui/core';
import { Info, Lock } from '@material-ui/icons';

import content from '../../utilities/helper/content';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    titleWrapper: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center'
    },
    title: {
        marginLeft: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        fontWeight: 700,
        lineHeight: 1.2
    },
    bold: {
        fontWeight: 900
    }
}))

export default function TokenExpirationInfo(props) {
    const classes = useStyles();

    let now = moment(new Date()).format("MM/DD/YYYY hh:mm");
    let expires = props.expiration;
    let hoursLeft = moment.duration(moment(expires).diff(now))._data;

    console.log(hoursLeft)

    return (
        <ContentContainer>
            <div className={classes.titleWrapper}>
                <Icon>
                    <Lock />
                </Icon>
                <Typography color="primary" variant="h6" className={classes.title}>
                    {content.playout.player.expirationInfo.title}
                </Typography>
            </div>
            <Typography color="secondary" variant="body1">
                {content.playout.player.expirationInfo.underline} {props.expiration} <span className={classes.bold}>{hoursLeft.seconds !== undefined ? `${hoursLeft.days > 0 ? `(${hoursLeft.days} day${hoursLeft.days === 1 ? "" : "s"} left)` : `(${hoursLeft.hours} hour${hoursLeft.hours === 1 ? "" : "s"} and ${hoursLeft.minutes} minute${hoursLeft.minutes === 1 ? "" : "s"} left)`}` : ""}</span>
            </Typography>
        </ContentContainer>
    )
}