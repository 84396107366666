import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TablePagination, Typography } from '@material-ui/core';

import Context from '../../utilities/context/Context';
import content from '../../utilities/helper/content';
import * as type from '../../utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import Tag from '../global/Tag';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    tagWrapper: {
        borderRadius: theme.spacing(0.5)
    },
}))

export default function Tags(props) {
    const classes = useStyles();
    const { dispatch } = useContext(Context);
    const [tags, setTags] = useState([]);
    const [tagsCount, setTagsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [quantity, setQuantity] = useState(25);

    const fetchTags = (offset, limit) => {
        bintu.getTagCollection(localStorage.getItem(localStorageHelper.BINTU_APIKEY), offset, limit,
            (success) => {
                setTags(success.tags);
                setTagsCount(success.overall_tag_count);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const handleTagClicked = (tag) => {
        if (props.clickedTag) {
            props.clickedTag(tag);
        }
    }

    const handleChangedPage = (event, newPage) => {
        fetchTags(newPage * quantity, quantity);
        setPage(newPage);
    }


    const handleChangedRowsPerPage = (event) => {
        setQuantity(event.target.value);
        setPage(0);
        fetchTags(0, event.target.value);
    }

    useEffect(() => {
        if (props.refresh) {
            fetchTags(0, quantity);
        }
    }, [props.refresh])

    useEffect(() => {
        fetchTags(0, quantity)
    }, [])

    return (
        <div className={classes.root}>
            {
                tags.length === 0
                    ?
                    <Typography variant="button" color="textSecondary">
                        {content.organisation.tags.noTags}
                    </Typography>
                    :
                    <div className={classes.tagWrapper}>
                        {
                            tags.map((tag, index) => (
                                // <RedirectLink
                                //     key={index}
                                //     to={{
                                //         pathname: link.STREAMS,
                                //         search: `?tag=${tag.tag}`
                                //     }}>
                                <Tag
                                    key={index}
                                    tag={tag.tag}
                                    handleClicked={handleTagClicked}
                                />
                                // </RedirectLink>
                            ))
                        }
                        <TablePagination
                            component="div"
                            className={classes.pagination}
                            rowsPerPageOptions={[15, 25, 35, 45]}
                            count={tagsCount}
                            rowsPerPage={quantity}
                            page={page}
                            onChangePage={handleChangedPage}
                            onChangeRowsPerPage={handleChangedRowsPerPage}
                        />
                    </div>
            }
        </div>
    )
}