import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';

import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import DividerTitle from '../global/DividerTitle';
import OutlinedTextfield from '../registration/OutlinedTextfield';
import content from '../../utilities/helper/content';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    textfieldWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}))

export default function CreateTag(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const [textfield, setTextfield] = useState({
        value: "",
        id: "tag",
        label: content.createTag.textfieldLabel,
        type: "text",
        icon: <LocalOffer color="primary" />,
        error: false,
    })

    const setValue = (event) => {
        setTextfield({
            ...textfield,
            value: event.target.value
        });
    }

    const handleSubmit = (event) => {
        createNewTag(event);
    }

    const createNewTag = (event) => {
        if (textfield.value.length === 0) return;
        bintu.createTag(apikey, textfield.value,
            () => {
                props.updatedTags(true);
                setTextfield({
                    ...textfield,
                    value: ""
                });
            }, () => {
                props.updatedTags(false);
            });
    }

    return (
        <ContentContainer>
            <DividerTitle title={content.createTag.title} />
            <div className={classes.textfieldWrapper}>
                <OutlinedTextfield
                    id={textfield.id}
                    type={textfield.type}
                    label={textfield.label}
                    error={textfield.error}
                    icon={textfield.icon}
                    changed={setValue}
                    submit={handleSubmit}
                />
            </div>
            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={createNewTag}
            >
                {content.createTag.submit}
            </Button>
        </ContentContainer>
    )
}