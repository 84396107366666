import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        paddingLeft: theme.spacing(2),
        background: theme.palette.grey[100],
    },
    heading: {
        color: theme.palette.primary.main,
        lineHeight: 1.2,
        maxWidth: '100%',
        fontWeight: 700
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%'
    }
}));

export default function CodeAccordion(props) {
    const classes = useStyles();

    return (
        <Accordion
            className={classes.root}
        // expanded={true}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Typography className={classes.heading} variant="button">
                    {props.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
}
