import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import SpinningLoadingLogo from '../utilities/SpinningLoadingLogo';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        marginTop: theme.spacing(2)
    },
    playerWrapper: {
        [theme.breakpoints.up('xs')]: {
            maxWidth: '500px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '900px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '800px',
        }
        // width: '100%',
        // maxWidth: '100%'
        // margin: '0 auto',
    },
    player: {
        // width: '450px',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }
}));


const createNewEntry = (stream, index, security) => {
    return new Promise((resolve) => {
        resolve({
            index: index,
            "h5live": {
                "rtmp": {
                    "streamname": stream.playout.h5live[0].rtmp.streamname
                },
                ...security && {
                    "security": {
                        "token": security.token,
                        "expires": security.expires,
                        "options": security.options,
                        "tag": security.tag
                    }
                }
            }
        });
    });
}

export default function Player(props) {
    const classes = useStyles();
    let urlParams = new URLSearchParams(window.location.search);
    const secureToken = props.secureToken;
    const streams = props.streams;
    const [loading, setLoading] = useState(true);
    const [hasStartIndex] = useState(urlParams.has("startIndex") ? urlParams.get("startIndex") : false);


    async function createConfig() {
        let entries = [];

        for (let i = 0; i < streams.length; i++) {
            let security = secureToken[i];
            await createNewEntry(streams[i], i, security)
                .then((success) => {
                    entries.push(success);
                }).catch(() => {
                    console.log("Could not create Config")
                });
        }

        let identifier = 'nanostream-h5live';
        let customConfig = {
            style: {
                components: {
                    stats: {
                        playback: true
                    }
                }
            },
            config: {
                source: {
                    defaults: {
                        service: "bintu"
                    },
                    entries,
                    options: {
                        adaption: {
                            rule: "deviationOfMean2"
                        },
                    },
                    startIndex: hasStartIndex ? hasStartIndex : 0,
                },
                playback: {
                    autoplay: true,
                    automute: true,
                    muted: true,
                },
                style: {
                    displayMutedAutoplay: true
                }
            }
        };

        new window.initPlayer(customConfig, identifier);
        setLoading(false);
    }

    useEffect(() => {
        if (window.initPlayer && streams.length > 0) {
            createConfig();
        }
    }, [props.streams, props.secureToken])


    useEffect(() => {
        return () => {
            if (window.destroyPlayer) {
                window.destroyPlayer();
            }
        }
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.playerWrapper}>
                {
                    loading
                        ? <SpinningLoadingLogo />
                        : null
                }
                <div className={classes.player} id="nanostream-h5live"></div>
            </div>
        </div>
    )
}