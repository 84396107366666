import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { SettingsEthernet } from '@material-ui/icons';

import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';
import content from '../../utilities/helper/content';

import DividerTitle from '../global/DividerTitle';
import OutlinedTextfield from '../registration/OutlinedTextfield';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    textfieldWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        marginRight: theme.spacing(1)
    }
}))

export default function CreateWebhook(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const [textfield, setTextfield] = useState({
        value: "",
        id: "webhook",
        label: content.createWebhook.textfield.title,
        type: "text",
        icon: <SettingsEthernet color="primary" />,
        error: false,
    })

    const setValue = (event) => {
        setTextfield({
            ...textfield,
            value: event.target.value
        });
    }

    const handleSubmit = () => {
        console.log(textfield)
        if (textfield.value.length === 0) return;
        createNewWebhook(textfield.value);
    }

    const handleReset = () => {
        createNewWebhook(null);
    }

    const createNewWebhook = (webhook) => {
        bintu.createWebhook(apikey, webhook,
            (success) => {
                localStorage.setItem(localStorageHelper.BINTU_ORGA, JSON.stringify(success));
                setValue({ target: { value: "" } })
                props.update(true);
            }, (error) => {
            });
    }

    return (
        <ContentContainer>
            <DividerTitle title={content.createWebhook.title} />
            <div className={classes.textfieldWrapper}>
                <OutlinedTextfield
                    id={textfield.id}
                    type={textfield.type}
                    label={textfield.label}
                    error={textfield.error}
                    icon={textfield.icon}
                    changed={setValue}
                    submit={handleSubmit}
                />
            </div>
            <div>
                <Button
                    className={classes.button}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {content.createWebhook.submit}
                </Button>
                <Button
                    className={classes.button}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleReset}
                >
                    {content.createWebhook.reset}
                </Button>
            </div>
        </ContentContainer>
    )
}