import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Collapse } from '@material-ui/core';
import { GitHub, Facebook, YouTube, Instagram, LinkedIn, Language, Twitter } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import * as link from '../utilities/helper/link-config';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as type from '../utilities/context/type';
import * as methods from '../utilities/helper/methods';

import clouds from '../assets/clouds-background.jpg';
import logo from '../assets/orange-logo.png';
import atw from '../assets/white-atw.png'

import SocialMedia from '../components/utilities/SocialMedia';
import content from '../utilities/helper/content';
import ResetPasswordDialog from '../components/registration/ResetPasswordDialog';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        backgroundImage: `url(${clouds})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
    },
    logoWrapper: {
        height: '64px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        [theme.breakpoints.up('xs')]: {
            "&:hover": {
                cursor: 'pointer'
            },
            height: '30px',
            padding: theme.spacing(0.5),
            marginLeft: theme.spacing(3)

        }
    },
    contentWrapper: {
        margin: theme.spacing(2, 4, 1, 4)
    },
    titleWrapper: {
        margin: theme.spacing(0, 1)
    },
    header: {
        [theme.breakpoints.up('xs')]: {
            color: theme.palette.secondary.main,
            fontWeight: 300
        }
    },
    underline: {
        marginTop: theme.spacing(2),
        color: theme.palette.primary.light,
        lineHeight: 1,
        fontWeight: 600
    },
    orangeText: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    registrationWrapper: {
        marginTop: theme.spacing(4)
    },
    atwWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    atw: {
        [theme.breakpoints.up('xs')]: {
            height: '400px',

        }
    },
    item: {
        minHeight: '100vh',
        '&:nth-of-type(1)': {
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        '&:nth-of-type(2)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        }
    },
    footerSection: {
        marginBottom: theme.spacing(4),
        textAlign: 'center'
    }
}));

export default function SetPassword(props) {
    const classes = useStyles();
    document.title = "nanoStream Cloud - Forgot Password";
    const { state, dispatch } = useContext(Context);
    const [redirectRoute, setRedirectRoute] = useState(null);
    const [login, setLogin] = useState(true);
    let inviteToken = props.match.params.token;

    const changeAuthState = () => {
        setRedirectRoute(<Redirect push to={link.AUTH} />);
    }

    useEffect(() => {
        if (state.access) {
            dispatch({ type: type.ACCESS, access: false });
            localStorage.setItem(localStorageHelper.AUTHORIZED, false);
            localStorage.clear();
        }
    }, []);

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname);
    }, [])

    return (
        <div className={classes.root}>
            {redirectRoute}
            <Grid container>
                <Grid item xs className={classes.item}>
                    <div>
                        <div className={classes.logoWrapper}>
                            <img alt="nanocosmos-logo" src={logo} className={classes.logo} />
                        </div>
                        <div className={classes.contentWrapper}>
                            <div className={classes.titleWrapper}>
                                <Typography variant="h4" className={classes.header}>
                                    {content.setPassword.title} <span className={classes.orangeText}>nanoStream Cloud</span>!
                                </Typography>
                                <Typography variant="h6" className={classes.underline}>
                                    {content.setPassword.underline.first}
                                </Typography>
                            </div>
                            <div className={classes.registrationWrapper}>
                                <ResetPasswordDialog
                                    signin={changeAuthState}
                                    inviteToken={inviteToken}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.footerSection}>
                        <Typography variant="subtitle1" color="textSecondary">
                            &copy; {new Date().getFullYear()} All right reserved. nanocosmos GmbH
                        </Typography>
                        <div>
                            {
                                [
                                    { icon: (<GitHub fontSize="inherit" />), size: "small", link: link.GITHUB },
                                    { icon: (<Language fontSize="inherit" />), size: "small", link: link.WEBSITE },
                                    { icon: (<Facebook fontSize="inherit" />), size: "small", link: link.FACEBOOK },
                                    { icon: (<YouTube fontSize="inherit" />), size: "small", link: link.YOUTUBE },
                                    { icon: (<Twitter fontSize="inherit" />), size: "small", link: link.TWITTER },
                                    { icon: (<Instagram fontSize="inherit" />), size: "small", link: link.INSTAGRAM },
                                    { icon: (<LinkedIn fontSize="inherit" />), size: "small", link: link.LINKEDIN },
                                ].map((socialmedia, index) => (
                                    <SocialMedia
                                        key={index}
                                        icon={socialmedia.icon}
                                        size={socialmedia.size}
                                        link={socialmedia.link}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={7} className={classes.item}>
                    <div className={classes.atwWrapper}>
                        <img alt="around-the-world-in-one-second-logo" src={atw} className={classes.atw} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
