import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: 'none',
        color: 'inherit'
    }
}))

export default function RedirectLink(props) {
    const classes = useStyles();

    return (
        <Link
            className={classes.root}
            to={props.to}
        >
            {props.children}
        </Link>
    )
}