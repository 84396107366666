import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Chip, TextField, Grid, InputAdornment, Collapse, Typography } from '@material-ui/core';
import { ClearRounded, LocalOffer } from '@material-ui/icons';

import content from '../../utilities/helper/content';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import Tag from '../global/Tag';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    tag: {
        // backgroundColor: theme.palette.primary.light,
        // color: theme.palette.common.white,
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(237, 125, 14, 0.2)',
        fontWeight: 600,
        borderRadius: theme.spacing(0.5),
        margin: theme.spacing(0.5, 0.5, 0, 0),
        maxWidth: '100%',
        '&:hover': {
            color: theme.palette.common.white
        },
        '&:active': {
            color: theme.palette.common.white
        },
        '&:focus': {
            color: theme.palette.common.white
        }
    },
    color: {
        color: theme.palette.common.white,
    },
    tags: {
        margin: theme.spacing(2, 0)
    }
}))

export default function AddTags(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY)
    const [newTag, setNewTag] = useState("");
    const [tags, setTags] = useState([]);
    const [fetching, setFetching] = useState(false);


    const handleDeleteTag = (tag) => {
        props.deleteTag(tag)
    }

    const handleTagInserted = (event) => {
        setNewTag(event.target.value);
    }

    const fetchTagsByRegex = (value) => {
        bintu.getTagsByRegex(apikey, value,
            (success) => {
                setTags(success.tags)
            }, (error) => {
                console.log(error)
            })
    }

    const addNewTag = (newTag) => {
        if (newTag !== "" && newTag.trim() !== "") {
            props.addNewTag(newTag);
            setNewTag("");
        }
    }

    const handleTagClicked = (tag) => {
        props.addNewTag(tag);
    }

    const handleKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: addNewTag(newTag); break;
            default: break;
        }
    }

    useEffect(() => {
        if (newTag.length > 2) {
            setFetching(true);
            fetchTagsByRegex(newTag);
        }
        if (newTag.length < 2) {
            setFetching(false);
        }
    }, [newTag])

    return (
        <Grid className={classes.root} item xs={12}>
            <div className={classes.tags}>
                {
                    props.tags.map((tag, index) => (
                        <Chip
                            key={index}
                            className={classes.tag}
                            label={tag}
                            clickable
                            color="primary"
                            onDelete={() => handleDeleteTag(tag)}
                            deleteIcon={<ClearRounded className={classes.color} />}
                        />
                    ))
                }
            </div>
            <TextField
                className={classes.textfield}
                fullWidth
                InputProps={{
                    startAdornment: (
                        < InputAdornment position="start">
                            <LocalOffer color="primary" />
                        </InputAdornment>)
                }}
                disabled={props.disabled}
                helperText={content.tags.enter}
                color="primary"
                label={content.tags.label}
                value={newTag}
                onChange={handleTagInserted}
                onKeyPress={handleKeyPressed}
            />
            {
                <Collapse in={fetching}>
                    {
                        tags.map((tag, index) => (
                            <Tag
                                key={index}
                                tag={tag.tag}
                                handleClicked={handleTagClicked}
                            />
                        ))
                    }
                </Collapse>
            }
        </Grid>
    )
}