import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import PageHeader from './PageHeader';
import RedirectLink from './RedirectLink';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function CouldNotFind(props) {
    const classes = useStyles();

    const handleClickedTitleButton = () => {
        props.clicked();
    }

    return (
        <Grid item xs={12}>
            <PageHeader
                title={props.title}
                underline={props.underline}
            />
            {
                props.to
                    ?
                    <RedirectLink to={props.to}>
                        <Button
                            className={classes.collapseButton}
                            color="primary"
                            variant="contained"
                        >
                            {props.redirectLabel}
                        </Button>
                    </RedirectLink>
                    : null
            }
        </Grid>
    )
}