import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { SettingsEthernet } from '@material-ui/icons';

import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import DividerTitle from '../global/DividerTitle';
import content from '../../utilities/helper/content';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        width: '100%',
        color: theme.palette.background.paper,
    },
    textfieldWrapper: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    }
}))

export default function UpdateVOD(props) {
    const classes = useStyles();
    const orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const [hideVod, setHideVod] = useState(orga.hideVOD);

    const setValue = (event) => {
        setHideVod(!hideVod);
    }

    const handleSubmit = () => {
        updateHideVOD(hideVod)
    }

    const handleCancel = () => {
        props.update();
    }

    const updateHideVOD = (hide) => {
        bintu.hideVod(apikey, hide,
            (success) => {
                console.log(success)
                // localStorage.setItem(localStorageHelper.BINTU_ORGA, JSON.stringify(success));
                props.update();
            }, (error) => {
            });
    }

    return (
        <ContentContainer>
            <DividerTitle title={content.vod.title} underline={content.vod.underline} />
            <FormControlLabel
                control={
                    <Switch
                        checked={hideVod}
                        onChange={setValue}
                        color="primary"
                    />
                }
                label={hideVod ? "VOD hidden" : "VOD public"}
            />
            <div className={classes.buttonWrapper}>
                <Button
                    className={classes.button}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {content.vod.submit}
                </Button>
                <Button
                    className={classes.button}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleCancel}
                >
                    {content.vod.cancel}
                </Button>
            </div>
        </ContentContainer>
    )
}