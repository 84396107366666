import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import moment from 'moment';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { OndemandVideo, OpenInNew } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';
import * as method from '../../utilities/helper/methods';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import StateSection from '../stream-overview/State';
import Tag from '../global/Tag';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    value: {
        lineHeight: 1,
    },
    moreTags: {
        fontWeight: '700',
        lineHeight: 1,
        display: 'inline-block',
        marginLeft: theme.spacing(1.5)
    },
    state: {
        height: 10,
        width: 10,
        borderRadius: '10px',
        marginRight: theme.spacing(1)
    },
    live: {
        backgroundColor: '#32CD32',
    },
    ended: {
        backgroundColor: '#CD5C5C',
    },
    created: {
        backgroundColor: '#B0C4DE',
    },
    streamname: {
        fontWeight: 600
    },
    date: {
        lineHeight: 1
    },

}))

export default function StreamPreviewItem(props) {
    const classes = useStyles();
    const [redirectRoute, setRedirectRoute] = useState(null);

    let stream = props.stream
    let streamInfo = {
        state: stream.state,
        id: stream.id,
        streamname: stream.ingest.rtmp.streamname,
        updatedAt: moment(stream.updatedAt),
        playout: stream.rtmpPlayoutCount,
        tags: stream.tags

    }

    const handleShowStreamClicked = () => {
        setRedirectRoute(
            <Redirect push to={`${link.STREAM}/${streamInfo.id}`} />
        );
    }

    const handleOpenPlayerClicked = (event) => {
        let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA))
        let apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
        let security = orga.secure ? method.GET_PLAYER_HASH(orga.hash, apikey) : false;
        window.open(`${link.PLAYOUT}/${streamInfo.id}${security ? `?security.expires=${security.expires}&security.options=${security.options}&security.tag=${security.tag}&security.token=${security.token}` : ""}`, "_blank")
    }

    return (
        <TableRow hover className={classes.root}>
            {redirectRoute}
            <TableCell className={clsx(classes.stateCell, classes.tableCell)} onClick={handleShowStreamClicked}>
                <StateSection state={streamInfo.state} />
            </TableCell>
            <TableCell className={clsx(classes.streamCell, classes.tableCell)} onClick={handleShowStreamClicked}>
                <Typography className={classes.value} variant="body1">
                    {streamInfo.id}
                </Typography>
            </TableCell>
            <TableCell className={clsx(classes.streamCell, classes.tableCell)} onClick={handleShowStreamClicked}>
                <Typography variant="body1" className={classes.value}>
                    {streamInfo.streamname}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell} onClick={handleShowStreamClicked}>
                <Typography color="textSecondary" variant="subtitle2" className={classes.date}>
                    {streamInfo.updatedAt.format('MM/DD/YYYY')} <br />
                    {streamInfo.updatedAt.utc().format('hh:mm a')} (UTC)
                </Typography>
            </TableCell>
            {
                streamInfo.tags.length > 4
                    ?
                    <TableCell className={classes.tableCell} onClick={handleShowStreamClicked}>
                        {
                            [
                                streamInfo.tags[0],
                                streamInfo.tags[1],
                                streamInfo.tags[2],
                                streamInfo.tags[3],
                            ].map((tag, index) => (
                                <Tag
                                    tag={tag}
                                    key={index}
                                />
                            ))
                        }
                        <Typography className={classes.moreTags} color="primary" onClick={handleShowStreamClicked}>
                            + {streamInfo.tags.length - 4} Tags
                        </Typography>
                    </TableCell>
                    :
                    <TableCell className={clsx(classes.tagCell, classes.tableCell)} onClick={handleShowStreamClicked}>
                        {
                            streamInfo.tags.map((tag, index) => (
                                <Tag
                                    tag={tag}
                                    key={index}
                                />
                            ))
                        }
                    </TableCell>
            }
            {
                streamInfo.state === "live"
                    ?
                    <TableCell className={classes.tableCell} onClick={handleOpenPlayerClicked}>
                        <Tooltip title="Open Playout">
                            <IconButton size="small">
                                <OndemandVideo color="primary" fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    : <TableCell className={classes.tableCell} onClick={handleShowStreamClicked} />
            }
        </TableRow>
    )
}