import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        borderRadius: theme.spacing(0.5),
        boxShadow: theme.shadows[5],
        maxWidth: '80vw'
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: theme.spacing(0.5),
        borderTopRightRadius: theme.spacing(0.5),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    item: {
        backgroundColor: theme.palette.background.default,
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        maxHeight: '50vh',
        overflow: 'scroll'
    },
    closeIcon: {
        alignSelf: 'flex-start',
        margin: theme.spacing(0.5),
        color: theme.palette.common.white
    },
}));

export default function ModalWrapper(props) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.onClose}
            >
                <div style={modalStyle} className={classes.root}>
                    <div className={classes.header}>
                        <div>
                            {props.title}
                        </div>
                        <div className={classes.closeIcon}>
                            <IconButton size="small" onClick={props.onClose}>
                                <Close className={classes.icon} fontSize="inherit" />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.item}>
                        {props.children}
                    </div>
                </div>
            </Modal>
        </div>
    );
}
