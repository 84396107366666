import React, { useEffect, useState } from 'react';

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    datepicker: {
        margin: theme.spacing(1, 2, 0, 0),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '45%',
        },

        [theme.breakpoints.up('md')]: {
            width: 250,
        }
    },
}));

export default function ExpirationDate(props) {
    const classes = useStyles();
    const [expirationDate, setExpirationDate] = useState(props.expirationDate);
    const [error, setError] = useState(false);

    const handleChangeDate = (date) => {
        setExpirationDate(date);
        props.updateValue("expirationDate", date);
    }

    const handleError = (event) => {
        if (event === "Invalid Date Format") {
            setError(true);
            return;
        }
        setError(false);
        return;
    }

    const handleBlur = () => {
        let date = new Date(Date.now() + 12096e5);
        if (error) {
            setExpirationDate(date);
            props.updateValue("expirationDate", date)
        }
    }

    useEffect(() => {
        setExpirationDate(props.date)
    }, [props.expirationDate])

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                className={classes.datepicker}
                disableToolbar
                autoOk
                variant="inline"
                format="MM/dd/yyyy hh:mm a"
                margin="normal"
                label="Expiration Date"
                helperText="Please enter valid date!"
                value={expirationDate}
                onError={handleError}
                onBlur={handleBlur}
                onChange={handleChangeDate}
            />
        </MuiPickersUtilsProvider>
    );
}