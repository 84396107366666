// SOCIAL MEDIA
export const GITHUB = 'https://github.com/nanostream';
export const FACEBOOK = 'https://www.facebook.com/nanocosmos.net/';
export const YOUTUBE = 'https://www.youtube.com/channel/UCsWDxJax9j3GrMDufHBwgKA';
export const INSTAGRAM = 'https://www.instagram.com/nanovideo/';
export const LINKEDIN = 'https://www.linkedin.com/company/nanocosmos-gmbh/';
export const WEBSITE = 'https://info.nanocosmos.de/';
export const TWITTER = 'https://twitter.com/nanovideo';
export const BLOG = 'https://www.nanocosmos.de/blog/';

// MASTER
export const BASE = window.DASHBOARD_BASE || 'https://bintu-cloud-frontend.nanocosmos.de'
export const URL = BASE;

// NEW DASHBOARD
export const NEW_DASHBOARD = 'https://dashboard.nanostream.cloud/';

// FRONTEND REDIRECTS
export const AUTH = '/auth';
export const INVITE_TOKEN = '/invite'
export const LOGIN = '/auth?login';
export const SIGNUP = '/auth?signup';
export const SET_PASSWORD = '/setpassword';
export const STREAMS = '/stream';
export const STREAM = '/stream';
export const DASHBOARD = '/';
export const CREATE_STREAM = '/stream/create';
export const PLAYBACK_TOKEN_HELPER = '/secure/token';
export const NEW_STREAM = '/stream/new';
export const ORGANISATION = '/organisation';
export const LOGOUT = '/logout';
export const USER = '/organisation';
export const SUPPORT = '/support';
export const ABOUT = '/about';
export const PLAYOUT = '/playout';
export const METRICS = '/metrics';
export const WEBCASTER = '/webcaster';

// HELPER LINKS
export const WEBCASTER_DOCS = 'https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_introduction/';
export const DASHBOARD_V3_ARTICLE = 'https://www.nanocosmos.de/blog/2022/09/nanostream-cloud-2022-and-dashboard-v3-release-notes/';
export const NANOPLAYER_RELEASE_SRC = 'https://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.4.min.js?20200819'
export const DOCS = 'https://docs.nanocosmos.de/';
export const FEEDBACK = 'https://www.nanocosmos.de/feedback';
export const BINTU = 'https://bintu.nanocosmos.de/';
export const READ_ME = 'https://docs.nanocosmos.de/nanostream-cloud-dashboard-readme.html';
export const CONTACT = 'https://www.nanocosmos.de/contact';
export const NANO_SUPPORT = 'https://www.nanocosmos.de/support';
export const H5LIVE_DOCS = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_api/';
export const TOKEN_SECURITY_IP = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security/#c-with-a-client-ip';
export const TOKEN_SECURITY_REFERER = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security/#d-with-a-custom-tag-and-a-referer';
export const METRICS_LOGIN = 'https://metrics.nanocosmos.de/#/login'
export const METRICS_DOCS = 'https://docs.nanocosmos.de/docs/cloud/analytics/';
export const IMPRINT = 'https://www.nanocosmos.de/terms';
