import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import content from '../../utilities/helper/content';

import TranscodingProfile from './TranscodingProfile';
import AddTags from './AddTags';
import DividerTitle from '../global/DividerTitle';
import ContentContainer from '../global/ContentContainer';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        marginTop: theme.spacing(2)
    },
    header: {
        fontWeight: 900,
        lineHeight: 1
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    profileWrapper: {
        [theme.breakpoints.up('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('725')]: {
            width: '45%',
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            width: '30%',
        }
    },
    overviewWrapper: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
        }
    }
}));

export default function AddVtrans(props) {
    const classes = useStyles();
    var vtransProfiles = props.profiles;

    const setVtransProfile = (identifier, value) => {
        props.setVtransProfile(identifier, value);
    }

    const updateDisabledState = (identifier) => {
        props.disableProfile(identifier)
    }

    const deleteTag = (tag, identifier) => {
        props.removeTranscodingTags(tag, identifier)

    }

    const addNewTag = (tag, identifier) => {
        props.addTranscodingTags(tag, identifier);

    }

    return (
        <div className={classes.root}>
            <ContentContainer>
                <DividerTitle title={content.createStream.addVtrans.title} />
                <div className={classes.overviewWrapper}>
                    {
                        [
                            vtransProfiles.first,
                            vtransProfiles.second,
                            vtransProfiles.third
                        ].map((profile) => (
                            <div className={classes.profileWrapper} key={`vtrans-${profile.identifier}`}>
                                <TranscodingProfile
                                    choosable={vtransProfiles[profile.identifier].choosable}
                                    disabled={vtransProfiles[profile.identifier].disabled}
                                    identifier={profile.identifier}
                                    label={profile.label}
                                    value={props.profiles[profile.identifier].value}
                                    profiles={profile.profiles}
                                    setVtransProfile={setVtransProfile}
                                    defaultValue={profile.defaultValue}
                                    updateDisabledState={updateDisabledState}
                                />
                                {
                                    vtransProfiles[profile.identifier].tags !== undefined
                                        ?
                                        <AddTags
                                            disabled={vtransProfiles[profile.identifier].disabled}
                                            tags={vtransProfiles[profile.identifier].tags}
                                            addNewTag={(tag) => addNewTag(tag, profile.identifier)}
                                            deleteTag={(tag) => deleteTag(tag, profile.identifier)}
                                        />
                                        : null
                                }

                            </div>
                        ))
                    }
                </div>
            </ContentContainer>
        </div>
    );
}
