import React, { useState, useContext } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, FormControlLabel, Checkbox, Link, Collapse } from '@material-ui/core';
import { Mail, Lock, ArrowForward } from '@material-ui/icons'

import Context from '../../utilities/context/Context';
import * as type from '../../utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as link from '../../utilities/helper/link-config';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import OutlinedTextfield from './OutlinedTextfield';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        borderRadius: theme.spacing(0.5)
    },
    forgotPassword: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    optionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(1, 1, 0, 1)
    },
    warning: {
        color: theme.palette.error.main,
        margin: theme.spacing(1)
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}))

export default function SignIn(props) {
    const classes = useStyles();
    const { dispatch } = useContext(Context);
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [warning, setWarning] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [textfields, setTextfields] = useState({
        email: {
            value: "",
            id: "email",
            autoComplete: "email",
            label: "E-Mail",
            type: "text",
            error: false,
            icon: <Mail color="primary" />,
            show: true
        },
        password: {
            value: "",
            id: "password",
            autoComplete: "current-password",
            label: "Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />,
            show: true
        }
    })

    const handleForgotPassword = () => {
        setForgot(!forgot);
        setTextfields({
            ...textfields,
            password: {
                ...textfields.password,
                show: !textfields.password.show
            }
        })
    }

    const setValue = (value, property) => {
        let settedValue = value;
        if (property === textfields.email.id) {
            // settedValue = settedValue.toLowerCase();
        }
        setTextfields({
            ...textfields,
            [property]: {
                ...textfields[property],
                value: settedValue
            }
        });
    }

    const handleSubmit = (event, property) => {
        if (property === textfields.password.id && !forgot) {
            validateUser(event);
        }
        if (forgot) {
            forgotPassword(event);
        }
    }

    const checkIfValid = (value, property) => {
        switch (property) {
            case "email":
                setTextfields({
                    ...textfields,
                    [property]: {
                        ...textfields[property],
                        error: !mailRegex.test(value)
                    }
                });
                break;
            case "password":
                setTextfields({
                    ...textfields,
                    [property]: {
                        ...textfields[property],
                        error: textfields.password.value === ""
                    }
                });
                break;
            default: break;
        }
    }

    const forgotPassword = (event) => {
        event.preventDefault();
        if (!acceptTerms) {
            setWarning(true);
            return;
        }
        let mailInvalid = !mailRegex.test(textfields.email.value);
        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
        });
        if (mailInvalid) return;

        bintu.resetPassword(textfields.email.value,
            (response) => {
                handleForgotPassword();
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const validateUser = (event) => {
        event.preventDefault();
        if (forgot) {
            forgotPassword(event);
            return;
        }
        if (!acceptTerms) {
            setWarning(true);
            return;
        }
        let mailInvalid = !mailRegex.test(textfields.email.value);
        let passwordInvalid = textfields.password.value === "";

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            password: {
                ...textfields.password,
                error: passwordInvalid
            }
        });

        if (mailInvalid || passwordInvalid) return;

        bintu.validateUser(textfields.email.value, textfields.password.value,
            (response) => {
                localStorage.setItem(localStorageHelper.AUTHORIZED, true);
                localStorage.setItem(localStorageHelper.BINTU_APIKEY, response.apiKey)
                localStorage.setItem(localStorageHelper.BINTU_MAIL, textfields.email.value);
                bintu.getOrga(response.apiKey,
                    (success) => {
                        if (success.isEnabled) {
                            localStorage.setItem(localStorageHelper.BINTU_ORGA, JSON.stringify(success));
                            dispatch({
                                type: type.ACCESS,
                                access: true
                            });
                        }
                        if (!success.isEnabled) {
                            dispatch({ type: type.SHOW_ERROR, error: { title: "Your orga is disabled!", message: "Please contact our sales team, if this is an issue." }, alert: "error" });
                        }
                        if (success.secure) {
                            let body = {
                                tag: "nanostream-cloud-dashboard-player-token",
                                expires: Math.round(moment(new Date(Date.now())).add(24, 'hours') / 1000),
                                orga: success.hash,
                            }
                            bintu.createSecureToken(response.apiKey, body,
                                (success) => {
                                    console.log(success)
                                    localStorage.setItem(localStorageHelper.H5LIVE_HASH_SECURE, JSON.stringify(success.h5live.security))
                                }, (error) => {
                                    console.log(error)
                                })
                        }
                    },
                    (error) => {
                        dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                    })
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const handleAcceptTerms = () => {
        if (warning) setWarning(false);
        setAcceptTerms(!acceptTerms)
    }

    return (
        <div className={classes.root}>
            <form autoComplete>
                <div className={classes.textfieldWrapper}>
                    {
                        [
                            textfields.email,
                            textfields.password
                        ].map((textfield, index) => (
                            textfield.show
                                ?
                                <OutlinedTextfield
                                    key={`${textfield.id}-${index}`}
                                    id={textfield.id}
                                    type={textfield.type}
                                    autoComplete={textfield.autoComplete}
                                    label={textfield.label}
                                    error={textfields[textfield.id].error}
                                    icon={textfield.icon}
                                    changed={(event) => { setValue(event.target.value, textfield.id) }}
                                    blured={(event) => { checkIfValid(event.target.value, textfield.id) }}
                                    submit={(event) => { handleSubmit(event, textfield.id) }}
                                />
                                : null
                        ))
                    }
                    <div className={classes.optionWrapper}>
                        <FormControlLabel
                            className={classes.checkboxLabel}
                            control={
                                <Checkbox
                                    color="primary"
                                    name="acceptTerms" />
                            }
                            label={
                                <Typography variant="overline" color="textSecondary">
                                    Accept <Link href={link.IMPRINT} target="_blank">terms and conditions</Link>
                                </Typography>
                            }
                            checked={acceptTerms}
                            onChange={handleAcceptTerms}
                        />
                        <Typography variant="overline" className={classes.forgotPassword} onClick={handleForgotPassword}>
                            {forgot ? "Remember your password?" : "Forgot password?"}
                        </Typography>
                    </div>
                    <Collapse in={warning}>
                        <Typography className={classes.warning} variant="caption">
                            Please accept our terms and conditions.
                        </Typography>
                    </Collapse>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={validateUser}
                    >
                        Login
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        component="a"
                        href={`${link.NEW_DASHBOARD}/auth`}
                    >
                        Sign Up
                    </Button>
                </div>
            </form>
        </div>
    )
}