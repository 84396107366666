import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Dashboard, AddBox, Help } from '@material-ui/icons';

import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import QuickLink from '../components/utilities/QuickLink';

import logo from '../assets/another_logo.png';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    contentWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    quickLinks: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('700')]: {
            flexDirection: 'row',
            justifyContent: 'center'
        }

    },
    content: {
        textAlign: 'center',
        maxWidth: '100%'
    },
    image: {
        height: 300,
    },
    header: {
        color: theme.palette.primary.main,
        lineHeight: 1,
        fontWeight: 900,
    },
    overline: {
        marginTop: theme.spacing(1),
        display: 'block',
        color: theme.palette.secondary.main,
        lineHeight: 1,
        fontWeight: 900
    }
}))

export default function NotFound(props) {
    const classes = useStyles();
    const [quickLinks, setQuickLinks] = useState([]);
    document.title = "nanoStream Cloud";

    useEffect(() => {
        if (localStorage.getItem(localStorageHelper.AUTHORIZED) || localStorage.getItem(localStorageHelper.BINTU_APIKEY)) {
            setQuickLinks([
                { label: content.notFound.dashboard.title, text: content.notFound.dashboard.underline, link: link.DASHBOARD, icon: <Dashboard fontSize="small" /> },
                { label: content.notFound.newStream.title, text: content.notFound.newStream.underline, link: link.CREATE_STREAM, icon: <AddBox fontSize="small" /> },
                { label: content.notFound.support.title, text: content.notFound.support.underline, link: link.SUPPORT, icon: <Help fontSize="small" /> }
            ])
        }
    }, [])

    useEffect(() => {
        methods.SEND_GA_ANALYTICS("/webcaster")
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <img alt="around-the-world-in-one-second-logo" className={classes.image} src={logo} />
                    <Typography className={classes.overline} variant="button" gutterBottom>
                        {content.notFound.overline}
                    </Typography>
                    <Typography className={classes.header} variant="h4" gutterBottom>
                        {content.notFound.title}
                    </Typography>
                    {/* <Button
                        variant="contained"
                        color="primary"
                    >
                        Home
                    </Button> */}
                    <div className={classes.quickLinks}>
                        {
                            quickLinks.map((link) => (
                                <QuickLink
                                    key={link.label}
                                    label={link.label}
                                    link={link.link}
                                    icon={link.icon}
                                    text={link.text}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}