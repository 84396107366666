import React, { useState } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import StateSection from '../stream-overview/State';
import Tag from '../global/Tag';

import ContentContainer from '../global/ContentContainer';
import CopyButton from '../global/CopyButton';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    broadcastInformations: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        flexWrap: 'wrap'
    },
    playerInfo: {
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(1)
    },
    version: {
        marginRight: theme.spacing(1),
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
        fontWeight: 700,
        color: theme.palette.common.white,
        display: 'inline-block'
    },
    stateDateWrapper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1)
    },
    tags: {
        marginTop: theme.spacing(1)
    },
    infoWrapper: {
        margin: theme.spacing(0.4)
    },
    info: {
        marginBottom: theme.spacing(0.5)
    },
    value: {
        fontWeight: 500,
        lineHeight: 1,
        fontSize: 16,
        marginRight: theme.spacing(0.5),
    },
    description: {
        color: theme.palette.text.secondary,
        lineHeight: 1
    },
    moreTags: {
        fontWeight: '700',
        lineHeight: 1,
        display: 'inline-block',
        marginLeft: theme.spacing(1)
    },
    copyWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}))

export default function StreamInfoSection(props) {
    const classes = useStyles();

    const [stats, setStats] = useState("");
    const [version, setVersion] = useState("");
    const [infos, setInfos] = useState({
        bitrate: { avg: 0, current: 0, deviation: 0, max: 0, min: 0 },
        buffer: { end: 0, start: 0, delay: { avg: 0, current: 0, deviation: 0, max: 0, min: 0 } },
        framerate: { avg: 0, current: 0, deviation: 0, max: 0, min: 0 },
    });
    let stream = props.stream
    let streamInfo = {
        state: stream.state,
        id: stream.id,
        streamname: stream.playout.rtmp[0].streamname,
        updatedAt: moment(stream.created_at),
        tags: stream.tags ? stream.tags : []
    }

    window.getBroadcastInformations = (response) => {
        setStats(response.stats);
        setVersion(response.version);
        setInfos(response.informations);
    }


    return (
        <ContentContainer>
            <div className={classes.stateDateWrapper}>
                <Typography color="primary" variant="subtitle2" className={classes.version}>
                    nanoPlayer {version}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                    {stats}
                </Typography>
            </div>
            <div className={classes.broadcastInformations}>
                {
                    [
                        { title: "Play Latency", timing: "current", value: (infos.buffer.delay.current).toFixed(2), ending: "s" },
                        { title: "Framerate", timing: "avg", value: (infos.framerate.avg).toFixed(2), ending: "fps" },
                        { title: "Buffer", timing: "avg", value: (infos.buffer.delay.avg).toFixed(2), ending: "s" },
                        { title: "Bitrate", timing: "avg", value: (infos.bitrate.avg / 1000).toFixed(0), ending: "kbps" },
                    ].map((info, index) => (
                        <div className={classes.playerInfo} key={`${info.title}-${index}`}>
                            <Typography key={index} className={classes.value} variant="body1">
                                {info.value} {info.ending}
                            </Typography>
                            <Typography className={classes.description} variant="overline">
                                {info.title} ({info.timing})
                            </Typography>
                        </div>
                    ))
                }
            </div>
            <Divider />
            <div className={classes.stateDateWrapper}>
                <StateSection state={streamInfo.state} />
                <Typography color="textSecondary" variant="subtitle2">
                    {streamInfo.updatedAt.utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                </Typography>
            </div>
            <div className={classes.infoWrapper}>
                {
                    [
                        { description: "Stream name", value: streamInfo.streamname },
                        { description: "Stream Id", value: streamInfo.id },
                    ].map((info, index) => (
                        <div key={index} className={classes.info}>
                            <div className={classes.copyWrapper}>
                                <Typography key={index} className={classes.value} variant="body1">
                                    {info.value}
                                </Typography>
                                <CopyButton iconButton={true} copy={info.value} />
                            </div>
                            <Typography className={classes.description} variant="overline">
                                {info.description}
                            </Typography>
                        </div>
                    ))
                }
            </div>
            {
                streamInfo.tags.length > 4
                    ?
                    <div className={classes.tags}>
                        {
                            [
                                streamInfo.tags[0],
                                streamInfo.tags[1],
                                streamInfo.tags[2],
                                streamInfo.tags[3],
                            ].map((tag, index) => (
                                <Tag
                                    tag={tag}
                                    key={index}
                                />
                            ))
                        }
                        <Typography className={classes.moreTags} color="primary">
                            + {streamInfo.tags.length - 4} Tags
                            </Typography>
                    </div>
                    :
                    <div className={classes.tags}>
                        {
                            streamInfo.tags.map((tag, index) => (
                                <Tag
                                    tag={tag}
                                    key={index}
                                />
                            ))
                        }
                    </div>
            }
        </ContentContainer>
    )
}