import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Collapse, Typography, Button } from '@material-ui/core';
import { AddBox, Search } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import * as type from '../utilities/context/type';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import StreamPreviewTable from '../components/streams/StreamList';
import SpinningLoadingLogo from '../components/utilities/SpinningLoadingLogo';
import Filter from '../components/filter/Filter';
import RedirectLink from '../components/global/RedirectLink';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    item: {
        '&:nth-of-type(2)': {
        },
        '&:nth-of-type(3)': {
            background: theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
            marginTop: theme.spacing(2)
        }
    },
    headerWrapper: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    },
    header: {
        fontWeight: 900,
        lineHeight: 1,
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('500')]: {
            marginBottom: 0
        }

    },
    filter: {
        marginRight: theme.spacing(0.5)
    },
    buttonWrapper: {
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('500')]: {
            marginTop: 0
        },
        display: 'flex',
        flexDirection: 'row'
    }
}))
export default function Streams(props) {
    const classes = useStyles();
    document.title = "All streams";
    const { dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    let urlParams = new URLSearchParams(window.location.search);
    const [streamlist, setStreamList] = useState([]);
    const [countAll, setCountAll] = useState(50);
    const [page, setPage] = useState(0);
    const [quantity, setQuantity] = useState(25);
    const [showFilter, setShowFilter] = useState(false);
    const [reset, setReset] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        state: "",
        from: localStorage.getItem(localStorageHelper.BINTU_ORGA) ? JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)).created_at : new Date(Date.now()).toISOString(),
        until: moment(new Date(Date.now())).endOf('day').toISOString(),
        tags: []
    });

    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    }

    const updateStreamlist = (searchFilter, quantity, offset) => {
        bintu.getStreamCollection(apikey, searchFilter.state, searchFilter.tags, searchFilter.from, searchFilter.until, quantity, offset,
            (success) => {
                setStreamList(success);
                setLoading(false);
            }, (error) => {
                setLoading(false);
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const updateFilter = (updatedFilter) => {
        setFilter(updatedFilter);
        setReset(true);
        setPage(0);
        fetchStreamList(updatedFilter, quantity, 0);
    }

    const resetFilter = (resettedFilter) => {
        setFilter(resettedFilter)
        fetchStreamList(resettedFilter, quantity, 0);
        setReset(false);
    }

    const changedPage = (newPage) => {
        fetchStreamList(filter, ((newPage + 1) * quantity), 0);
        setPage(newPage);
    }

    const changedQuantity = (quantity) => {
        setQuantity(quantity);
        setPage(0);
        fetchStreamList(filter, quantity, 0)
    }

    const fetchStreamList = (settedFilter, quantity, offset) => {
        bintu.countAllStreams(apikey, settedFilter.state, settedFilter.tags,
            (success) => {
                setCountAll(success.count);
                updateStreamlist(settedFilter, quantity, offset);
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                setLoading(false);
            })
    }

    useEffect(() => {
        let updatedFilter = filter;
        let refresh = false;
        if (urlParams.has('state')) {
            let state = { state: urlParams.has('state') ? urlParams.get('state') : "" };
            updatedFilter = _.extend(filter, state);
            refresh = true;
        }
        if (urlParams.has('tags')) {
            let fetchedTags = urlParams.has('tags') ? urlParams.get('tags') : "";
            let tags = { tags: fetchedTags.split(",") };
            updatedFilter = _.extend(filter, tags);
            refresh = true;
        }
        if (urlParams.has('from')) {
            let date = urlParams.get('from')
            if (moment(date).isValid()) {
                let from = { from: date };
                updatedFilter = _.extend(filter, from);
                refresh = true;
            }
        }
        if (urlParams.has('until')) {
            let date = urlParams.get('until')
            if (moment(date).isValid()) {
                let until = { until: date };
                updatedFilter = _.extend(filter, until);
                refresh = true;
            }
        }
        if (refresh) {
            setFilter(updatedFilter);
            setShowFilter(true);
            setReset(true);
        }
        fetchStreamList(updatedFilter, quantity, 0);
    }, [])

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname)
    }, [])

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography color="primary" variant="h4" className={classes.header}>
                        {content.streams.title}
                    </Typography>
                    <div className={classes.buttonWrapper}>
                        <RedirectLink to={link.CREATE_STREAM}>
                            <Button
                                className={classes.filter}
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<AddBox />}
                            >
                                {content.createStream.button}
                            </Button>
                        </RedirectLink>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleShowFilter}
                            startIcon={<Search />}
                        >
                            Search
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <Collapse in={showFilter}>
                        <Filter
                            filter={filter}
                            settedFilter={updateFilter}
                            reset={reset}
                            resetFilter={resetFilter}
                        />
                    </Collapse>
                </Grid>
                {
                    loading
                        ? <SpinningLoadingLogo />
                        : null

                }
                <Grid item xs={12} className={classes.item}>
                    <Collapse in={!loading}>
                        <StreamPreviewTable
                            count={countAll}
                            streamlist={streamlist}
                            page={page}
                            quantity={quantity}
                            changedPage={changedPage}
                            changedQuantity={changedQuantity}
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </div>
    );
}
