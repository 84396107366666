import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Collapse, Button, Tooltip, FormControlLabel, Typography, FormGroup, Radio, TextField } from '@material-ui/core';
import { Add, KeyboardArrowDown, KeyboardArrowUp, Remove } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import * as type from '../utilities/context/type';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as vtrans from '../utilities/helper/vtrans-helper';
import * as webcaster from '../utilities/helper/webcaster-helper';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import AddTags from '../components/helper/AddTags';
import Tags from '../components/organisation/Tags';
import DividerTitle from '../components/global/DividerTitle';
import AddVtrans from '../components/helper/AddVtrans';
import ContentContainer from '../components/global/ContentContainer';
import PageHeader from '../components/global/PageHeader';
import ChooseStartIndex from '../components/helper/ChooseStartIndex';
import SetupWebcaster from '../components/helper/SetupWebcaster';
import content from '../utilities/helper/content';
import AddOpcode from '../components/helper/AddOpcode';


const useStyles = makeStyles((theme) => ({
    root: {
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    datepicker: {
        margin: theme.spacing(1, 2, 0, 0)
    },
    textfieldRow: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        }
    },
    textfield: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: 250,
            '&:first-of-type': {
                marginRight: theme.spacing(1)
            }
        }
    },
    tagWrapper: {
        marginTop: theme.spacing(1)
    },
    buttonWrapper: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        }
    },
    button: {
        margin: theme.spacing(0.5, 1, 0, 0),
    },
    disabled: {
        color: '#d3d3d3',
    },
    underline: {
        marginBottom: theme.spacing(1.5),
        lineHeight: 1.2
    },
    switchLabel: {
        color: theme.palette.secondary.light
    },
    collapseButton: {
        margin: theme.spacing(1, 0),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export default function CreateStream(props) {
    const classes = useStyles();
    document.title = "Create Stream";
    const { state, dispatch } = useContext(Context);
    const [apikey] = useState(localStorage.getItem(localStorageHelper.BINTU_APIKEY));
    const [createdStream, setCreatedStream] = useState(undefined);
    const [sortedProfiles, setSortedProfiles] = useState([])
    const [tags, setTags] = useState([]);
    const [transcode, setTranscode] = useState(false);
    const [opcode, setOpcode] = useState(false);
    const [opcodeSetup, setOpcodeSetup] = useState(null);
    const [metadata, setMetadata] = useState(false);
    const [timecode, setTimecode] = useState(2000);
    const [enableMetadata, setEnableMetadata] = useState(false);
    const [enableTags, setEnableTags] = useState(false);
    const [enableTranscoding, setEnableTranscoding] = useState(false);
    const [enableWebcaster, setEnableWebcaster] = useState(false);
    const [enableVod, setEnableVod] = useState(false);
    const [showAllTags, setShowAllTags] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState("");
    const [startIndex, setStartIndex] = useState(0);
    const [vtransProfiles, setVtransProfiles] = useState({
        first: {
            identifier: "first",
            label: "1. Transcoding Profile",
            profiles: vtrans.VTRANS_PROFILES,
            defaultValue: "vtrans2-852x480x800x25",
            value: "vtrans2-852x480x800x25",
            disabled: false,
            choosable: true,
            tags: [],
        },
        second: {
            identifier: "second",
            label: "2. Transcoding Profile",
            profiles: vtrans.VTRANS_PROFILES,
            defaultValue: "vtrans2-640x360x400x25",
            value: "vtrans2-640x360x400x25",
            disabled: false,
            choosable: true,
            tags: [],
        },
        third: {
            identifier: "third",
            label: "3. Transcoding Profile",
            profiles: vtrans.VTRANS_PROFILES,
            defaultValue: "vtrans2-426x240x200x15",
            value: "vtrans2-426x240x200x15",
            tags: [],
            disabled: true,
            choosable: true,
        }
    });
    const [setup, setSetup] = useState("rtmp");
    const [webcasterSetup, setWebcasterSetup] = useState({
        framerate: webcaster.FRAMERATE.value,
        resolution: `${webcaster.RESOLUTION.width}x${webcaster.RESOLUTION.height}`,
        videobitrate: webcaster.VIDEOBITRATE.value,
        audiobitrate: webcaster.AUDIOBITRATE.value
    });

    let redirect = () => {
        let url = `${setup === "webcaster" ? `${link.WEBCASTER}` : `${link.NEW_STREAM}`}`
        let passthrough = `/${createdStream.id}`;
        let vtrans = `${createdStream.playout.rtmp[1] !== undefined
            ? `?vtrans1.id=${createdStream.playout.rtmp[1].info.id}&vtrans1.bitrate=${sortedProfiles[0].bitrate}&vtrans1.fps=${sortedProfiles[0].fps}&vtrans1.height=${sortedProfiles[0].height}&vtrans1.width=${sortedProfiles[0].width}`
            : ""}${createdStream.playout.rtmp[2] !== undefined
                ? `&vtrans2.id=${createdStream.playout.rtmp[2].info.id}&vtrans2.bitrate=${sortedProfiles[1].bitrate}&vtrans2.fps=${sortedProfiles[1].fps}&vtrans2.height=${sortedProfiles[1].height}&vtrans2.width=${sortedProfiles[1].width}`
                : ""}${createdStream.playout.rtmp[3] !== undefined
                    ? `&vtrans3.id=${createdStream.playout.rtmp[3].info.id}&vtrans3.bitrate=${sortedProfiles[2].bitrate}&vtrans3.fps=${sortedProfiles[2].fps}&vtrans3.height=${sortedProfiles[2].height}&vtrans3.width=${sortedProfiles[2].width}`
                    : ""} ${transcode
                        ? `&startIndex=${startIndex}`
                        : ""
            }`

        let params = `${setup === "webcaster" ? `${transcode ? "&" : "?"}webcaster.framerate=${webcasterSetup.framerate}&webcaster.resolution=${webcasterSetup.resolution}&webcaster.videobitrate=${webcasterSetup.videobitrate}&webcaster.audiobitrate=${webcasterSetup.audiobitrate}` : ""}`
        setRedirectRoute(<Redirect push to={`${url}${passthrough}${vtrans}${params}`} />)
    }

    const handleAddTags = () => {
        setEnableTags(!enableTags);
    }

    const handleAddTranscoding = () => {
        setTranscode(!transcode);
    }

    const collapseAllTags = () => {
        setShowAllTags(!showAllTags);
    }

    const handleAddMetadata = () => {
        setMetadata(!metadata);
    }

    const handleAddOpcode = () => {
        setOpcode(!opcode);
    }

    const addTimecode = (event) => {
        setTimecode(event.target.value)
    }

    const bluredTimecode = () => {
        if (timecode === "" || Number(timecode) < 500 || Number(timecode) > 3600000 || /[^0-9]+/.test(timecode)) {
            setTimecode(500)
        }
    }

    const deleteTag = (tag) => {
        let updatedTagArray = tags.filter((singleTag) => singleTag !== tag);
        setTags(updatedTagArray);
    }

    const addNewTag = (tag) => {
        if (tags.includes(tag)) return
        let updatedTagArray = tags.concat([tag]);
        setTags(updatedTagArray);
    }

    const updateStartIndex = (index) => {
        setStartIndex(index)
    }

    const updateVtransProfile = (identifier, value) => {
        const unchangedValues = [vtransProfiles.first, vtransProfiles.second, vtransProfiles.third].filter((profile) => profile.identifier !== identifier);
        const firstValue = unchangedValues[0];
        const secondValue = unchangedValues[1];

        if (value === "None") {
            setVtransProfiles({
                ...vtransProfiles,
                first: {
                    ...vtransProfiles.first,
                    value: "None"
                },
                second: {
                    ...vtransProfiles.second,
                    value: "None"
                },
                third: {
                    ...vtransProfiles.third,
                    value: "None"
                }
            });
            return;
        }
        if (!(value === "None")) {
            if (vtransProfiles.first.value === "None" || vtransProfiles.second.value === "None" || vtransProfiles.third.value === "None") {
                setVtransProfiles({
                    ...vtransProfiles,
                    [firstValue.identifier]: {
                        ...vtransProfiles[firstValue.identifier],
                        value: vtransProfiles[firstValue.identifier].defaultValue
                    },
                    [secondValue.identifier]: {
                        ...vtransProfiles[secondValue.identifier],
                        value: vtransProfiles[secondValue.identifier].defaultValue
                    },
                    [identifier]: {
                        ...vtransProfiles[identifier],
                        value: value
                    }
                });
                return;
            }
        }
        setVtransProfiles({
            ...vtransProfiles,
            [identifier]: {
                ...vtransProfiles[identifier],
                value: value
            }
        });
    }

    const disableProfile = (identifier) => {
        setVtransProfiles({
            ...vtransProfiles,
            [identifier]: {
                ...vtransProfiles[identifier],
                disabled: !vtransProfiles[identifier].disabled
            }
        });
    }

    const pushUrl = (streamid, profile, streamname) => {
        return new Promise((resolve, reject) => {
            bintu.setPushUrl(apikey, streamid, profile, streamname,
                (success) => {
                    resolve(success)
                },
                (error) => {
                    dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                })
        })
    }

    const createStream = (tags, profiles) => {
        return new Promise((resolve, reject) => {
            bintu.createStreamGroup(apikey, enableMetadata, metadata, timecode, enableTranscoding, profiles, tags,
                (success) => {
                    resolve(success)
                },
                (error) => {
                    dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                })
        })
    }

    async function handleCreateStreamClicked() {
        let stream = {};
        let profiles = transcode ? sortVtransProfiles() : [];
        setSortedProfiles(profiles);

        bintu.createStreamGroup(apikey, enableMetadata, metadata, timecode, enableTranscoding, profiles, tags,
            (success) => {
                stream = success;
                setCreatedStream(stream);
                if (opcode) {
                    createOpcode(stream.id, opcodeSetup);
                }
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })

    }

    const createOpcode = (streamid, opcode) => {
        bintu.setOpCode(apikey, streamid, opcode,
            (success) => {
                console.log(success)
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const setProfileProperties = (profile, tags) => {
        return {
            none: false,
            identifier: profile.identifier,
            tags: tags,
            bitrate: profile.bitrate,
            width: profile.width,
            height: profile.height,
            fps: profile.fps
        }
    }

    const sortVtransProfiles = () => {

        let filteredProfiles = [];

        if (!vtransProfiles.first.disabled && !(vtransProfiles.first.value === "")) {
            let first = vtrans.VTRANS_PROFILES.filter((profile) => profile.identifier === vtransProfiles.first.value);

            if (vtransProfiles.first.value === "None") {
                filteredProfiles.push({ none: true, tags: vtransProfiles.first.tags });
            }
            if (vtransProfiles.first.value !== "None") {
                filteredProfiles.push(setProfileProperties(first[0], vtransProfiles.first.tags));
            }
        }

        if (!vtransProfiles.second.disabled && !(vtransProfiles.second.value === "")) {
            let second = vtrans.VTRANS_PROFILES.filter((profile) => profile.identifier === vtransProfiles.second.value);

            if (vtransProfiles.second.value === "None") {
                filteredProfiles.push({ none: true, tags: vtransProfiles.second.tags });
            }
            if (vtransProfiles.second.value !== "None") {
                filteredProfiles.push(setProfileProperties(second[0], vtransProfiles.second.tags));
            }
        }

        if (!vtransProfiles.third.disabled && !(vtransProfiles.third.value === "")) {
            let third = vtrans.VTRANS_PROFILES.filter((profile) => profile.identifier === vtransProfiles.third.value);

            if (vtransProfiles.third.value === "None") {
                filteredProfiles.push({ none: true, tags: vtransProfiles.third.tags });
            }
            if (vtransProfiles.third.value !== "None") {
                filteredProfiles.push(setProfileProperties(third[0], vtransProfiles.third.tags))
            }
        }

        let sortedProfiles = _.orderBy(filteredProfiles, ['bitrate'], ['desc'])

        return sortedProfiles;
    }

    const addTranscodingTags = (tag, identifier) => {
        let updatedTagArray = vtransProfiles[identifier].tags.concat([tag]);

        setVtransProfiles({
            ...vtransProfiles,
            [identifier]: {
                ...vtransProfiles[identifier],
                tags: updatedTagArray
            }
        });
    }

    const removeTranscodingTags = (tag, identifier) => {
        let updatedTagArray = vtransProfiles[identifier].tags.filter((singleTag) => singleTag !== tag);

        setVtransProfiles({
            ...vtransProfiles,
            [identifier]: {
                ...vtransProfiles[identifier],
                tags: updatedTagArray
            }
        });
    }

    const handleSwitchedCheckbox = (id) => (event) => {
        setSetup(id);
    }

    const updateOpcode = (value) => {
        setOpcodeSetup(value)

    }

    const updateWebcasterSetup = (setup) => {
        setWebcasterSetup(setup);
    }

    useEffect(() => {
        if (vtransProfiles.first.disabled && vtransProfiles.second.choosable) {
            setVtransProfiles({
                ...vtransProfiles,
                second: {
                    ...vtransProfiles.second,
                    choosable: false,
                    disabled: true
                },
                third: {
                    ...vtransProfiles.third,
                    choosable: false,
                    disabled: true
                },
            })
        }
        if (!vtransProfiles.first.disabled && !vtransProfiles.second.choosable) {
            setVtransProfiles({
                ...vtransProfiles,
                second: {
                    ...vtransProfiles.second,
                    choosable: true,
                    disabled: false
                },
                third: {
                    ...vtransProfiles.third,
                    choosable: true,
                    disabled: false
                },
            })
        }
        if (vtransProfiles.second.disabled && vtransProfiles.third.choosable) {
            setVtransProfiles({
                ...vtransProfiles,
                third: {
                    ...vtransProfiles.third,
                    choosable: false,
                    disabled: true
                },
            })
        }
        if (!vtransProfiles.second.disabled && !vtransProfiles.third.choosable) {
            setVtransProfiles({
                ...vtransProfiles,
                third: {
                    ...vtransProfiles.third,
                    choosable: true,
                    disabled: false
                }
            })
        }
    }, [vtransProfiles])

    useEffect(() => {
        if (createdStream !== undefined) {
            redirect();
        }
    }, [createdStream])

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname);

        let orga = localStorage.getItem(localStorageHelper.BINTU_ORGA);
        setEnableTranscoding(orga ? JSON.parse(orga).transcoding : false);
        setEnableMetadata(orga ? JSON.parse(orga).metadata : false);
        setEnableVod(orga ? JSON.parse(orga).vod : false);
        let webcaster = orga ? JSON.parse(orga).webrtc : false;
        setEnableWebcaster(webcaster)
    }, [])

    return (
        <div className={classes.root}>
            <Grid container>
                <PageHeader title={content.createStream.title} underline={content.createStream.underline} />
                {redirectRoute}
                <ContentContainer>
                    <DividerTitle title={content.createStream.ingestStream.title} />
                    <FormGroup row>
                        {
                            [
                                { label: "RTMP", id: "rtmp" },
                                { label: "Webcaster", id: "webcaster", disabled: !enableWebcaster },
                                // { label: "Meetcaster", id: "meetcaster", disabled: true },
                            ].map((checkbox, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Radio
                                            disabled={checkbox.disabled}
                                            color="secondary"
                                            checked={checkbox.id === setup}
                                            onChange={handleSwitchedCheckbox(checkbox.id)}
                                            name={checkbox.id} />
                                    }
                                    label={checkbox.label}
                                />
                            ))
                        }
                    </FormGroup>
                </ContentContainer>
                <Grid item xs={12}>
                    <Collapse in={setup === "webcaster"}>
                        <SetupWebcaster
                            updateSetup={updateWebcasterSetup}
                        />
                    </Collapse>
                </Grid>
                <Grid item xs={12} className={classes.buttonWrapper}>
                    <Button
                        size="small"
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        onClick={handleAddTags}
                        startIcon={enableTags ? <Remove /> : <Add />}
                    >
                        {enableTags ? "Remove" : "Add"} stream tags
                    </Button>
                    {
                        enableTranscoding
                            ?
                            <Button
                                size="small"
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                onClick={handleAddTranscoding}
                                startIcon={transcode ? <Remove /> : <Add />}
                            >
                                {transcode ? "Remove" : "Add"} transcoding
                            </Button>
                            :
                            <Tooltip title="Contact Sales to enable transcoding">
                                <Button
                                    size="small"
                                    className={clsx(classes.button, classes.disabled)}
                                    variant="outlined"
                                    startIcon={<Add />}
                                >
                                    Add transcoding
                                </Button>
                            </Tooltip>
                    }
                    {
                        enableMetadata
                            ?
                            <Button
                                size="small"
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                onClick={handleAddMetadata}
                                startIcon={metadata ? <Remove /> : <Add />}
                            >
                                {metadata ? "Remove" : "Add"} timecode
                            </Button>
                            :
                            <Tooltip title="Contact Sales to enable Metadata.">
                                <Button
                                    size="small"
                                    className={clsx(classes.button, classes.disabled)}
                                    variant="outlined"
                                    startIcon={<Add />}
                                >
                                    Add timecode
                                </Button>
                            </Tooltip>
                    }
                    {
                        enableVod
                            ?
                            <Button
                                size="small"
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                onClick={handleAddOpcode}
                                startIcon={opcode ? <Remove /> : <Add />}
                            >
                                {opcode ? "Remove" : "Add"} live processing
                            </Button>
                            :
                            <Tooltip title="Contact Sales to enable live processing.">
                                <Button
                                    size="small"
                                    className={clsx(classes.button, classes.disabled)}
                                    variant="outlined"
                                    startIcon={<Add />}
                                >
                                    Add live processing
                                </Button>
                            </Tooltip>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={enableTags}>
                        <ContentContainer>
                            <DividerTitle title={content.createStream.addTags.title} />
                            <AddTags
                                tags={tags}
                                addNewTag={addNewTag}
                                deleteTag={deleteTag}
                            />
                            <Typography
                                className={classes.collapseButton}
                                color="primary"
                                variant="subtitle2"
                                onClick={collapseAllTags}
                            >
                                {showAllTags ? "Hide" : "Show"} all tags {showAllTags ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Typography>
                            <Collapse in={showAllTags}>
                                <Tags clickedTag={addNewTag} />
                            </Collapse>
                        </ContentContainer>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={transcode}>
                        <AddVtrans
                            setVtransProfile={updateVtransProfile}
                            profiles={vtransProfiles}
                            disableProfile={disableProfile}
                            addTranscodingTags={addTranscodingTags}
                            removeTranscodingTags={removeTranscodingTags}
                        />
                        <ChooseStartIndex
                            profiles={vtransProfiles}
                            startIndex={startIndex}
                            setStartIndex={updateStartIndex}

                        />
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={metadata}>
                        <ContentContainer>
                            <DividerTitle title="Choose timecode injection period." />
                            <Typography variant="body1" className={classes.underline} color="textSecondary">
                                You can set the time injection interval between 500 ms and 3600000 ms (1 hour), recommended: 1000-5000 ms.
                            </Typography>
                            <TextField
                                label="Timecode (ms)"
                                value={timecode}
                                onChange={addTimecode}
                                onBlur={bluredTimecode}
                            />
                        </ContentContainer>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={opcode}>
                        <AddOpcode updateSetup={updateOpcode} />
                    </Collapse>
                </Grid>
                <div className={classes.section}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateStreamClicked}
                    >
                        {content.createStream.button}
                    </Button>
                </div>
            </Grid>
        </div >
    );
}
