import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import CodeAccordion from '../stream-overview/Acoordion';
import DividerTitle from '../global/DividerTitle';
import CopyButton from '../global/CopyButton';
import content from '../../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    keyWrapper: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            maxWidth: '100%',
        },
        [theme.breakpoints.up('500')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
    },
    code: {
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('500')]: {
            marginBottom: 0
        },
        padding: theme.spacing(1, 1),
        width: '100%',
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.background.default,
        fontSize: '1.3em',
        marginRight: theme.spacing(1),
        wordWrap: 'anywhere',
    },
}))

export default function KeyAccordion(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY)
    const [orga, setOrga] = useState({
        playerKey: "",
    })

    useEffect(() => {
        if (localStorage.getItem(localStorageHelper.BINTU_ORGA)) {
            let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA))
            setOrga(orga);
        }
    }, [])

    return (
        <CodeAccordion title={content.organisation.keys.accordionTitle}>
            {
                [
                    { id: "api", label: content.organisation.keys.apiKey.title, underline: content.organisation.keys.apiKey.underline, key: apikey },
                    { id: "player", label: content.organisation.keys.playerKey.title, underline: content.organisation.keys.playerKey.underline, key: orga.playerKey }
                ].map((key, index) => (
                    <div key={index} className={classes.marginBottom}>
                        <DividerTitle title={key.label} />
                        <Typography variant="body1" color="textSecondary">
                            {key.underline}
                        </Typography>
                        <div className={classes.keyWrapper}>
                            <code className={classes.code} id={`${key.id}-key`}>
                                {key.key}
                            </code>
                            <CopyButton iconButton={false} copy={key.key} />
                        </div>
                    </div>
                ))
            }
        </CodeAccordion>
    )
}