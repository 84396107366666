import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { SettingsEthernet } from '@material-ui/icons';
import { Collapse, Grid, IconButton, TextField, Typography } from '@material-ui/core';

import Context from '../../utilities/context/Context';
import content from '../../utilities/helper/content';
import * as type from '../../utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import Tag from '../global/Tag';
import Title from '../global/Title';
import ContentContainer from '../global/ContentContainer';
import CreateWebhook from './CreateWebhook';
import CopyButton from '../global/CopyButton';
import DividerTitle from '../global/DividerTitle';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    link: {
        lineHeight: 1.1,
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '100%',
        overflowWrap: 'anywhere'
    },
}))

export default function Webhook() {
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false);
    const [webhook, setWebhook] = useState("");

    const handleCreateWebhook = () => {
        setOpenCreate(!openCreate);
    }

    const updateWebhook = () => {
        let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
        let webhookValue = orga.webhook
        setWebhook(webhookValue);
        setOpenCreate(false);
    }

    useEffect(() => {
        updateWebhook();
    }, [])

    return (
        <div className={classes.root}>
            <Title
                title={content.organisation.webhook.title}
                button={content.createWebhook.button}
                startIcon={<SettingsEthernet />}
                clicked={handleCreateWebhook}
            />
            <Grid item xs={12} className={classes.item}>
                <Collapse in={openCreate}>
                    <CreateWebhook update={updateWebhook} />
                </Collapse>
            </Grid>
            <ContentContainer>
                {
                    webhook === null
                        ?
                        <Typography variant="button" color="textSecondary">
                            {content.organisation.webhook.noWebhook}
                        </Typography>
                        :
                        <div className={classes.webhook}>
                            <Typography variant="subtitle1" className={classes.link}>
                                <TextField
                                    fullWidth
                                    disabled
                                    className={classes.textfield}
                                    value={webhook}
                                />
                                <CopyButton iconButton={true} copy={webhook} />
                            </Typography>
                        </div>
                }
            </ContentContainer>
        </div >
    )
}