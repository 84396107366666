import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import * as link from '../utilities/helper/link-config';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as method from '../utilities/helper/methods';

import SpinningLoadingLogo from '../components/utilities/SpinningLoadingLogo';
import PageHeader from '../components/global/PageHeader';
import CreatedStream from '../components/stream-overview/CreatedStream';
import RedirectLink from '../components/global/RedirectLink';
import NanoStreamWebcaster from '../components/webcaster/Webcaster';
import Title from '../components/global/Title';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    item: {
        marginTop: theme.spacing(2)
    },
    h5liveWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    header: {
        marginTop: theme.spacing(3)
    },
    section: {
        margin: theme.spacing(2, 0)
    },
    collapseButton: {
        marginTop: theme.spacing(1)
    },
}))

export default function NewStream(props) {
    const classes = useStyles();
    document.title = "nanoStream Webcaster"
    const { state, dispatch } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [streams, setStreams] = useState([]);
    const [enableSecure, setEnableSecure] = useState(false);
    const [transcodedStream, setTranscodedStream] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [notFound, setNotFound] = useState(false);
    const [videoSetup, setVideoSetup] = useState({});
    const [audioSetup, setAudioSetup] = useState({});
    const [transcodingOptions, setTranscodingOptions] = useState({
        vtrans1: {},
        vtrans2: {},
        vtrans3: {}
    });

    let params = props.match.params

    const getStream = (streamid) => {
        return new Promise((resolve, reject) => {
            bintu.getStream(localStorage.getItem(localStorageHelper.BINTU_APIKEY), streamid,
                (success) => {
                    let vodUrls = success.playout.web.filter((url) => url.type === "vod");
                    let liveUrls = success.playout.web.filter((url) => url.type === "live");
                    resolve({
                        data: success,
                        vodUrls: vodUrls,
                        liveUrls: liveUrls
                    });
                }, (error) => {
                    reject(error)
                });
        });
    }

    async function getStreamInformations(streamlist) {
        let fetchedStreamlist = [];
        for (let i = 0; i < streamlist.length; i++) {
            await getStream(streamlist[i])
                .then((success) => {
                    fetchedStreamlist.push(success);
                }).catch((error) => {
                    setNotFound(true);
                });
        }
        setStreams(fetchedStreamlist);
        setLoading(false);
    }

    const openInNewTab = (url) => (event) => {
        window.open(url, '_blank');
    }


    useEffect(() => {
        setLoading(true);

        let passthrough = props.match.params
        let searchParams = method.GET_PARAMS(props.location.search)

        let streamlist = Object.values(passthrough);
        let options = {
            vtrans1: {},
            vtrans2: {},
            vtrans3: {},

        };

        if (Object.keys(searchParams).length > 1) {
            if (searchParams.hasOwnProperty("vtrans1")) {
                if (searchParams.vtrans1.hasOwnProperty("id")) {
                    streamlist.push(searchParams.vtrans1.id)
                }
                options.vtrans1 = searchParams.vtrans1
            }
            if (searchParams.hasOwnProperty("vtrans2")) {
                if (searchParams.vtrans2.hasOwnProperty("id")) {
                    streamlist.push(searchParams.vtrans2.id)
                }
                options.vtrans2 = searchParams.vtrans2
            }
            if (searchParams.hasOwnProperty("vtrans3")) {
                if (searchParams.vtrans3.hasOwnProperty("id")) {
                    streamlist.push(searchParams.vtrans3.id)
                }
                options.vtrans3 = searchParams.vtrans3
            }
            if (searchParams.hasOwnProperty("startIndex")) setStartIndex(searchParams.startIndex)
        }

        setTranscodingOptions(options);

        if (searchParams.hasOwnProperty("webcaster")) {

            let updatedAudioSetup = {};
            let updatedVideoSetup = {};


            if (searchParams.webcaster.hasOwnProperty("framerate")) _.extend(updatedVideoSetup, { framerate: searchParams.webcaster.framerate })
            if (searchParams.webcaster.hasOwnProperty("resolution")) {
                let resolution = searchParams.webcaster.resolution.split("x");
                _.extend(updatedVideoSetup, { resolution: { width: resolution[0], height: resolution[1] } })
            }
            if (searchParams.webcaster.hasOwnProperty("videobitrate")) _.extend(updatedVideoSetup, { bitrate: searchParams.webcaster.videobitrate })
            if (searchParams.webcaster.hasOwnProperty("audiobitrate")) _.extend(updatedAudioSetup, { bitrate: searchParams.webcaster.audiobitrate })

            setAudioSetup(updatedAudioSetup);
            setVideoSetup(updatedVideoSetup);
        }


        if (streamlist.length > 1) setTranscodedStream(true);

        getStreamInformations(streamlist);
        setEnableSecure(localStorage.getItem(localStorageHelper.BINTU_ORGA) ? JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)).secure : false);
    }, [])

    useEffect(() => {
        method.SEND_GA_ANALYTICS("/webcaster")
    }, [])

    return (
        <div className={classes.root}>
            <Grid container>
                {
                    loading
                        ? <SpinningLoadingLogo />
                        : <PageHeader
                            title={`${!(!loading && notFound) ? `${transcodedStream ? content.newStream.title.transcoding : content.newStream.title.singleStream}` : content.newStream.title.notFound} `}
                            underline={`${!(!loading && notFound) ? content.newStream.underline.webcaster : content.newStream.underline.notFound} `}
                        />
                }
                {
                    !(!loading && notFound)
                        ?
                        <Grid item xs={12}>
                            <Title
                                title={content.newStream.webcaster.title}
                                button="nanoStream Webcaster Docs"
                                endIcon={<OpenInNew />}
                                clicked={openInNewTab(`${link.WEBCASTER_DOCS}`)}
                            />
                            <NanoStreamWebcaster
                                streams={streams}
                                startIndex={startIndex}
                                enableTranscoding={transcodedStream}
                                audio={audioSetup}
                                video={videoSetup}
                            />
                            <CreatedStream
                                enableSecure={enableSecure}
                                streams={streams}
                                startIndex={startIndex}
                                enableTranscoding={transcodedStream}
                                transcodingInformations={transcodingOptions}
                            />
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <RedirectLink to={link.STREAMS}>
                                <Button
                                    className={classes.collapseButton}
                                    color="primary"
                                    variant="contained"
                                >
                                    Back to streamlist
                                </Button>
                            </RedirectLink>
                        </Grid>
                }
            </Grid>
        </div>
    )
}