import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Help, Description } from '@material-ui/icons';

import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import QuickLink from '../components/utilities/QuickLink';

import logo from '../assets/another_logo.png';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    contentWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    quickLinks: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('700')]: {
            flexDirection: 'row',
            justifyContent: 'center'
        }

    },
    content: {
        textAlign: 'center'
    },
    image: {
        height: 300,
    },
    header: {
        color: theme.palette.primary.main,
        lineHeight: 1,
        fontWeight: 900,
    },
    overline: {
        marginTop: theme.spacing(1),
        display: 'block',
        color: theme.palette.secondary.main,
        lineHeight: 1,
        fontWeight: 900
    }
}))

export default function Support(props) {
    const classes = useStyles();
    document.title = "nanoStream Cloud Support";
    const [quickLinks, setQuickLinks] = useState([]);
    let orga = localStorage.getItem(localStorageHelper.BINTU_ORGA) ? JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)) : false;

    useEffect(() => {
        let supportUrl = `${link.NANO_SUPPORT}?bintu.id=${orga ? orga.id : ""}&bintu.name=${orga ? orga.name : ""}&bintu.webhook=${orga ? orga.webhook : ""}&bintu.vod=${orga ? orga.vod : ""}&bintu.webrtc=${orga ? orga.webrtc : ""}&bintu.transcoding=${orga ? orga.transcoding : ""}&bintu.h5live=${orga ? orga.h5live : ""}&bintu.hash=${orga ? orga.hash : ""}&bintu.hls=${orga ? orga.hls : ""}&bintu.secure=${orga ? orga.secure : ""}&bintu.metrics=${orga ? orga.metrics : ""}&bintu.sla=${orga ? orga.sla : ""}&bintu.trial=${orga ? orga.trial : ""}`
        if (localStorage.getItem(localStorageHelper.AUTHORIZED) || localStorage.getItem(localStorageHelper.BINTU_APIKEY)) {
            setQuickLinks([
                { label: content.support.docs.title, text: content.support.docs.underline, window: link.DOCS, icon: <Description fontSize="small" /> },
                { label: content.support.support.title, text: content.support.support.underline, window: supportUrl, icon: <Help fontSize="small" /> }
            ])
        }
    }, [])

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname)
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <img className={classes.image} src={logo} />
                    <Typography className={classes.overline} variant="button" gutterBottom>
                        {content.support.overline}
                    </Typography>
                    <Typography className={classes.header} variant="h4" gutterBottom>
                        {content.support.title}
                    </Typography>
                    {/* <Button
                        variant="contained"
                        color="primary"
                    >
                        Home
                    </Button> */}
                    <div className={classes.quickLinks}>
                        {
                            quickLinks.map((link) => (
                                <QuickLink
                                    key={link.label}
                                    label={link.label}
                                    window={link.window}
                                    icon={link.icon}
                                    text={link.text}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}