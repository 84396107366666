import React, { useContext } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Table, TableContainer, TableRow, TableCell, TableBody, Button, Divider } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import Context from '../../utilities/context/Context';
import * as type from '../../utilities/context/type';
import * as link from '../../utilities/helper/link-config';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import CopyButton from '../global/CopyButton';
import RedirectLink from '../global/RedirectLink';
import ContentContainer from '../global/ContentContainer';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1, 2, 2, 2),
        borderRadius: theme.spacing(0.5),
        // border: `1px solid #f3f3f3`
    },
    headerWrapper: {
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginRight: theme.spacing(2),
        lineHeight: 1,
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('500')]: {
            marginBottom: 0
        },
    },
    underline: {
        marginBottom: theme.spacing(1.5),
        lineHeight: 1.2
    },
    value: {
        lineHeight: 1.1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        wordBreak: 'break-word',
    },
    label: {
        [theme.breakpoints.up('500')]: {
            width: 200,
        },
        color: theme.palette.text.secondary,
        lineHeight: 1.2,
    },
    tagWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    date: {
        lineHeight: 1
    },
    link: {
        cursor: 'pointer',
    }
}))

export default function PlayoutTable(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);

    const openInNewTab = () => {
        window.open(props.to, '_blank')
    }

    const handleOpenLinkClicked = (url) => (event) => {
        event.preventDefault();
        window.open(url, '_blank');
        return;
    }

    return (
        <ContentContainer>
            <div className={classes.headerWrapper}>
                <Typography variant="h6" className={classes.title}>
                    {props.label}
                </Typography>
                <div>
                    {
                        props.buttonLabel
                            ?
                            <RedirectLink to={props.to}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    endIcon={props.buttonIcon}
                                >
                                    {props.buttonLabel}
                                </Button>
                            </RedirectLink>
                            : null
                    }
                    {
                        props.openInNew
                            ?
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                endIcon={<OpenInNew />}
                                onClick={openInNewTab}
                            >
                                {props.openInNew}
                            </Button>
                            : null
                    }
                </div>
            </div>
            {
                props.underline
                    ?
                    <Typography variant="body1" className={classes.underline} color="textSecondary">
                        {props.underline}
                    </Typography>
                    : null
            }
            <Divider />
            <TableContainer className={classes.table}>
                <Table size="small">
                    {
                        props.list
                            ?
                            <TableBody>
                                {
                                    props.list.map((playout, id) => (
                                        <TableRow key={id}>
                                            <TableCell className={classes.label}>
                                                <Typography variant="button">
                                                    {playout.type} Playout URL
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color="textSecondary" variant="subtitle1" className={classes.value}>
                                                    <span>
                                                        <Link
                                                            className={classes.link}
                                                            color="primary"
                                                            underline="always"
                                                            href={playout.url}
                                                            onClick={handleOpenLinkClicked(playout.url)}
                                                        >
                                                            {playout.url}
                                                        </Link>
                                                        {playout.size ? ` (${(playout.size / (1024 * 1024)).toFixed(2)} MB)` : ""}
                                                    </span>
                                                    <CopyButton iconButton={true} copy={playout.url} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                            : null
                    }
                </Table>
            </TableContainer>
        </ContentContainer>
    )
}