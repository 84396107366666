import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    header: {
        paddingBottom: theme.spacing(1)
    },
    title: {
        fontWeight: 900,
        lineHeight: 1,
    },
    underline: {
        lineHeight: 1
    }
}))

export default function PageHeader(props) {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.header}>
            <Typography color="primary" variant="h4" className={classes.title}>
                {props.title}
            </Typography>
            {
                props.underline
                    ?
                    <Typography className={classes.underline} color="secondary" variant="body1">
                        {props.underline}
                    </Typography>
                    : null
            }
        </Grid>
    )
}