import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        margin: theme.spacing(1.5, 0),
        display: 'block',
        width: '100%'
    },
    select: {
        width: '100%',
        minWidth: '100%'
    },
    checkboxLabel: {
        color: theme.palette.secondary.light
    },
    control: {
        marginTop: theme.spacing(1),
        width: '100%',
    }
}))

export default function TranscodingProfile(props) {
    const classes = useStyles();
    const [profile, setProfile] = useState(props.value)

    const handleSelectedProfile = (event) => {
        let selectedValue = event.target.value;
        props.setVtransProfile(props.identifier, selectedValue);
        setProfile(selectedValue);
    }

    const handleCheckboxChanged = (event) => {
        props.updateDisabledState(props.identifier);
    }

    useEffect(() => {
        setProfile(props.value);
    }, [props.value])

    return (
        <FormGroup className={classes.root}>
            <FormControlLabel
                classes={{ label: classes.checkboxLabel }}
                control={
                    <Checkbox
                        disabled={!props.choosable}
                        color="primary"
                        checked={!props.disabled}
                        name={`${props.identifier}`}
                        onChange={handleCheckboxChanged}
                    />
                }
                label={`Add ${props.label}`}
            />
            <FormControl className={classes.control}>
                <InputLabel color="primary">{props.label}</InputLabel>
                <Select
                    color="primary"
                    disabled={props.disabled}
                    className={classes.select}
                    value={profile}
                    onChange={handleSelectedProfile}
                >
                    {/* <MenuItem
                        value={"None"}
                    >
                        None
                    </MenuItem> */}
                    {
                        props.profiles.map((profile, index) => (
                            <MenuItem
                                key={index}
                                value={profile.identifier}
                            >
                                {
                                    `
                                    ${profile.width === 0
                                        ? ``
                                        : `Resolution: ${profile.width} x ${profile.height},`
                                    }
                                    Bitrate: ${profile.bitrate} kbit/s,
                                    FPS: ${profile.fps}
                                    `
                                }
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </FormGroup>
    )
}