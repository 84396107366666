import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';
import content from '../../utilities/helper/content';

import DividerTitle from '../global/DividerTitle';
import CopyButton from '../global/CopyButton';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    textfieldWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    infoText: {
        lineHeight: 1.2,
        marginBottom: theme.spacing(2)
    },
    title: {
        fontWeight: 700,
        marginTop: theme.spacing(1.5),
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1)
    },
    link: {
        lineHeight: 1.1,
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '100%',
        overflowWrap: 'anywhere'
    },
    url: {
        marginRight: theme.spacing(1)
    },
    textfield: {
        width: 450,
        maxWidth: '100%'
    }
}))

export default function CreateInviteToken(props) {
    const classes = useStyles();
    const url = `${link.URL}${link.INVITE_TOKEN}/${props.token}`

    const openInNewTab = (event) => {
        event.preventDefault();
        window.open(url, '_target')
    }

    return (
        <ContentContainer>
            <DividerTitle title={content.inviteToken.title} />
            <div>
                <Typography variant="subtitle1" className={classes.link}>
                    <TextField
                        disabled
                        className={classes.textfield}
                        value={url}
                    />
                    <IconButton
                        size="small"
                        onClick={openInNewTab}
                    >
                        <OpenInNew fontSize="inherit" />
                    </IconButton>
                    <CopyButton iconButton={true} copy={url} />
                </Typography>
            </div>
        </ContentContainer>
    )
}