import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Typography, FormControlLabel, Checkbox, Link, Collapse } from '@material-ui/core';
import { Mail, Lock, ArrowForward, Business } from '@material-ui/icons'


import Context from '../../utilities/context/Context';
import * as type from '../../utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as link from '../../utilities/helper/link-config';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import OutlinedTextfield from './OutlinedTextfield';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        borderRadius: theme.spacing(0.5)
    },
    optionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(1)
    },
    warning: {
        color: theme.palette.error.main,
        margin: theme.spacing(1)
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

export default function SignUp(props) {
    const classes = useStyles();
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { dispatch } = useContext(Context);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [warning, setWarning] = useState(false);
    const [textfields, setTextfields] = useState({
        email: {
            value: "",
            id: "email",
            autoComplete: "email",
            label: "E-Mail",
            type: "text",
            error: false,
            icon: <Mail color="primary" />
        },
        organisation: {
            value: "",
            id: "organisation",
            autoComplete: "organisation",
            label: "Company name",
            type: "text",
            error: false,
            icon: <Business color="primary" />
        },
        password: {
            value: "",
            id: "password",
            autoComplete: "new-password",
            label: "Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />
        },
        confirmPassword: {
            value: "",
            id: "confirmPassword",
            autoComplete: "new-password",
            label: "Confirm Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />
        }
    })

    const setValue = (value, property) => {
        let settedValue = value;
        if (property === textfields.email.id) {
            // settedValue = settedValue.toLowerCase();
        }
        setTextfields({
            ...textfields,
            [property]: {
                ...textfields[property],
                value: settedValue
            }
        });
    }

    const handleSubmit = (event, property) => {
        if (property === textfields.confirmPassword.id) {
            createUser(event);
        }
    }

    const checkIfValid = (value, property) => {
        switch (property) {
            case "email":
                setTextfields({
                    ...textfields,
                    [property]: {
                        ...textfields[property],
                        error: !mailRegex.test(value)
                    }
                });
                break;
            case "confirmPassword":
                let valid = textfields.confirmPassword.value === textfields.password.value
                setTextfields({
                    ...textfields,
                    password: {
                        ...textfields.password,
                        error: !valid
                    },
                    confirmPassword: {
                        ...textfields.confirmPassword,
                        error: !valid
                    }
                });
                break;
            default: break;
        }
    }

    // Google Ads Tracking Code
    const reportGTagConversion = (url) => {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        window.gtag('event', 'conversion', {
            'send_to': 'AW-1071822910/bNrLCK_FpPgCEL7wiv8D',
            'event_callback': callback
        });
    }

    const sendAnalyticsEvent = () => {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'bintu_sign_up',
            eventAction: 'bintu_sign_up',
            eventLabel: 'Bintu Sign Up'
          });
    }

    const createUser = (event) => {
        event.preventDefault();
        if (!acceptTerms) {
            setWarning(true);
            return;
        }
        let mailInvalid = !mailRegex.test(textfields.email.value);
        let orgaInvalid = textfields.organisation.value === "";
        let passwordInvalid = textfields.password.value === "";
        let confirmPasswordInvalid = textfields.password.value !== textfields.confirmPassword.value || textfields.confirmPassword.value === "";

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            organisation: {
                ...textfields.organisation,
                error: orgaInvalid
            },
            password: {
                ...textfields.password,
                error: passwordInvalid
            },
            confirmPassword: {
                ...textfields.confirmPassword,
                error: confirmPasswordInvalid
            }
        });

        if (mailInvalid || orgaInvalid || passwordInvalid || confirmPasswordInvalid) return;

        bintu.createUser(textfields.email.value, textfields.password.value, textfields.organisation.value,
            (response) => {
                localStorage.setItem(localStorageHelper.AUTHORIZED, true);
                localStorage.setItem(localStorageHelper.BINTU_APIKEY, response.apikey.key)
                localStorage.setItem(localStorageHelper.BINTU_MAIL, textfields.email.value);
                localStorage.setItem(localStorageHelper.BINTU_ORGA, JSON.stringify(response.organisation));
                props.signin();

                sendAnalyticsEvent();
                reportGTagConversion();
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const handleAcceptTerms = () => {
        if (warning) setWarning(false);
        setAcceptTerms(!acceptTerms)
    }

    return (
        <div className={classes.root}>
            <form autoComplete>
                <div>
                    {
                        [
                            textfields.email,
                            textfields.organisation,
                            textfields.password,
                            textfields.confirmPassword
                        ].map((textfield, index) => (
                            <OutlinedTextfield
                                key={`${textfield.id}-${index}`}
                                id={`${textfield.id}-${index}`}
                                autoComplete={textfield.autoComplete}
                                label={textfield.label}
                                type={textfield.type}
                                error={textfields[textfield.id].error}
                                icon={textfield.icon}
                                changed={(event) => { setValue(event.target.value, textfield.id) }}
                                blured={(event) => { checkIfValid(event.target.value, textfield.id) }}
                                submit={(event) => { handleSubmit(event, textfield.id) }}
                            />
                        ))
                    }
                    <div className={classes.optionWrapper}>
                        <FormControlLabel
                            className={classes.checkboxLabel}
                            control={<Checkbox color="primary" name="acceptTerms" />}
                            label={
                                <Typography variant="overline" color="textSecondary">
                                    Accept <Link href={link.IMPRINT} target="_blank">terms and conditions</Link>
                                </Typography>
                            }
                            checked={acceptTerms}
                            onChange={handleAcceptTerms}
                        />
                    </div>
                    <Collapse in={warning}>
                        <Typography className={classes.warning} variant="caption">
                            Please accept our terms and conditions.
                        </Typography>
                    </Collapse>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={createUser}
                    >
                        Sign Up
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={props.signin}
                    >
                        Login
                    </Button>
                </div>
            </form>
        </div>
    )
}