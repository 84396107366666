import React, { useEffect, useState } from 'react';

import * as link from '../../utilities/helper/link-config';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    textfield: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '45%',
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('md')]: {
            width: 250,
            marginRight: theme.spacing(1)
        }
    }
}));

export default function SpecificOptions(props) {
    const classes = useStyles();
    const [informations, setInformations] = useState({
        ip: {
            value: props.ip,
            identifier: "ip",
            label: "IP",
            helperText: "1.1.1.1.",
            link: link.TOKEN_SECURITY_IP
        },
        referer: {
            value: props.referer,
            identifier: "referer",
            label: "Referer",
            helperText: "123.your-referer.net",
            link: link.TOKEN_SECURITY_REFERER
        }
    });

    const handleInsertedInformation = (event, identifier) => {
        let value = event.target.value;
        props.updateValue(identifier, value);
    }

    useEffect(() => {
        setInformations({
            ip: {
                ...informations.ip,
                value: props.ip
            },
            referer: {
                ...informations.referer,
                value: props.referer
            }
        })
    }, [props.ip, props.referer])

    return (
        <div className={classes.root}>
            {
                [
                    informations.ip,
                    informations.referer
                ].map((info, index) => (
                    <TextField
                        color="primary"
                        key={index}
                        fullWidth
                        className={classes.textfield}
                        InputLabelProps={{ shrink: true }}
                        label={info.label}
                        value={info.value}
                        placeholder={info.helperText}
                        InputProps={{
                            endAdornment:
                                <IconButton
                                    size="small"
                                    onClick={() => { window.open(info.link, '_blank') }}
                                >
                                    <Help />
                                </IconButton>
                        }}
                        onChange={(event) => handleInsertedInformation(event, info.identifier)}
                    />
                ))
            }
        </div>
    );
}