import React from 'react';

import moment from 'moment';

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import codeStyle from "react-syntax-highlighter/dist/esm/styles/prism/material-light";

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import * as link from '../../utilities/helper/link-config';
import content from '../../utilities/helper/content';

import ModalWrapper from './ModalWrapper';
import OutlinedTable from '../stream-overview/OutlinedTable';
import JsonObject from '../stream-overview/JsonObject';
import CodeAccordion from '../stream-overview/Acoordion';
import CopyButton from '../global/CopyButton';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        padding: theme.spacing(2),
    },
    bold: {
        fontWeight: 700
    },
    informationWrapper: {
        // backgroundColor: theme.palette.primary.light,
        margin: theme.spacing(0, 2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        borderRadius: theme.spacing(0.5),
    },
    info: {
        lineHeight: 1.1,
    },
    codeWrapper: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    codeSnippet: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    text: {
        marginRight: theme.spacing(1)
    }
}));

export default function SecurityTokenPreview(props) {
    const classes = useStyles();
    let token = props.token;

    return (
        <ModalWrapper
            title={
                <Typography variant="h5" className={classes.title}>
                    You created a new <span className={classes.bold}>playback token</span>!
                </Typography>
            }
            open={props.open}
            onClose={props.close}
        >
            <div className={classes.informationWrapper}>
                <Typography variant="subtitle1" className={classes.info}>
                    Please take a screenshot of this Modal. <br />
                    After refreshing or closing this window you can not access to this specific token again.
                </Typography>
            </div>
            {
                <div className={classes.root}>

                    {
                        props.token.security
                            ?
                            <OutlinedTable
                                label="Overview"
                                list={[
                                    { label: "Token", value: token.security.token },
                                    { label: props.secureOrg.ident, value: props.secureOrg.value },
                                    { label: "IP", value: props.informations.ip === "" ? "-" : props.informations.ip },
                                    { label: "Referer", value: props.informations.referer === "" ? "-" : props.informations.referer },
                                    { label: "Expires", date: { date: moment(token.expires).format('MM/DD/YYYY hh:mm a') } },
                                    { label: "Tag", tags: token.security.tag === "" ? [] : [token.security.tag] },
                                    // { label: "Playout URL", playout: `${link.URL}${link.PLAYOUT}/${props.secureOrg.ident === "Streamname" ? props.secureOrg.value : "[YOUR-STREAM-ID]"}?security.expires=${token.security.expires}&security.token=${token.security.token}&security.options=${token.security.options}` }
                                    { label: "Playout URL", playout: `${link.URL}${link.PLAYOUT}/YOUR-STREAM-ID?security.expires=${token.security.expires}&security.token=${token.security.token}&security.options=${token.security.options}` }


                                ]}
                            />
                            : null
                    }
                </div>
            }
            {
                token.security
                    ?
                    <div className={classes.codeWrapper}>
                        <CodeAccordion title={content.createSecurePlayoutToken.codeSnippet.title}>
                            <div className={classes.codeSnippet}>
                                <Typography className={classes.text} variant="body1" color="textSecondary">
                                    {content.createSecurePlayoutToken.codeSnippet.underline}<br />
                                </Typography>
                                <CopyButton
                                    iconButton={false}
                                    copy={JSON.stringify({
                                        expires: token.security.expires,
                                        tag: token.security.tag,
                                        token: token.security.token,
                                        options: token.security.options
                                    }, null, 2)}
                                />
                            </div>
                            <JsonObject>
                                <SyntaxHighlighter showLineNumbers language="html" style={codeStyle}>
                                    {
                                        JSON.stringify({
                                            expires: token.security.expires,
                                            tag: token.security.tag,
                                            token: token.security.token,
                                            options: token.security.options
                                        }, null, 2)
                                    }
                                </SyntaxHighlighter>
                            </JsonObject>
                        </CodeAccordion>
                    </div>
                    : null
            }
        </ModalWrapper>
    );
}
