import React, { useState } from 'react';

import moment from 'moment';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import content from '../../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    datepicker: {
        margin: 0,
        [theme.breakpoints.up('xs')]: {
            width: '50%'
        },
        [theme.breakpoints.up('800')]: {
        },
    }
}))

export default function DateSelection(props) {
    const classes = useStyles();
    const [openDatepicker, setOpenDatepicker] = useState(false);

    const handleChangedDate = (updatedDate, id) => {
        if (openDatepicker) {
            props.setDate(updatedDate.toISOString(), id);
        }
    }

    const checkIfValidDate = (event, id) => {
        let updatedDate = new Date(event.target.value);
        if (updatedDate === null) {
            props.resetDate(id);
            return;
        }
        if (updatedDate.toString() === "Invalid Date" || updatedDate.getDate() === NaN || updatedDate.getMonth() === NaN || updatedDate.getFullYear() === NaN || updatedDate === "Invalid Date") {
            props.resetDate(id);
            return;
        }
        let date = new Date(new Date());
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let settedDay = updatedDate.getDate();
        let settedMonth = updatedDate.getMonth() + 1;
        let settedYear = updatedDate.getFullYear();

        if (day === settedDay && month === settedMonth && year === settedYear) {
            let newDate = moment(new Date(Date.now())).endOf('day').toISOString();
            props.setDate(newDate, id);
            return;
        }
        props.setDate(updatedDate.toISOString(), id);
    }


    return (
        <div className={classes.filterSection}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    allowKeyboardControl={true}
                    className={classes.datepicker}
                    disableToolbar
                    maxDate={props.date.until}
                    maxDateMessage={content.filter.date.error.maxStartDate}
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Start Date"
                    value={props.date.from}
                    onOpen={() => { setOpenDatepicker(true) }}
                    onClose={() => { setOpenDatepicker(false) }}
                    onChange={(date) => handleChangedDate(date, "from")}
                    onBlur={(event) => checkIfValidDate(event, "from")}
                />
                <KeyboardDatePicker
                    className={classes.datepicker}
                    disableToolbar
                    minDate={props.date.from}
                    minDateMessage={content.filter.date.error.minEndDate}
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="End Date"
                    value={props.date.until}
                    onOpen={() => { setOpenDatepicker(true) }}
                    onClose={() => { setOpenDatepicker(false) }}
                    onChange={(date) => handleChangedDate(date, "until")}
                    onBlur={(event) => checkIfValidDate(event, "until")}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}