import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    select: {
        width: '100%',
        minWidth: 100
    }
}))

export default function StateSelection(props) {
    const classes = useStyles();

    const handleChangedState = (event) => {
        props.setState(event.target.value);
    }

    return (
        <FormControl className={classes.select}>
            <InputLabel shrink>State</InputLabel>
            <Select
                displayEmpty
                value={props.state}
                onChange={handleChangedState}
            >
                {
                    [
                        { label: "--", value: "" },
                        { label: "Live", value: "live" },
                        { label: "Created", value: "created" },
                        { label: "Ended", value: "ended" },
                        { label: "Deleted", value: "deleted" },
                    ].map((state, index) => (
                        <MenuItem value={state.value} key={index}>{state.label}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}