import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        lineHeight: 1.2
    },
    divider: {
        marginBottom: theme.spacing(1)
    }
}))

export default function DividerTitle(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.title}>
                {props.title}
            </Typography>
            {
                props.underline
                    ?

                    <Typography variant="body1" color="textSecondary" className={classes.underline}>
                        {props.underline}
                    </Typography>
                    : null
            }
        </div>
    )
}