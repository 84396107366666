import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

import ContentContainer from '../global/ContentContainer';
import DividerTitle from '../global/DividerTitle';
import ExpirationDate from './ExpirationDate';
import SpecificOptions from './SpecificOptions';
import AddTags from '../helper/AddTags';
import content from '../../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    title: {
        fontWeight: 700,
        marginTop: theme.spacing(1.5),
        color: theme.palette.primary.main
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    formControl: {
        width: '100%'
    },
    textfieldRow: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',

        }
    },
    textfield: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 250,
            marginRight: theme.spacing(1)
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
}));

const BodyTitle = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="body1" className={classes.title}>
            {props.children}
        </Typography>
    )
}

export default function QuickTokenCreator(props) {
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [secureOrg, setSecureOrg] = useState("Stream");
    const [tokenSetup, setTokenSetup] = useState({
        expirationDate: new Date(Date.now() + 12096e5),
        ip: "",
        referer: "",
    });

    const deleteTag = (tag) => {
        let updatedTagArray = tags.filter((singleTag) => singleTag !== tag);
        setTags(updatedTagArray);
    }

    const addNewTag = (tag) => {
        let updatedTagArray = [tag];
        setTags(updatedTagArray);
    }

    const updateTokenSetup = (identifier, value) => {
        setTokenSetup({
            ...tokenSetup,
            [identifier]: value
        })
    };

    const handleCreateSecureToken = () => {
        console.log(tags)
        let body = {
            tag: tags.length === 1 ? tags[0] : "",
            expires: Math.round(tokenSetup.expirationDate / 1000)
        }

        if (tokenSetup.ip !== "") { body.ip = tokenSetup.ip }
        if (tokenSetup.referer !== "") { body.referer = tokenSetup.referer }

        props.createToken(body, secureOrg === "Organisation Hash");
    }

    const handleSwitchedRadio = (event) => {
        setSecureOrg(event.target.value)
    }

    return (
        <ContentContainer>
            <DividerTitle title={content.createSecurePlayoutToken.title} />
            <Typography variant="body1" color="textSecondary">
                {content.createQuickPlayoutToken.underline}
            </Typography>
            <BodyTitle>
                {content.createQuickPlayoutToken.createTokenFor.title}
            </BodyTitle>
            <FormControl className={classes.formControl}>
                <RadioGroup
                    className={classes.radioGroup}
                    value={secureOrg}
                    onChange={handleSwitchedRadio}
                >
                    <FormControlLabel value="Stream" control={<Radio />} label="Stream" />
                    <FormControlLabel value="Organisation Hash" control={<Radio />} label="Organisation Hash" />
                </RadioGroup>
            </FormControl>
            <BodyTitle>
                {content.createQuickPlayoutToken.expirationDate.title}
            </BodyTitle>
            <ExpirationDate
                date={tokenSetup.expirationDate}
                updateValue={updateTokenSetup}
            />
            <BodyTitle>
                {content.createQuickPlayoutToken.specificOptions.title}
            </BodyTitle>
            <SpecificOptions
                ip={tokenSetup.ip}
                referer={tokenSetup.referer}
                updateValue={updateTokenSetup}
            />
            <BodyTitle>
                {content.createQuickPlayoutToken.addTags.title}
            </BodyTitle>
            <AddTags
                tags={tags}
                addNewTag={addNewTag}
                deleteTag={deleteTag}
            />
            <div className={classes.section}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleCreateSecureToken}
                >
                    {content.createQuickPlayoutToken.button}
                </Button>
            </div>
        </ContentContainer>
    );
}