import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { fade, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.2, 0.8),
        borderRadius: '5px',
        textAlign: 'center',
        display: 'inline-block',
        marginRight: theme.spacing(0.5)
    },
    stateLabel: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    live: {
        backgroundColor: fade(theme.palette.success.main, 0.2),
        color: theme.palette.success.main
    },
    ended: {
        backgroundColor: fade(theme.palette.error.dark, 0.2),
        color: theme.palette.error.dark
    },
    created: {
        backgroundColor: fade(theme.palette.info.main, 0.2),
        color: theme.palette.info.main
    },
    deleted: {
        backgroundColor: fade(theme.palette.common.black, 0.2),
        color: theme.palette.common.black
    }
}));

export default function StateSection(props) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, classes[props.state])}>
            {/* <div className={clsx(classes.state, classes[props.state])} /> */}
            <Typography variant="button" className={classes.stateLabel} >
                {props.state}
            </Typography >
        </div >
    );
}
