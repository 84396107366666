import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Collapse } from '@material-ui/core';

import Context from '../utilities/context/Context';
import * as type from '../utilities/context/type';
import * as link from '../utilities/helper/link-config';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as methods from '../utilities/helper/methods';

import SecurityTokenPreview from '../components/modal/SecurityTokenPreview';
import PageHeader from '../components/global/PageHeader';
import TokenCreator from '../components/token/TokenCreator';
import content from '../utilities/helper/content';



const useStyles = makeStyles((theme) => ({
    root: {
    },
    item: {
        marginTop: theme.spacing(2),
        background: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    datepicker: {
        margin: theme.spacing(1, 2, 0, 0)
    },
    formControl: {
        width: '100%'
    },
    textfieldRow: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',

        }
    },
    textfield: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 250,
            marginRight: theme.spacing(1)
        }
    }
}));

export default function PlaybackToken() {
    const classes = useStyles();
    document.title = "Create playback token."
    const { dispatch } = useContext(Context);
    const [redirectRoute, setRedirectRoute] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [createdToken, setCreatedToken] = useState({})
    const [apikey] = useState(localStorage.getItem(localStorageHelper.BINTU_APIKEY));
    const [secureOrg, setSecureOrg] = useState("Streamname");
    const [secureOrgValue, setSecureOrgValue] = useState("");
    const [tokenSetup, setTokenSetup] = useState({
        expirationDate: new Date(Date.now() + 12096e5),
        ip: "",
        referer: "",
    });

    const closeModal = () => {
        setOpenModal(false);
    };

    const updateTokenInfo = (tokenSetup, secureOrg, secureOrgValue) => {
        setTokenSetup(tokenSetup);
        setSecureOrg(secureOrg);
        setSecureOrgValue(secureOrgValue)
    }

    const createSecureToken = (body) => {
        bintu.createSecureToken(apikey, body,
            (success) => {
                setOpenModal(true);
                setCreatedToken(success.h5live);
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            });
    }


    useEffect(() => {
        if (localStorage.getItem(localStorageHelper.BINTU_ORGA)) {
            if (!JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)).secure) {
                setRedirectRoute(<Redirect push to={link.DASHBOARD} />)
            }
        }
    }, [localStorage.getItem(localStorageHelper.BINTU_ORGA)])

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname);
    }, [])

    return (
        <div className={classes.root}>
            {redirectRoute}
            <Collapse in={openModal}>
                <SecurityTokenPreview
                    open={openModal}
                    close={closeModal}
                    token={createdToken}
                    informations={{
                        ip: tokenSetup.ip.value,
                        referer: tokenSetup.referer.value
                    }}
                    secureOrg={{
                        ident: secureOrg,
                        value: secureOrgValue
                    }}
                />
            </Collapse>
            <Grid container>
                <PageHeader title={content.createSecurePlayoutToken.title} underline={content.createSecurePlayoutToken.underline} />
                <TokenCreator
                    createToken={createSecureToken}
                    updateOverviewInfo={updateTokenInfo}
                />
            </Grid>
        </div>
    );
}
