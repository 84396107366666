import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';

import StateSection from '../stream-overview/State';
import Tag from '../global/Tag';
import ContentContainer from '../global/ContentContainer';
import CopyButton from '../global/CopyButton';
import Table from '../stream-overview/Table';

const useStyles = makeStyles((theme) => ({
    date: {
    },
    stateDateWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    }
}))

export default function TranscodedStreamInfoSection(props) {
    const classes = useStyles();
    const streams = props.streams;
    const [streamInfos, setStreamInfos] = useState([])
    const profiles = {
        1: props.transcodingOptions.vtrans1,
        2: props.transcodingOptions.vtrans2,
        3: props.transcodingOptions.vtrans3
    }

    useEffect(() => {
        let infos = [];
        if (streamInfos.length === 0 && props.streams.length > 0) {
            props.streams.map((stream, index) => (
                infos.push({
                    label: index === 0 ? "Passthrough" : `Profile ${index}`,
                    streamid: stream.id,
                    streamname: stream.playout.rtmp[0] !== undefined ? stream.playout.rtmp[0].streamname : "",
                    tags: stream.tags,
                })
            ));
            setStreamInfos(infos);
        }
    }, [props.streams])

    return (
        <div>
            <ContentContainer>
                <div className={classes.stateDateWrapper}>
                    <StateSection state={streams[0].state} />
                    <Typography color="textSecondary" variant="subtitle2" className={classes.date}>
                        {moment(streams[0].created_at).utc().format('MM/DD/YYYY hh:mm a')} UTC
                    </Typography>
                </div>
            </ContentContainer>
            {
                props.shareOption
                    ? <div>
                        {
                            streamInfos.map((stream, index) => (
                                <Table
                                    key={index}
                                    openInNew={`${props.shareOption === true ? `${stream.label} Single Stream Info` : undefined}`}
                                    to={`${link.STREAM}/${stream.streamid}`}
                                    label={stream.label}
                                    underline={index > 0 ? `${profiles[index].width !== undefined && profiles[index].height !== undefined ? `Resolution: ${profiles[index].width}x${profiles[index].height} ${profiles[index].bitrate !== undefined ? `Bitrate: ${profiles[index].bitrate}` : ""} ${profiles[index].fps !== undefined ? `Framerate: ${profiles[index].fps} fps` : ""}` : ""}` : false}
                                    list={[
                                        { label: "Streamid", value: stream.streamid },
                                        { label: "Streamname", value: stream.streamname },
                                        { label: "Stream tags", tags: stream.tags }
                                    ]}
                                />
                            ))
                        }
                    </div>
                    : <div >
                        {
                            streamInfos.map((stream, index) => (
                                <Table
                                    key={index}
                                    label={stream.label}
                                    underline={index > 0 ? `${profiles[index].width !== undefined && profiles[index].height !== undefined ? `Resolution: ${profiles[index].width}x${profiles[index].height} ${profiles[index].bitrate !== undefined ? `Bitrate: ${profiles[index].bitrate}` : ""} ${profiles[index].fps !== undefined ? `Framerate: ${profiles[index].fps} fps` : ""}` : ""}` : false}
                                    list={[
                                        { label: "Streamid", value: stream.streamid },
                                        { label: "Streamname", value: stream.streamname },
                                        { label: "Stream tags", tags: stream.tags }
                                    ]}
                                />
                            ))
                        }
                    </div>
            }
        </div>
    )
}