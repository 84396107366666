import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.primary.light,
        // color: theme.palette.common.white,
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(237, 125, 14, 0.2)',
        fontWeight: 600,
        borderRadius: theme.spacing(0.5),
        display: 'inline-block',
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

export default function Tag(props) {
    const classes = useStyles();

    const handleClicked = () => {
        if(props.handleClicked) {
            props.handleClicked(props.tag)
        }
    }

    return (
        <div className={classes.root} onClick={handleClicked}>
            {props.tag}
        </div>
    )
}