export const VTRANS_PROFILES = [
    // {
    //     identifier: "vtrans-2000x30",
    //     width: 0,
    //     height: 0,
    //     bitrate: 2000,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-2000x25",
    //     width: 0,
    //     height: 0,
    //     bitrate: 2000,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-2000x25",
    //     width: 0,
    //     height: 0,
    //     bitrate: 2000,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1600x30",
    //     width: 0,
    //     height: 0,
    //     bitrate: 1600,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1600x25",
    //     width: 0,
    //     height: 0,
    //     bitrate: 1600,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1000k",
    //     width: 0,
    //     height: 0,
    //     bitrate: 1000,
    //     fps: "copy",
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1000x30",
    //     width: 0,
    //     height: 0,
    //     bitrate: 1000,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1000x25",
    //     width: 0,
    //     height: 0,
    //     bitrate: 1000,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-800k",
    //     width: 0,
    //     height: 0,
    //     bitrate: 800,
    //     fps: "copy",
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-400k",
    //     width: 0,
    //     height: 0,
    //     bitrate: 400,
    //     fps: "copy",
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-400k15",
    //     width: 0,
    //     height: 0,
    //     bitrate: 400,
    //     fps: 15,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x2000x30",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 2000,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x2000x25",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 2000,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x1600x30",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 1600,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x1600x25",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 1600,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x1200x30",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 1200,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x1200x25",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 1200,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x1000x30",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 1000,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-1280x720x1000x25",
    //     width: 1280,
    //     height: 720,
    //     bitrate: 1000,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-852x480x800x30",
    //     width: 852,
    //     height: 480,
    //     bitrate: 800,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-852x480x800x25",
    //     width: 852,
    //     height: 480,
    //     bitrate: 800,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-852x480x640x30",
    //     width: 852,
    //     height: 480,
    //     bitrate: 640,
    //     fps: 30,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-852x480x640x25",
    //     width: 852,
    //     height: 480,
    //     bitrate: 640,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-640x360x400",
    //     width: 640,
    //     height: 360,
    //     bitrate: 400,
    //     fps: "copy",
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-640x360x400x25",
    //     width: 640,
    //     height: 360,
    //     bitrate: 400,
    //     fps: 25,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-640x360x300x15",
    //     width: 640,
    //     height: 360,
    //     bitrate: 300,
    //     fps: 15,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-480x360x300x15",
    //     width: 480,
    //     height: 360,
    //     bitrate: 300,
    //     fps: 15,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-480x270x250x15",
    //     width: 480,
    //     height: 270,
    //     bitrate: 250,
    //     fps: 15,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-426x240x200x15",
    //     width: 426,
    //     height: 240,
    //     bitrate: 200,
    //     fps: 15,
    //     preset: "ultrafast"
    // },
    // {
    //     identifier: "vtrans-320x240x200x15",
    //     width: 320,
    //     height: 240,
    //     bitrate: 200,
    //     fps: 15,
    //     preset: "ultrafast"
    // },
    {
        identifier: "vtrans2-2000x30",
        width: 0,
        height: 0,
        bitrate: 2000,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-2000x25",
        width: 0,
        height: 0,
        bitrate: 2000,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1600x30",
        width: 0,
        height: 0,
        bitrate: 1600,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1600x25",
        width: 0,
        height: 0,
        bitrate: 1600,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1000k",
        width: 0,
        height: 0,
        bitrate: 1000,
        fps: "copy",
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1000x30",
        width: 0,
        height: 0,
        bitrate: 1000,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1000x25",
        width: 0,
        height: 0,
        bitrate: 1000,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-800k",
        width: 0,
        height: 0,
        bitrate: 800,
        fps: "copy",
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-400k",
        width: 0,
        height: 0,
        bitrate: 400,
        fps: "copy",
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-400k15",
        width: 0,
        height: 0,
        bitrate: 400,
        fps: 15,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x2000x30",
        width: 1280,
        height: 720,
        bitrate: 2000,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x2000x25",
        width: 1280,
        height: 720,
        bitrate: 2000,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x1600x30",
        width: 1280,
        height: 720,
        bitrate: 1600,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x1600x25",
        width: 1280,
        height: 720,
        bitrate: 1600,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x1200x30",
        width: 1280,
        height: 720,
        bitrate: 1200,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x1200x25",
        width: 1280,
        height: 720,
        bitrate: 1200,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x1000x30",
        width: 1280,
        height: 720,
        bitrate: 1000,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-1280x720x1000x25",
        width: 1280,
        height: 720,
        bitrate: 1000,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-960x540x1000",
        width: 960,
        height: 540,
        bitrate: 1000,
        fps: "copy",
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-960x540x640x25",
        width: 960,
        height: 540,
        bitrate: 640,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-900x720x1200x25",
        width: 900,
        height: 720,
        bitrate: 1200,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-900x720x800x25",
        width: 900,
        height: 720,
        bitrate: 800,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-852x480x800x30",
        width: 852,
        height: 480,
        bitrate: 800,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-852x480x800x25",
        width: 852,
        height: 480,
        bitrate: 800,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-852x480x640x30",
        width: 852,
        height: 480,
        bitrate: 640,
        fps: 30,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-852x480x640x25",
        width: 852,
        height: 480,
        bitrate: 640,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-640x360x400",
        width: 640,
        height: 360,
        bitrate: 400,
        fps: "copy",
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-640x360x400x25",
        width: 640,
        height: 360,
        bitrate: 400,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-640x360x300x15",
        width: 640,
        height: 360,
        bitrate: 300,
        fps: 15,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-600x480x600x25",
        width: 600,
        height: 480,
        bitrate: 600,
        fps: 25,
        preset: "veryfast"
    },

    {
        identifier: "vtrans2-480x384x400x25",
        width: 480,
        height: 384,
        bitrate: 400,
        fps: 25,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-480x384x400x15",
        width: 480,
        height: 384,
        bitrate: 400,
        fps: 15,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-480x360x300x15",
        width: 480,
        height: 360,
        bitrate: 300,
        fps: 15,
        preset: "veryfast"
    },
    
    {
        identifier: "vtrans2-480x270x250x15",
        width: 480,
        height: 270,
        bitrate: 250,
        fps: 15,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-426x240x200x15",
        width: 426,
        height: 240,
        bitrate: 200,
        fps: 15,
        preset: "veryfast"
    },
    {
        identifier: "vtrans2-320x240x200x15",
        width: 320,
        height: 240,
        bitrate: 200,
        fps: 15,
        preset: "veryfast"
    }
];
