import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Table, TableContainer, TableRow, TableCell, TableBody, Button, Divider, Icon, IconButton } from '@material-ui/core';
import { OpenInNew, CheckCircle, Cancel, Replay } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';

import StateSection from './State';
import Tag from '../global/Tag';
import CopyButton from '../global/CopyButton';
import RedirectLink from '../global/RedirectLink';
import ContentContainer from '../global/ContentContainer';



const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    headerWrapper: {
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginRight: theme.spacing(2),
        lineHeight: 1,
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('500')]: {
            marginBottom: 0
        },
    },
    value: {
        lineHeight: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    label: {
        [theme.breakpoints.up('500')]: {
            width: 200,
        },
        color: theme.palette.text.secondary,
        lineHeight: 1.2,
    },
    link: {
        lineHeight: 1.2,
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    tagWrapper: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        flexWrap: 'wrap'
    },
    booleanWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.light
    },
    underline: {
        marginBottom: theme.spacing(1.5),
        lineHeight: 1.2
    },
    date: {
        lineHeight: 1
    }
}))

export default function OutlinedTable(props) {
    const classes = useStyles();

    const openInNewTab = (url) => (event) => {
        window.open(url, 'blank')
    }

    const handleOpenInSame = () => {
        props.clicked();
    }

    return (
        <ContentContainer>
            <div className={classes.headerWrapper}>
                <Typography variant="h6" className={classes.title}>
                    {props.label}
                </Typography>
                {
                    props.buttonLabel
                        ?
                        <RedirectLink to={props.to}>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                startIcon={props.buttonIcon}
                            >
                                {props.buttonLabel}
                            </Button>
                        </RedirectLink>
                        : null
                }
                {
                    props.openInSame
                        ?
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={props.buttonIcon}
                            onclick={handleOpenInSame}
                        >
                            {props.openInSame}
                        </Button>
                        : null
                }
                {
                    props.openInNew !== undefined
                        ?
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            endIcon={<OpenInNew />}
                            onClick={openInNewTab(props.to)}
                        >
                            {props.openInNew}
                        </Button>
                        : null
                }
            </div>
            {
                props.underline
                    ?
                    <Typography variant="body1" className={classes.underline} color="textSecondary">
                        {props.underline}
                    </Typography>
                    : null
            }
            <Divider />
            <TableContainer className={classes.table}>
                <Table size="small">
                    {
                        props.list
                            ?
                            <TableBody>
                                {
                                    props.list.filter((item) => { return item !== false }).map((item, id) => (
                                        <TableRow hover key={id}>
                                            <TableCell className={classes.label}>
                                                <Typography variant="button">
                                                    {item.label}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    item.state
                                                        ? <StateSection state={item.state} />
                                                        : null
                                                }
                                                {
                                                    item.tags
                                                        ?
                                                        <div className={classes.tagWrapper}>
                                                            {

                                                                item.tags.map((tag, index) => (
                                                                    <Tag
                                                                        tag={tag}
                                                                        key={index}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.nonCopyValue
                                                        ?
                                                        <Typography variant="subtitle2" className={classes.value}>
                                                            {item.nonCopyValue}
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.booleanValue
                                                        ?
                                                        <div className={classes.booleanWrapper}>
                                                            <Icon>
                                                                {
                                                                    item.booleanValue === "true"
                                                                        ? < CheckCircle />
                                                                        : <Cancel />
                                                                }
                                                            </Icon>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.redirect
                                                        ?
                                                        <div className={classes.value}>
                                                            <Typography variant="subtitle1" className={classes.link}>
                                                                <RedirectLink to={item.redirect}>
                                                                    {`${link.URL}${item.redirect}`}
                                                                </RedirectLink>
                                                            </Typography>
                                                            <CopyButton iconButton={true} copy={item.value} />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.playout
                                                        ?
                                                        <Typography variant="subtitle1" className={classes.value}>
                                                            {
                                                                <Link
                                                                    color="primary"
                                                                    underline="always"
                                                                    target="_blank"
                                                                    href={item.playout}
                                                                // onClick={openInNewTab(item.playout)}
                                                                >
                                                                    {item.playout}
                                                                </Link>
                                                            }
                                                            <CopyButton iconButton={true} copy={item.playout} />
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.value
                                                        ?
                                                        <Typography variant="subtitle1" className={classes.value}>
                                                            {item.value}
                                                            <CopyButton iconButton={true} copy={item.value} />
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.date
                                                        ?
                                                        <Typography color="textSecondary" variant="subtitle2" className={classes.date}>
                                                            {item.date.date ? item.date.date : null}
                                                            {
                                                                item.date.time
                                                                    ? <br />
                                                                    : null
                                                            }
                                                            {item.date.time ? item.date.time : null}
                                                        </Typography>
                                                        : null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                            : null
                    }
                </Table>
            </TableContainer>
        </ContentContainer>
    )
}