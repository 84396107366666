import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { GitHub, Facebook, YouTube, Instagram, LinkedIn, Language, Description, Fingerprint, LaptopChromebook, Headset, Twitter } from '@material-ui/icons';

import content from '../utilities/helper/content';
import version from '../utilities/version';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import PageHeader from '../components/global/PageHeader';
import DividerTitle from '../components/global/DividerTitle';
import ContentContainer from '../components/global/ContentContainer';
import SocialMedia from '../components/utilities/SocialMedia';
import Title from '../components/global/Title';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    quickLink: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    version: {
        marginTop: theme.spacing(2)
    }
}))

export default function About(props) {
    const classes = useStyles();
    document.title = "About nanoStream Cloud"

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname);
    }, [])

    return (
        <div className={classes.root}>
            <Grid container>
                <PageHeader title={content.about.title} underline={content.about.underline} />
                <Title title={content.about.socialMedia.title} />
                <ContentContainer>
                    <div>
                        {
                            [
                                { icon: (<Language fontSize="inherit" />), size: "small", link: link.WEBSITE },
                                { icon: (<Headset fontSize="inherit" />), size: "small", link: link.SUPPORT },
                                { icon: (<LinkedIn fontSize="inherit" />), size: "small", link: link.LINKEDIN },
                                { icon: (<GitHub fontSize="inherit" />), size: "small", link: link.GITHUB },
                                { icon: (<YouTube fontSize="inherit" />), size: "small", link: link.YOUTUBE },
                                { icon: (<Facebook fontSize="inherit" />), size: "small", link: link.FACEBOOK },
                                { icon: (<Twitter fontSize="inherit" />), size: "small", link: link.TWITTER },
                                { icon: (<Instagram fontSize="inherit" />), size: "small", link: link.INSTAGRAM },
                            ].map((socialmedia) => (
                                <SocialMedia
                                    key={socialmedia.title}
                                    title={socialmedia.title}
                                    icon={socialmedia.icon}
                                    size={socialmedia.size}
                                    link={socialmedia.link}
                                />
                            ))
                        }
                    </div>
                </ContentContainer>
                <Title title="Quick Links" />
                <ContentContainer>
                    <div>
                        {
                            [
                                { icon: (<Language fontSize="inherit" />), link: link.WEBSITE, label: "Website" },
                                { icon: (<Headset fontSize="inherit" />), link: link.SUPPORT, label: "Support" },
                                { icon: (<Description fontSize="inherit" />), link: link.DOCS, label: "Documentation" },
                                { icon: (<Fingerprint fontSize="inherit" />), link: link.IMPRINT, label: "Imprint" }
                            ].map((link, index) => (
                                <Button
                                    className={classes.quickLink}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={link.icon}
                                    onClick={() => { window.open(link.link, '_blank') }}
                                >
                                    {link.label}
                                </Button>
                            ))
                        }
                    </div>
                </ContentContainer>
                <Typography className={classes.version} color="textSecondary" variant="button">
                    {version.name} {version.version}
                </Typography>
            </Grid>
        </div>
    )
}