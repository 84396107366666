export default {
    "about": {
        "title": "About",
        "underline": "Follow nanocosmos around the world",
        "socialMedia": {
            "title": "Social Media"
        },
        "quickLinks": {
            "title": "Quick Links"
        }
    },
    "createQuickPlayoutToken": {
        "title": "Create secure playback token",
        "underline": "By clicking on 'Create secure playback token' you will generate a new token for this stream. This specific token only shows once and can not be retrieved or restored once the page is refreshed or closed. Please copy it and keep it properly.",
        "expirationDate": {
            "title": "Choose an expiration date. Default expiration is two weeks"
        },
        "createTokenFor": {
            "title": "Choose if you want to create a token for this specific stream or the organisation hash."
        },
        "specificOptions": {
            "title": "Optional: Add specific options like the IP or the referer"
        },
        "addTags": {
            "title": "Optional: Add a single tag"
        },
        "success": {
            "title": "You created a new secure playback token!",
            "underline": "Open the nanoStream H5Live Code Snippet including your secure playback token and embed it on your web page!"
        },
        "button": "Create secure playback token",
    },
    "createSecurePlayoutToken": {
        "title": "Create secure playback token",
        "underline": "Token generator to enable H5Live secure playback",
        "createTokenFor": {
            "title": "Choose if you want to create a token for a specific stream or your organisation"
        },
        "expirationDate": {
            "title": "Expiration date (default: 2 weeks)"
        },
        "specificOptions": {
            "title": "Optional: Add specific options"
        },
        "addTags": {
            "title": "Optional: Add a tag"
        },
        "button": "Create secure playback token",
        "codeSnippet": {
            "title": "H5Live Config Security Property",
            "underline": "Paste this object into the 'security' object of the stream you would like to playback."
        }
    },
    "createStream": {
        "button": "Create new stream",
        "title": "Create new Stream",
        "underline": "Ultra low-latency passthrough stream.",
        "ingestStream": {
            "title": "Ingest Stream"
        },
        "addTags": {
            "title": "Add search tags to your stream"
        },
        "addVtrans": {
            "title": "Choose your transcoding profiles",
            "startIndex": "Select your playback start stream"
        },
        "webcaster": {
            "videoSettings": "Set up the video settings",
            "bitrates": "Set up the bitrates"
        }
    },
    "createTag": {
        "button": "Create new tag",
        "title": "Create new tag",
        "textfieldLabel": "New Tag",
        "submit": "Create"
    },
    "createWebhook": {
        "button": "Create web hook",
        "title": "Create custom web hook",
        "submit": "Create",
        "reset": "Reset",
        "textfield": {
            "title": "Custom web hook"
        }
    },
    "codeSnippet": {
        "title": "Web Code Snippet",
        "accordion": {
            "nonSecure": "nanoStream H5Live Code Snippet (nanoPlayer)",
            "secure": "nanoStream H5Live Code Snippet including secure token (nanoPlayer)"
        },
        "missingToken": "Please create a secure playback token by clicking on 'Create secure playback token'.",
        "underline": "Embed the nanoStream H5Live Player on your own webpage using the code snippet below.",
        "secureOrgaToken": "The following code snippet contains a standard token for this organisation. It expires in 24 hours. If you want to change the security set up and create a new token, click on 'Create secure playback token'.",
        "secureSpecificToken": "The following code snippet contains a customized token for this specific stream. If you want to change the security set up and create a new token, click on 'Create secure playback token'."
    },
    "dashboard": {
        "liveStreams": {
            "title": "Your 5 latest live streams",
            "noStreams": "There are currently no live streams."
        },
        "streamOverview": {
            "title": "Stream Overview",
            "all": "total",
            "created": "created",
            "live": "live",
            "ended": "ended",
            "deleted": "deleted"
        },
        "freeTrial": "Trial Version"
    },
    "deleteStream": {
        "button": "Delete Stream",
        "title": "Are you sure you want to delete this stream?",
        "underline": "This is an one way operation. Once you delete this stream you can not get it back.",
        "cancel": "Cancel",
        "submit": "Delete"
    },
    "filter": {
        "date": {
            "error": {
                "maxStartDate": "Start date should not be after end date.",
                "minEndDate": "End date should not be before start date."
            }
        },
        "tags": {
            "button": "Search Tags"
        },
        "reset": "Reset Filter"
    },
    "inviteToken": {
        "button": "Create invite token",
        "title": "Invite Token for a new user",
        "list": {
            "noInviteToken": "There are no invite token yet in this organisation.",
            "title": "Invite Token"
        }
    },
    "login": {
        "title": "Welcome back to",
        "underline": {
            "first": "Around the world in 1 second!",
            "second": "Sign in and continue streaming with ultra-low-latency!"
        }
    },
    "menu": {
        "dashboard": "Home",
        "streams": "Streams",
        "createStream": "Create Stream",
        "metrics": "Metrics",
        "organisation": "Organisation",
        "createSecurePlayoutToken": "Secure Playback token",
        "about": "About",
        "support": "Support",
        "logout": "Logout"
    },
    "metrics": {
        "title": "Metrics",
        "underline": "Monitor and analyze the performance and quality of service of your live stream."
    },
    "newStream": {
        "title": {
            "singleStream": "You created a new stream!",
            "transcoding": "You created a new stream with transcoding profiles!",
            "notFound": "Could not find stream."
        },
        "underline": {
            "rtmp": "Copy the ingest url and stream name to your live encoder software or hardware and start the broadcast.",
            "webcaster": "Start the broadcast directly out of the browser.",
            "notFound": "The stream you are looking for does not exist."
        },
        "ingest": {
            "title": "1. Start the Ingest",
        },
        "webcaster": {
            "title": "nanoStream Webcaster"
        },
        "playout": {
            "title": {
                "singleStream": "2. Access the Playout",
                "transcoding": "2. Access the ABR Playout"
            },
            "playoutTable": "Web Playout URL",
            "rtmpTable": "RTMP Playout"
        },
        "overview": {
            "title": "3. Stream Overview",
            "overviewTable": {
                "title": "Created streams",
                "underline": {
                    "transcoding": "These are the single output streams. For ABR playback, use the URL showed above."
                }
            }
        }
    },
    "notFound": {
        "overline": "404 - page not found",
        "title": "The page you are looking for does not exist.",
        "dashboard": {
            "title": "Dashboard",
            "underline": "Go back to the dashboard and get an overview over your organisation."
        },
        "newStream": {
            "title": "Create new stream",
            "underline": "Create a new live stream and stream around the world in 1 second."
        },
        "support": {
            "title": "Get Support",
            "underline": "Fill out the form and get support from our team."
        }
    },
    "organisation": {
        "title": "Organisation",
        "keys": {
            "title": "Keys",
            "accordionTitle": "Private Keys",
            "apiKey": {
                "title": "API Key",
                "underline": "We strongly recommend to keep the API Key of your organisation private and secure."
            },
            "playerKey": {
                "title": "Player Key",
                "underline": "We strongly recommend to keep the Player Key of your organisation private and secure."
            }
        },
        "user": {
            "title": "All Users",
            "noUser": "There are no user in this organisation.",
            "delete": {
                "title": "Are you sure you want to delete this user",
                "description": "You are about to delete this user. Please confirm this action by clicking 'Delete'."
            }
        },
        "tags": {
            "title": "All Tags",
            "noTags": "There are no tags yet. Click on 'Create new tag' to create the first."
        },
        "webhook": {
            "title": "Custom web hook",
            "noWebhook": "There is no web hook setted up in your organisation."
        },
        "info": {
            "name": "Organisation Name",
            "hash": "Organisation Hash"
        }
    },
    "playout": {
        "title": "nanoStream H5Live Player",
        "underline": {
            "foreignOrga": "This is a playout that does not belong to your organisation."
        },
        "player": {
            "title": {
                "singleStream": "H5Live Playout (Ultra-low latency)",
                "transcoding": "H5Live ABR Playout (Ultra-low latency)",
                "docs": "Nanoplayer documentation"
            },
            "expirationInfo": {
                "title": "This is a secured stream",
                "underline": "The token for the playback is valid until:"
            },
            "notFound": {
                "notFound": "Could not find stream.",
                "notLive": "The stream you are looking for is currently not live."
            }
        },
        "abr": {
            "controls": {
                "title": "Adaptive Bitrate Controls" 
            }
        },
        "streamInfo": {
            "title": "Full stream Info",
            "button": "Full stream info"
        },
        "notFound": {
            "title": "Could not find playout.",
            "underline": "The playout you are looking for does not exist.",
        },
    },
    "setPassword": {
        "title": "Set new password for",
        "underline": {
            "first": "Choose a new password for your nanoStream Cloud account."
        }
    },
    "signUp": {
        "title": "Welcome to",
        "underline": {
            "first": "Ready to use nanoStream Cloud?",
            "second": "Sign up and start streaming now with ultra-low -latency!"
        }
    },
    "support": {
        "overline": "Support",
        "title": "What are you looking for?",
        "docs": {
            "title": "Documentation",
            "underline": "Have a look at our documentation and find answers for your questions!"
        },
        "support": {
            "title": "Get Support",
            "underline": "Fill out the form and get support from our team."
        }
    },
    "startWebcaster": {
        "button": "Open nanoStream Webcaster"
    },
    "streams": {
        "title": "Streams"
    },
    "stream": {
        "title": "Stream Overview",
        "info": {
            "streamInfoTable": "Stream Info",
            "ingestTable": "Ingest"
        },
        "security": {
            "title": "Security",
            "table": {
                "title": "Playback Token Overview",
            }
        },
        "playouts": {
            "title": "Playouts",
            "h5live": {
                "title": "nanoPlayer (H5Live Ultra-low latency)",
                "underline": {
                    "secureOrgaToken": "The following URL contains a standard token for this organisation. It expires in 24 hours. If you want to change the security set up and create a new token, click on 'Create secure playback token'.",
                    "secureSpecificToken": "The following URL contains a custom token for this stream. If you want to change the security set up you can create a new token, click on 'Create secure playback token'.",
                    "transcodedSingleStream": "This stream is part of an adaptive bitrate / transcoding group. The URL below is only for the single stream. You should have seen a multibitrate playback URL in the overview after creating the streams."
                },
            },
            "rtmp": {
                "title": "RTMP Playout"
            },
            "vod": {
                "title": "VOD Playout URLs"
            },
            "hls": {
                "title": "HLS Playout",
                "underline": "To see the H5Live Playout see the H5Live section above."
            },
            "thumbnail": {
                "title": "VOD Thumbnail",
                "underline": "The thumbnail of your stream."
            }
        },
    },
    "updateInterval": {
        "button": "Update Timecode",
        "title": "Update the timecode of your stream",
        "submit": "Update"
    },
    "updateTags": {
        "button": "Update Tags",
        "title": "Update the tags of your stream",
        "submit": "Update"
    },
    "updateOpcode": {
        "button": "Update Live Processing",
        "title": "Update the live processing of your stream",
        "submit": "Update"
    },
    "tags": {
        "label": "Tags",
        "enter": "Press ENTER to add tag"
    },
    "vod": {
        "header": "VOD Settings",
        "button": "Customize",
        "title": "Hide VOD for public requests",
        "underline": "When true, the VODs of this organisation wont be accessible using the public request URL.",
        "cancel": "Cancel",
        "submit": "Submit"
    },
};