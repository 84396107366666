import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

import * as serviceWorker from './serviceWorker';

import Layout from './components/layout/Layout';
import Router from './components/layout/Router';


document.body.style.margin = 0;
document.body.style.padding = 0;
document.body.style.backgroundColor = "#fff";

const generateClassName = createGenerateClassName({
    productionPrefix: 'nanoStream-cloud',
    seed: 'nanoStream-cloud'
});

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: '',
            main: '#ed7d0e',
            // dark: '#706f6f',
            // contrastText: ''
        },
        secondary: {
            // light: '#8f7666',
            main: '#3e2516',
            // dark: '',
            // contrastText: ''
        }
    },
    typography: {
        fontFamily: [
            'Source Sans Pro',
            "sans-serif"
        ],
        fontSize: 13,
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: 600,
            },
            containedPrimary: {
                backgroundColor: '#ed7d0e',
                color: '#ffffff',
                boxShadow: 'none',
                borderRadius: '20px',
                '&:hover': {
                    boxShadow: 'none',
                }
            },
            containedSecondary: {
                backgroundColor: '#ed7d0e33',
                color: '#ed7d0e',
                boxShadow: 'none',
                borderRadius: '20px',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#ed7d0e66',
                    color: '#ed7d0e',
                }
            },
            outlinedPrimary: {
                borderColor: '#ed7d0e',
                borderRadius: '20px',
                color: '#ed7d0e',
            },
            outlinedSecondary: {
                borderColor: '#3e2516',
                borderRadius: '20px',
                color: '#3e2516',
                '&:hover': {
                    borderColor: '#3e251690'
                }
            },
        }
    }
})
console.log(theme)

const app = (
    <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Layout>
                    <Router />
                </Layout>
            </BrowserRouter>
        </MuiThemeProvider>
    </StylesProvider>
);

ReactDOM.render(app, document.getElementById('cloud-frontend')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
