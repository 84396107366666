import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import content from '../../utilities/helper/content';
import * as link from '../../utilities/helper/link-config';
import * as method from '../../utilities/helper/methods';

import PageHeader from '../../components/global/PageHeader';
import Player from './Player';
import Title from '../global/Title';
import SwitchStream from './SwitchStream';
import StreamInfoSection from './StreamInfoSection';
import TranscodedStreamInfoSection from './TranscodedStreamInfoSection';
import H5LiveCodeSnippet from './H5LiveCodeSnippet';
import TokenExpirationInfo from '../player/TokenExpirationInfo';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    buttonWrapper: {
        marginTop: theme.spacing(2)
    }
}))

export default function AuthedPlayer(props) {
    const classes = useStyles();
    let streams = props.streams;
    const [secureToken, setSecureToken] = useState(props.secureToken);
    let transcodingOptions = props.transcodingOptions;
    let startIndex = props.startIndex;

    const openInNewTab = (link) => (event) => {
        window.open(link, '_blank')
    }

    const createProfileSearchParam = (count, profile) => {
        return `${count === 1 ? "?" : "&"}${profile.hasOwnProperty("id") ? `vtrans${count}.id=${profile.id}` : ""}${profile.hasOwnProperty("bitrate") ? `&vtrans${count}.bitrate=${profile.bitrate}` : ""}${profile.hasOwnProperty("fps") ? `&vtrans${count}.fps=${profile.fps}` : ""}${profile.hasOwnProperty("width") ? `&vtrans${count}.width=${profile.bitrate}${profile.hasOwnProperty("bitrate") ? `&vtrans${count}.height=${profile.height}` : ""}` : ""}`;
    }

    const openStreamOverview = () => {
        if (streams.length === 1) {
            console.log(props.streams)
            window.open(`${link.STREAM}/${streams[0].id}`, '_blank');
            return;
        }

        if (streams.length > 1) {
            let passthroughId = `${streams[0].id}`;
            let transcodingSuffix = "";

            if (Object.keys(transcodingOptions).length > 0) {
                if (transcodingOptions.hasOwnProperty("vtrans1")) {
                    transcodingSuffix = `${transcodingSuffix}${createProfileSearchParam(1, transcodingOptions.vtrans1)}`
                }
                if (transcodingOptions.hasOwnProperty("vtrans2")) {
                    transcodingSuffix = `${transcodingSuffix}${createProfileSearchParam(2, transcodingOptions.vtrans2)}`
                }
                if (transcodingOptions.hasOwnProperty("vtrans3")) {
                    transcodingSuffix = `${transcodingSuffix}${createProfileSearchParam(3, transcodingOptions.vtrans3)}`
                }
            }
            window.open(`${link.NEW_STREAM}/${passthroughId}${transcodingSuffix}`, '_blank')
        }
    }

    useEffect(() => {
        setSecureToken(props.secureToken);
    }, [props.secureToken])

    return (
        <div className={classes.root}>
            <PageHeader title={content.playout.title} />
            <Collapse in={streams.length > 0}>
                <Title
                    title={`${streams.length > 1 ? content.playout.player.title.transcoding : content.playout.player.title.singleStream}`}
                    button={content.playout.player.title.docs}
                    endIcon={<OpenInNew />}
                    clicked={openInNewTab(link.H5LIVE_DOCS)}
                />
                <Player streams={streams} secureToken={secureToken} />
                <Collapse in={!(props.expirationDate.length === 0)}>
                    <TokenExpirationInfo expiration={props.expirationDate} />
                </Collapse>
                {
                    streams.length > 1
                        ?
                        <div>
                            <Title
                                title={content.playout.abr.controls.title}
                            />
                            <SwitchStream
                                startIndex={startIndex}
                                streams={streams}
                                transcodingOptions={transcodingOptions}
                            />
                        </div>
                        : null
                }
                <Title
                    title={content.playout.streamInfo.title}
                    button={content.playout.streamInfo.button}
                    endIcon={<OpenInNew />}
                    clicked={openStreamOverview}
                />
                {
                    streams.length === 1
                        ?
                        <div>
                            {
                                streams.map((stream, index) => (
                                    <StreamInfoSection key={index} stream={stream} />
                                ))
                            }
                        </div>
                        : null
                }
                {
                    streams.length > 1
                        ? <TranscodedStreamInfoSection
                            shareOption
                            streams={streams}
                            transcodingOptions={transcodingOptions}
                        />
                        : null
                }
                <Collapse in={streams}>
                    <H5LiveCodeSnippet
                        withTitle
                        streams={streams}
                        security={secureToken}
                    />
                </Collapse>
            </Collapse>
        </div>
    )
}