import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';


import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as link from '../utilities/helper/link-config';
import * as method from '../utilities/helper/methods';

import ExternalPlayer from '../components/player/ExternalPlayer';
import AuthedPlayer from '../components/player/AuthedPlayer';
import CouldNotFind from '../components/global/CouldNotFind';
import ForeignOrgaPlayer from '../components/player/ForeignOrgaPlayer';
import SpinningLoadingLogo from '../components/utilities/SpinningLoadingLogo';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        root: {
            display: 'flex',
        },
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...theme.mixins.toolbar
    },
    drawerToggleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    logo: {
        [theme.breakpoints.up('xs')]: {
            "&:hover": {
                cursor: 'pointer'
            },
            height: '30px',
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: '100%',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('md')]: {
        }
    },
}))

export default function Playout(props) {
    const classes = useStyles();
    document.title = "nanoStream H5Live Player";
    let isExternal = props.isExternal
    const [streams, setStreams] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [foreign, setForeign] = useState(false);
    const [loading, setLoading] = useState(true);
    const [transcodingOptions, setTranscodingOptions] = useState({ vtrans1: {}, vtrans2: {}, vtrans3: {} });
    const [secureToken, setSecureToken] = useState([]);
    const [expirationDate, setExpirationDate] = useState("");

    const getStream = (streamid) => {
        let apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
        return new Promise((resolve, reject) => {
            bintu.getStream(apikey, streamid,
                (success) => {
                    resolve(success);

                }, (error) => {
                    reject(error);
                });
        });
    }

    const getExternalStream = (streamid) => {
        return new Promise((resolve, reject) => {
            bintu.publicGetStream(streamid,
                (success) => {
                    resolve(success);

                }, (error) => {
                    reject(error);
                });
        });
    }


    async function getStreamInformations(streamlist) {
        setLoading(true);
        let fetchedStreamlist = [];
        let tryForeignStream = false;
        if (!isExternal) {
            for (let i = 0; i < streamlist.length; i++) {
                await getStream(streamlist[i])
                    .then((success) => {
                        setNotFound(false);
                        fetchedStreamlist.push(success);
                    }).catch((error) => {
                        tryForeignStream = true;
                        return;
                    });
            }
            setStreams(fetchedStreamlist);
        }
        if (isExternal || tryForeignStream) {
            for (let i = 0; i < streamlist.length; i++) {
                await getExternalStream(streamlist[i])
                    .then((success) => {
                        setNotFound(false);
                        if (tryForeignStream) setForeign(true);
                        fetchedStreamlist.push(success);
                    }).catch((error) => {
                        setNotFound(true);
                    });
            }
            setStreams(fetchedStreamlist);
        }
        setLoading(false);
    }

    const createProfileSearchParam = (count, profile) => {
        return `${profile.hasOwnProperty("bitrate") ? `&vtrans${count}.bitrate=${profile.bitrate}` : ""}${profile.hasOwnProperty("fps") ? `&vtrans${count}.fps=${profile.fps}` : ""}${profile.hasOwnProperty("width") ? `&vtrans${count}.width=${profile.bitrate}${profile.hasOwnProperty("bitrate") ? `&vtrans${count}.height=${profile.height}` : ""}` : ""}`;
    }

    const getStreamlist = () => {

        let passthrough = props.match.params
        let searchParams = method.GET_PARAMS(props.location.search)
        let vtransOptionsParams = {
            vtrans1: "",
            vtrans2: "",
            vtrans3: ""
        };
        let options = {};
        let secureTokenList = [];

        let streamlist = Object.values(passthrough);
        if (searchParams.hasOwnProperty("security")) {
            secureTokenList.push(searchParams.security);
            setExpirationDate(moment.unix(searchParams.security.expires).format("MM/DD/YYYY hh:mm"));
        }

        if (Object.keys(searchParams).length > 1) {
            if (searchParams.hasOwnProperty("vtrans1")) {
                if (searchParams.vtrans1.hasOwnProperty("id")) {
                    streamlist.push(searchParams.vtrans1.id)
                }
                if (searchParams.hasOwnProperty("security")) {
                    secureTokenList.push(searchParams.vtrans1.security);
                }
                options.vtrans1 = searchParams.vtrans1
                vtransOptionsParams.vtrans1 = createProfileSearchParam(1, searchParams.vtrans1)
            }
            if (searchParams.hasOwnProperty("vtrans2")) {
                if (searchParams.vtrans2.hasOwnProperty("id")) {
                    streamlist.push(searchParams.vtrans2.id)
                }
                if (searchParams.hasOwnProperty("security")) {
                    secureTokenList.push(searchParams.vtrans2.security);
                }
                options.vtrans2 = searchParams.vtrans2
                vtransOptionsParams.vtrans2 = createProfileSearchParam(2, searchParams.vtrans2)
            }
            if (searchParams.hasOwnProperty("vtrans3")) {
                if (searchParams.vtrans3.hasOwnProperty("id")) {
                    streamlist.push(searchParams.vtrans3.id)
                }
                if (searchParams.hasOwnProperty("security")) {
                    secureTokenList.push(searchParams.vtrans3.security);
                }
                options.vtrans3 = searchParams.vtrans3
                vtransOptionsParams.vtrans3 = createProfileSearchParam(3, searchParams.vtrans3)
            }
            if (searchParams.hasOwnProperty("startIndex")) setStartIndex(searchParams.startIndex)
        }
        setSecureToken(secureTokenList);
        setTranscodingOptions(options);

        if (streamlist.length === 0) {
            // no streams
        }
        if (streamlist.length === 1) {
            getStreamInformations(streamlist);
        }
        if (streamlist.length > 1) {
            getStreamInformations(streamlist);
        }
    }

    useEffect(() => {
        method.SEND_GA_ANALYTICS("/playout");
        getStreamlist();
    }, [])


    return (
        <div className={classes.root}>
            <Grid container>
                {
                    loading && !notFound
                        ? <SpinningLoadingLogo />
                        : null
                }
                <Grid item xs={12}>
                    {
                        isExternal && !loading && !notFound
                            ? <ExternalPlayer
                                streams={streams}
                                transcodingOptions={transcodingOptions}
                                secureToken={secureToken}
                                startIndex={startIndex}
                                expirationDate={expirationDate}
                            />
                            : null
                    }
                    {
                        !isExternal && !loading && !notFound && !foreign
                            ? <AuthedPlayer
                                streams={streams}
                                transcodingOptions={transcodingOptions}
                                secureToken={secureToken}
                                startIndex={startIndex}
                                expirationDate={expirationDate}
                            />
                            : null
                    }
                    {
                        !isExternal && !loading && foreign && !notFound
                            ? <ForeignOrgaPlayer
                                streams={streams}
                                transcodingOptions={transcodingOptions}
                                secureToken={secureToken}
                                startIndex={startIndex}
                                expirationDate={expirationDate}
                            />
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        notFound && !isExternal
                            ?
                            <CouldNotFind
                                title={content.playout.notFound.title}
                                underline={content.playout.notFound.underline}
                                to={link.PLAYOUT}
                                redirectLabel="Back to overview."
                            />
                            : null
                    }
                </Grid>
            </Grid>
        </div>
    )
}