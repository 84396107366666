import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import * as webcaster from '../../utilities/helper/webcaster-helper';
import content from '../../utilities/helper/content';

import DividerTitle from '../global/DividerTitle';
import Dropdown from './Dropdown';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    dropdownRow: {
        display: 'flex',
        flexDirection: 'row'
    },
}))

export default function SetupWebcaster(props) {
    const classes = useStyles();
    const [values, setValues] = useState({
        framerate: {
            identifier: 'framerate',
            title: 'Framerate',
            value: webcaster.FRAMERATE.value,
            values: webcaster.FRAMERATE.values
        },
        resolution: {
            identifier: 'resolution',
            title: 'Resolution',
            value: `${webcaster.RESOLUTION.width}x${webcaster.RESOLUTION.height}`,
            values: webcaster.RESOLUTION.values
        },
        videobitrate: {
            identifier: 'videobitrate',
            title: 'Video Bitrate',
            value: webcaster.VIDEOBITRATE.value,
            values: webcaster.VIDEOBITRATE.values
        },
        audiobitrate: {
            identifier: 'audiobitrate',
            title: 'Audio Bitrate',
            value: webcaster.AUDIOBITRATE.value,
            values: webcaster.AUDIOBITRATE.values
        },
    });

    const updateDropdownValue = (value, identifier) => {
        setValues({
            ...values,
            [identifier]: {
                ...values[identifier],
                value: value
            }
        });
    }

    useEffect(() => {
        let newSetup = {
            framerate: values.framerate.value,
            resolution: values.resolution.value,
            videobitrate: values.videobitrate.value,
            audiobitrate: values.audiobitrate.value,
        }
        props.updateSetup(newSetup);
    }, [values])

    return (
        <Grid className={classes.root} item xs={12}>
            <ContentContainer>
                <DividerTitle title={content.createStream.webcaster.videoSettings} />
                <div className={classes.dropdownRow}>
                    {
                        [
                            values.framerate,
                            values.resolution
                        ].map((item, index) => (
                            <Dropdown
                                key={`${item.title}-${index}`}
                                value={item}
                                onChange={updateDropdownValue}
                            />
                        ))
                    }
                </div>
            </ContentContainer>
            <ContentContainer>
                <DividerTitle title={content.createStream.webcaster.bitrates} />
                <div className={classes.dropdownRow}>
                    {
                        [
                            values.audiobitrate,
                            values.videobitrate
                        ].map((item, index) => (
                            <Dropdown
                                key={`${item.title}-${index}`}
                                value={item}
                                onChange={updateDropdownValue}
                            />
                        ))
                    }
                </div>
            </ContentContainer>
        </Grid>
    )
}