import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Collapse, Button, Box, fade } from '@material-ui/core';
import { GitHub, Facebook, YouTube, Instagram, LinkedIn, Language, Twitter, OpenInNew, ContactSupport, Headset, ArrowRightAlt } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import clouds from '../assets/clouds-background.jpg';
import atw from '../assets/white-atw.png';
import world from '../assets/world-background.png';
import logo from '../assets/orange-logo.png';
import nsc from '../assets/white_logo_nsc.png';
import cheetha from '../assets/white_cheetha.png';

import SignIn from '../components/registration/SingIn';
import SignUp from '../components/registration/SignUp';
import SocialMedia from '../components/utilities/SocialMedia';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        backgroundImage: `url(${world})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
    },
    logoWrapper: {
        height: '64px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        [theme.breakpoints.up('xs')]: {
            "&:hover": {
                cursor: 'pointer'
            },
            height: '30px',
            padding: theme.spacing(0.5),
            marginLeft: theme.spacing(3)

        }
    },
    contentWrapper: {
        margin: theme.spacing(2, 4, 1, 4)
    },
    titleWrapper: {
        margin: theme.spacing(0, 1)
    },
    header: {
        [theme.breakpoints.up('xs')]: {
            color: theme.palette.secondary.main,
            fontWeight: 300
        }
    },
    underline: {
        marginTop: theme.spacing(2),
        color: theme.palette.primary.light,
        lineHeight: 1,
        fontWeight: 600
    },
    orangeText: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    registrationWrapper: {
        marginTop: theme.spacing(4)
    },
    atwWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    nsc: {
        [theme.breakpoints.up('xs')]: {
            height: '100px',
            display: 'block',
            margin: '0 auto',
            marginBottom: theme.spacing(2)
        }
    },
    cheetha: {
        height: '200px',
        display: 'block',
        margin: '0 auto'
    },
    slogan: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontFamily: 'Source Code Pro',
        textAlign: 'center'
    },
    item: {
        minHeight: '100vh',
        '&:nth-of-type(1)': {
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        '&:nth-of-type(2)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        }
    },
    footerSection: {
        marginBottom: theme.spacing(4),
        textAlign: 'center'
    },
    box: {
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1.5),
        backgroundColor: fade(theme.palette.primary.main, 0.2)
    },
}));

export default function Auth() {
    const classes = useStyles();
    const { state } = useContext(Context);
    const [redirectRoute, setRedirectRoute] = useState(null);
    const [login, setLogin] = useState(true);

    document.title = "nanoStream Cloud";

    const changeAuthState = () => {
        let pushUrl = !login ? "login" : "signup"
        window.history.pushState({}, "", `auth?${pushUrl}`);
        setLogin(!login)
    }

    const redirect = (link) => {
        setRedirectRoute(<Redirect push to={link} />);
    }

    const handleOpenDashboard = () => {
        window.open(link.NEW_DASHBOARD, '_self');
    }

    useEffect(() => {
        if (state.access) {
            redirect(link.DASHBOARD);
        }
    }, [state.access]);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        setLogin(!urlParams.has("signup"));
    }, []);

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(`${window.location.pathname}?${login ? "login" : "signup"}`);
    }, [])

    return (
        <div className={classes.root}>
            {redirectRoute}
            <Grid container>
                <Grid item xs className={classes.item}>
                    <div>
                        <div className={classes.logoWrapper}>
                            <img alt="nanocosmos-logo" src={logo} className={classes.logo} />
                        </div>
                        <div className={classes.contentWrapper}>
                            <div className={classes.titleWrapper}>
                                <Typography variant="h4" className={classes.header}>
                                    {login ? content.login.title : content.signUp.title} <span className={classes.orangeText}>nanoStream Cloud</span>!
                                </Typography>
                                <Typography variant="h6" className={classes.underline}>
                                    {
                                        login
                                            ? content.login.underline.first
                                            : content.signUp.underline.first
                                    } <br />
                                    {
                                        login
                                            ? content.login.underline.second
                                            : content.signUp.underline.second
                                    }
                                </Typography>
                            </div>
                            <div className={classes.registrationWrapper}>
                                <Collapse in={login}>
                                    <SignIn signup={changeAuthState} />
                                </Collapse>
                                <Collapse in={!login}>
                                    <SignUp signin={changeAuthState} />
                                </Collapse>
                            </div>
                            <Box className={classes.box}>
                                <Typography variant="h6" color="primary">
                                    <b>Upgrade to nanoStream Cloud Dashboard v4!</b>
                                </Typography>
                                <Typography variant="body1">
                                    <b>As of March 1, 2024, Dashboard V2 will no longer be supported.</b> <br />
                                    Switch to Dashboard v4 now and benefit from an enhanced, user-friendly interface and additional features!
                                </Typography>
                                <br />
                                <Button variant="outlined" color="primary" endIcon={<ArrowRightAlt />} component="a" href={link.NEW_DASHBOARD}>
                                    Switch to v4
                                </Button>
                            </Box>
                        </div>
                    </div>
                    <div className={classes.footerSection}>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<OpenInNew />}
                            onClick={handleOpenDashboard}
                        >
                            Switch to dashboard v4
                        </Button>
                        <Typography variant="subtitle1" color="textSecondary">
                            &copy; {new Date().getFullYear()} All right reserved. nanocosmos GmbH
                        </Typography>
                        <div>
                            {
                                [
                                    { icon: (<Language fontSize="inherit" />), size: "small", link: link.WEBSITE },
                                    { icon: (<Headset fontSize="inherit" />), size: "small", link: link.SUPPORT },
                                    { icon: (<LinkedIn fontSize="inherit" />), size: "small", link: link.LINKEDIN },
                                    { icon: (<GitHub fontSize="inherit" />), size: "small", link: link.GITHUB },
                                    { icon: (<YouTube fontSize="inherit" />), size: "small", link: link.YOUTUBE },
                                    { icon: (<Facebook fontSize="inherit" />), size: "small", link: link.FACEBOOK },
                                    { icon: (<Twitter fontSize="inherit" />), size: "small", link: link.TWITTER },
                                    { icon: (<Instagram fontSize="inherit" />), size: "small", link: link.INSTAGRAM },
                                ].map((socialmedia, index) => (
                                    <SocialMedia
                                        key={index}
                                        icon={socialmedia.icon}
                                        size={socialmedia.size}
                                        link={socialmedia.link}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={7} className={classes.item}>
                    <div className={classes.atwWrapper}>
                        <div>
                            <img alt="around-the-world-in-one-second-logo" src={nsc} className={classes.nsc} />
                        </div>
                        <Typography className={classes.slogan} variant="h2" gutterBottom>
                            Interactive<br />
                            streaming<br />
                            with the edge
                        </Typography>
                        <div>
                            <img src={cheetha} className={classes.cheetha} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
