import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import Context from '../../utilities/context/Context';
import * as link from '../../utilities/helper/link-config';
import * as type from '../../utilities/context/type';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import NotFound from '../../pages/404';
import Auth from '../../pages/Auth';
import CreatePlaybackToken from '../../pages/CreatePlaybackToken';
import CreateStream from '../../pages/CreateStream';
import Dashboard from '../../pages/Dashboard';
import NewStream from '../../pages/NewStream';
import Stream from '../../pages/Stream';
import Streams from '../../pages/Streams';
import Organisation from '../../pages/Organisation';
import Logout from '../registration/Logout';
import About from '../../pages/About';
import Metrics from '../../pages/Metrics';
import Support from '../../pages/Support';
import InviteToken from '../../pages/InviteToken';
import Webcaster from '../../pages/Webcaster';
import Playout from '../../pages/Playout';
import SetPassword from '../../pages/SetPassword';
import StreamOverviewlol from '../../pages/StreamOverview';

export default function Router(props) {
    const { state, dispatch } = useContext(Context);

    const PrivateRoute = ({ component, ...options }) => {
        let access = state.access && (localStorage.getItem(localStorageHelper.AUTHORIZED) !== null || localStorage.getItem(localStorageHelper.AUTHORIZED) !== false);
        const accessComponent = access ? component : Auth
        if (!access && state.access !== false) {
            dispatch({ type: type.ACCESS, access: false });
        }
        return <Route {...options} component={accessComponent} />;
    }

    const ExternalPlayoutRoute = ({ ...options }) => {
        let access = state.access && (localStorage.getItem(localStorageHelper.AUTHORIZED) !== null || localStorage.getItem(localStorageHelper.AUTHORIZED) !== false);
        return <Route {...options} render={(props) => <Playout {...props} isExternal={!access} />} />;
    }

    return (
        <Switch>
            <Route path={link.AUTH} component={Auth} />
            <Route path={`${link.INVITE_TOKEN}/:token`} component={InviteToken} />
            <Route path={`${link.SET_PASSWORD}/:token`} component={SetPassword} />
            <ExternalPlayoutRoute path={`${link.PLAYOUT}/:id`} />
            <PrivateRoute exact path={link.DASHBOARD} component={Dashboard} />
            <PrivateRoute exact path={link.STREAMS} component={Streams} />
            <PrivateRoute exact path={link.CREATE_STREAM} component={CreateStream} />
            <PrivateRoute exact path={'/lol/:id'} component={StreamOverviewlol} />
            <PrivateRoute exact path={`${link.NEW_STREAM}/:id`} component={NewStream} />
            <PrivateRoute exact path={`${link.STREAM}/:id`} component={Stream} />
            <PrivateRoute path={link.PLAYBACK_TOKEN_HELPER} component={CreatePlaybackToken} />
            <PrivateRoute path={link.METRICS} component={Metrics} />
            <PrivateRoute path={link.USER} component={Organisation} />
            <PrivateRoute path={link.ABOUT} component={About} />
            <PrivateRoute path={link.SUPPORT} component={Support} />
            <PrivateRoute exact path={`${link.WEBCASTER}/:id`} component={Webcaster} />
            {/* <PrivateRoute exact path={`${link.PLAYOUT}`} component={H5LivePlayer} />
            <PrivateRoute exact path={`${link.PLAYOUT}/:id?`} component={H5LivePlayer} /> */}
            <Route path={link.LOGOUT} component={Logout} />
            <PrivateRoute component={NotFound} />
        </Switch>
    )
}