import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import { Lock, Share } from '@material-ui/icons';

import Context from '../../utilities/context/Context';
import content from '../../utilities/helper/content';
import * as link from '../../utilities/helper/link-config';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as type from '../../utilities/context/type';
import * as method from '../../utilities/helper/methods';

import Title from '../global/Title';
import OutlinedTable from './Table';
import PlayoutTable from './PlayoutTable';
import H5LiveCodeSnippet from '../player/H5LiveCodeSnippet';
import QuickTokenCreator from '../token/QuickTokenCreator';
import TokenOverview from '../token/TokenOverview';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    accordion: {
        marginTop: theme.spacing(1)
    },
    table: {
        maxWidth: '100%'
    },
    item: {
        marginTop: theme.spacing(2)
    }
}))


export default function CreatedStream(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
    const orgaToken = orga.secure ? method.GET_PLAYER_HASH(orga.hash, apikey) : false;
    const [loading, setLoading] = useState(true);
    const [streams, setStreams] = useState(props.streams);
    const [playoutUrl, setPlayoutUrl] = useState([]);
    const [codeSnippet, setCodeSnippet] = useState([]);
    const [streamOverview, setStreamOverview] = useState(undefined);
    const [openTokenCreator, setOpenTokenCreator] = useState(false);
    const [isOrgaToken, setIsOrgaToken] = useState(true);
    const [tokenSetup, setTokenSetup] = useState(false);
    const [tokenInformations, setTokenInformations] = useState({
        referer: "-",
        ip: "-"
    });


    const createPlayoutUrl = (streamlist, secureList) => {

        let security = secureList !== undefined && !(Array.isArray(secureList)) ? secureList : orgaToken;
        if (Array.isArray(secureList)) security = secureList[0]

        let passthrough = `${streamlist[0] !== undefined ? streamlist[0].data.id : ""}${security ? `?security.expires=${security.expires}&security.options=${security.options}&security.tag=${security.tag}&security.token=${security.token}` : ""}`;
        let vtrans = `${streamlist[1] !== undefined ? `${security ? "&" : "?"}vtrans1.id=${streamlist[1].data.id}${createProfileSearchParam(1, props.transcodingInformations.vtrans1)}${security ? `&vtrans1.security.expires=${security.expires}&vtrans1.security.options=${security.options}&vtrans1.security.tag=${security.tag}&vtrans1.security.token=${security.token}` : ""}` : ""}${streamlist[2] !== undefined ? `&vtrans2.id=${streamlist[2].data.id}${createProfileSearchParam(2, props.transcodingInformations.vtrans2)}${security ? `&vtrans2.security.expires=${security.expires}&vtrans2.security.options=${security.options}&vtrans2.security.tag=${security.tag}&vtrans2.security.token=${security.token}` : ""}` : ""}${streamlist[3] !== undefined ? `&vtrans3.id=${streamlist[3].data.id}${createProfileSearchParam(3, props.transcodingInformations.vtrans3)}${security ? `&vtrans3.security.expires=${security.expires}&vtrans3.security.options=${security.options}&vtrans3.security.tag=${security.tag}&vtrans3.security.token=${security.token}` : ""}` : ""}${props.startIndex ? `&startIndex=${props.startIndex}` : ""}`
        if (Array.isArray(secureList) && (secureList.length > 1)) vtrans = `${streamlist[1] !== undefined ? `&vtrans1.id=${streamlist[1].data.id}${createProfileSearchParam(1, props.transcodingInformations.vtrans1)}&vtrans1.security.expires=${secureList[1].expires}&vtrans1.security.options=${secureList[1].options}&vtrans1.security.tag=${secureList[1].tag}&vtrans1.security.token=${secureList[1].token}` : ""}${streamlist[2] !== undefined ? `&vtrans2.id=${streamlist[2].data.id}${createProfileSearchParam(2, props.transcodingInformations.vtrans2)}&vtrans2.security.expires=${secureList[2].expires}&vtrans2.security.options=${secureList[2].options}&vtrans2.security.tag=${secureList[2].tag}&vtrans2.security.token=${secureList[2].token}` : ""}${streamlist[3] !== undefined ? `&vtrans3.id=${streamlist[3].data.id}${createProfileSearchParam(3, props.transcodingInformations.vtrans3)}&vtrans3.security.expires=${secureList[3].expires}&vtrans3.security.options=${secureList[3].options}&vtrans3.security.tag=${secureList[3].tag}&vtrans3.security.token=${secureList[3].token}` : ""}${props.startIndex ? `&startIndex=${props.startIndex}` : ""}`
        let createdPlayoutUrl = `${link.URL}${link.PLAYOUT}/${passthrough}${vtrans}`
        setPlayoutUrl([{ type: "live", url: createdPlayoutUrl }]);
    }

    const createOverviewArray = (streams) => {
        let updatedArray = [];
        streams.forEach((stream, index) => (
            updatedArray.push({ label: `Link to stream Info ${index + 1}`, redirect: `${link.STREAM}/${stream.data.id}` })
        ))
        setStreamOverview(updatedArray);
    }

    const handleClickedOpenTokenCreator = () => {
        setOpenTokenCreator(!openTokenCreator);
    }

    const createSecureToken = (body) => {
        return new Promise((resolve, reject) => {
            bintu.createSecureToken(apikey, body,
                (success) => {
                    resolve(success)
                },
                (error) => {
                    dispatch({ type: type.SHOW_ERROR, error: { title: "An error occured", message: "Could not create secure playback token. Please try again." }, alert: "error" });
                });
        })
    }

    const createProfileSearchParam = (count, profile) => {
        return `${profile.hasOwnProperty("bitrate") ? `&vtrans${count}.bitrate=${profile.bitrate}` : ""}${profile.hasOwnProperty("fps") ? `&vtrans${count}.fps=${profile.fps}` : ""}${profile.hasOwnProperty("width") ? `&vtrans${count}.width=${profile.width}${profile.hasOwnProperty("width") ? `&vtrans${count}.height=${profile.height}` : ""}` : ""}`;
    }

    async function handleCreateSecureToken(setup, isForOrgaHash) {
        let secureTokenlist = [];
        let body = setup;
        setTokenInformations({
            ip: body.ip ? body.ip : "-",
            referer: body.referer ? body.referer : "-"
        });
        if (isForOrgaHash) {
            body.orga = orga.hash
            await createSecureToken(body).then((success) => {
                secureTokenlist = (success.h5live.security);
            });
        }
        if (!isForOrgaHash) {
            for (var i = 0; i < streams.length; i++) {
                body.streamname = streams[i].data.playout.rtmp[0].streamname;
                await createSecureToken(body).then((success) => {
                    secureTokenlist.push(success.h5live.security);
                });
            }
        }
        createPlayoutUrl(streams, secureTokenlist);
        setTokenSetup(secureTokenlist);
        setOpenTokenCreator(false);
    }

    useEffect(() => {
        if (props.enableSecure) {
            setTokenSetup(orgaToken);
        }
    }, [props.enableSecure])

    useEffect(() => {
        if (props.enableSecure) {
            if ((tokenSetup.tag !== null) && (orgaToken.tag !== null)) {
                setIsOrgaToken(tokenSetup.tag === orgaToken.tag);
            }
        }
    }, [tokenSetup])

    useEffect(() => {
        createPlayoutUrl(streams);
        createOverviewArray(streams);
    }, [streams])

    useEffect(() => {
        setStreams(props.streams);
        let updatedStreamlist = props.streams.filter((stream) => { return stream.data }).map((stream) => { return stream.data });
        setCodeSnippet(updatedStreamlist);
    }, [props.streams])

    useEffect(() => {
        if (streams.length > 0) {
            setLoading(false);
        }
    }, [streams])


    return (
        <div className={classes.root}>
            {
                !loading
                    ?
                    <div>
                        <Title title={content.newStream.ingest.title} />
                        <div className={classes.item}>
                            <OutlinedTable
                                label="Ingest"
                                list={[
                                    { label: "RTMP Ingest Streamname", value: streams[0].data.ingest.rtmp.streamname },
                                    { label: "RTMP Ingest URL", value: props.enableTranscoding ? (streams[0].data.ingest.rtmp.url).replace("stream", "vtrans") : streams[0].data.ingest.rtmp.url }
                                ]}
                            />
                        </div>
                        <Title
                            title={`${props.enableTranscoding ? content.newStream.playout.title.transcoding : content.newStream.playout.title.singleStream}`}
                            button={props.enableSecure ? content.createQuickPlayoutToken.button : false}
                            startIcon={<Lock />}
                            clicked={handleClickedOpenTokenCreator}
                        />
                        <Collapse in={openTokenCreator}>
                            <QuickTokenCreator
                                createToken={handleCreateSecureToken}
                            />
                        </Collapse>
                        <div className={classes.item}>
                            {
                                props.enableSecure
                                    ?
                                    <Collapse in={tokenSetup}>
                                        <TokenOverview
                                            informations={tokenInformations}
                                            token={tokenSetup}
                                        />
                                    </Collapse>
                                    : null
                            }
                        </div>
                        <div className={classes.item}>
                            {
                                !(playoutUrl === undefined)
                                    ?
                                    <PlayoutTable
                                        underline={props.enableSecure ? `${isOrgaToken ? content.stream.playouts.h5live.underline.secureOrgaToken : content.stream.playouts.h5live.underline.secureSpecificToken}` : ""}
                                        openInNew="NanoPlayer Documentation"
                                        to={link.H5LIVE_DOCS}
                                        label={content.newStream.playout.playoutTable}
                                        list={playoutUrl}
                                        transcoded={props.enableTranscoding}
                                        startIndex={props.startIndex}
                                    />
                                    : null
                            }
                            {
                                props.enableSecure
                                    ? null
                                    :
                                    <div className={classes.item}>
                                        <OutlinedTable
                                            label={content.newStream.playout.rtmpTable}
                                            list={[
                                                { label: "RTMP Streamname", value: streams[0].data.playout.rtmp[0].streamname },
                                                { label: "RTMP URL", value: streams[0].data.playout.rtmp[0].url }
                                            ]}
                                        />
                                    </div>
                            }
                        </div>
                        {
                            <Collapse in={codeSnippet}>
                                <H5LiveCodeSnippet
                                    security={tokenSetup}
                                    streams={codeSnippet}
                                />
                            </Collapse>
                        }
                        <Title title={content.newStream.overview.title} />
                        <div className={classes.item}>
                            {
                                streamOverview !== undefined
                                    ?
                                    <OutlinedTable
                                        underline={props.enableTranscoding ? content.newStream.overview.overviewTable.underline.transcoding : ""}
                                        label={`Created stream${streamOverview.length > 1 ? "s" : ""}`}
                                        list={streamOverview}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}