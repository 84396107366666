import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import * as method from '../../utilities/helper/methods';

import Table from '../stream-overview/Table';
import content from '../../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    table: {
        marginTop: theme.spacing(2)
    }
}))

export default function TokenOverview(props) {
    const classes = useStyles();
    const [token, setToken] = useState(props.token);
    const [tokenList, setTokenList] = useState(false);

    useEffect(() => {
        setTokenList(Array.isArray(props.token))
        setToken(props.token);
    }, [props.token])

    // console.log(method.RESOLVE_PLAYER_TOKEN_OPTIONS(25))

    return (
        <div className={classes.root}>
            {
                token
                    ?
                    <div>
                        {
                            tokenList
                                ?
                                <div>
                                    <Table
                                        label={content.stream.security.table.title}
                                        list={[
                                            token[0] !== undefined && { label: "Passthrough Token", value: token[0].token },
                                            token[1] !== undefined && { label: "Profile 1 Token", value: token[1].token },
                                            token[2] !== undefined && { label: "Profile 2 Token", value: token[2].token },
                                            token[3] !== undefined && { label: "Profile 3 Token", value: token[3].token },
                                            { label: "IP", nonCopyValue: props.informations.ip },
                                            { label: "Referer", nonCopyValue: props.informations.referer },
                                            { label: "Tag", tags: token[0].tag === "" ? [] : [token[0].tag] },
                                            { label: "Expires", date: { date: moment.unix(token[0].expires).format('MM/DD/YYYY hh:mm a') } },
                                            { label: "Options", nonCopyValue: token[0].options },
                                        ]}
                                    />
                                </div>
                                :
                                <Table
                                    label={content.stream.security.table.title}
                                    list={[
                                        { label: "Token", value: token.token },
                                        { label: "IP", nonCopyValue: props.informations.ip },
                                        { label: "Referer", nonCopyValue: props.informations.referer },
                                        { label: "Tag", tags: token.tag === "" ? [] : [token.tag] },
                                        { label: "Expires", date: { date: moment.unix(token.expires).format('MM/DD/YYYY hh:mm a') } },
                                        { label: "Options", nonCopyValue: token.options },
                                    ]}
                                />
                        }
                    </div>
                    : null
            }
        </div>
    )
}