import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

import ModalWrapper from '../modal/ModalWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        borderRadius: theme.spacing(0.5),
        boxShadow: theme.shadows[5],
        maxWidth: '80vw'
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        marginRight: theme.spacing(1)
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),
    },
    bold: {
        fontWeight: 700
    },
    message: {
        lineHeight: 1.2
    },
    content: {
        padding: theme.spacing(2),
        maxHeight: '50vh',
        overflow: 'scroll'
    },
    buttonWrapper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    button: {
        '&:nth-of-type(1)': {
            marginRight: theme.spacing(1)
        }
    }
}));

export default function ResetPassword(props) {
    const classes = useStyles();

    return (
        <ModalWrapper
            title={
                <div className={classes.titleWrapper}>
                    <Typography variant="h5" className={classes.title}>
                        Reset password
                    </Typography>
                </div>
            }
            open={props.open}
            onClose={props.close}>
            <div className={classes.content}>
                <Typography variant="body1" className={classes.message}>
                    You want to reset the password of <span className={classes.bold}>{props.user.email}</span>? <br />
                    Please confirm this action by clicking on "Reset".
                </Typography>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={props.close}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.button}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={props.resetUser}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    );
}
