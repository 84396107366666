import * as type from './type';
import * as localStorageHelper from '../helper/localStorage-vars';

export const initialState = {
    access: localStorage.getItem(localStorageHelper.AUTHORIZED) || false,
    apikey: localStorage.getItem(localStorageHelper.BINTU_APIKEY) || "",
    email: localStorage.getItem(localStorageHelper.BINTU_MAIL) || "",
    orga: localStorage.getItem(localStorageHelper.BINTU_ORGA) || {},
    bintuApiUrl: "https://bintu.nanocosmos.de",
    bintu: {},
    showSnackbar: false,
    error: {
        title: "This is a message",
        message: "Error Error",
        alert: "error"
    }
}

export const reducer = (state, action) => {
    switch (action.type) {
        case type.ACCESS: {
            return {
                ...state,
                access: action.access,
                apikey: action.apiKey
            }
        }
        case type.SHOW_ERROR: {
            return {
                ...state,
                showSnackbar: true,
                error: {
                    title: action.error.title,
                    message: action.error.message,
                    alert: action.alert
                }
            }
        }
        case type.HIDE_ERROR: {
            return {
                ...state,
                showSnackbar: false,
            }
        }
        case type.SET_BINTU: {
            return {
                ...state,
                bintu: action.bintu
            }
        }
        default: return state;
    }
}