import React, { useEffect, useState } from 'react';

import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, RadioGroup, FormControlLabel, Radio, TextField, Grid, Button } from '@material-ui/core';

import ContentContainer from '../global/ContentContainer';
import DividerTitle from '../global/DividerTitle';
import SpecificOptions from './SpecificOptions';
import ExpirationDate from './ExpirationDate';
import AddTags from '../helper/AddTags';
import content from '../../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    textfieldRow: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',

        }
    },
    textfield: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 250,
            marginRight: theme.spacing(1)
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    section: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    }
}));

export default function CreateToken(props) {
    const classes = useStyles();
    const urlParams = new URLSearchParams(window.location.search);
    const [tags, setTags] = useState([]);
    const [secureOrg, setSecureOrg] = useState("Streamname");
    const [secureOrgValue, setSecureOrgValue] = useState("");
    const [tokenSetup, setTokenSetup] = useState({
        expirationDate: new Date(Date.now() + 12096e5),
        ip: "",
        referer: "",
    })

    const deleteTag = (tag) => {
        let updatedTagArray = tags.filter((singleTag) => singleTag !== tag);
        setTags(updatedTagArray);
    }

    const addNewTag = (tag) => {
        let updatedTagArray = [tag];
        setTags(updatedTagArray);
    }

    const handleSecureOrgInserted = (event) => {
        setSecureOrgValue(event.target.value);
    }

    const handleSwitchedRadio = (event) => {
        if (event.target.value !== secureOrg) {
            setSecureOrgValue("")
        }
        setSecureOrg(event.target.value)
    }

    const updateTokenSetup = (identifier, value) => {
        setTokenSetup({
            ...tokenSetup,
            [identifier]: value
        })
    }

    const handleCreateSecureToken = () => {
        let body = {
            tag: tags.length === 1 ? tags[0] : "",
            expires: Math.round(tokenSetup.expirationDate / 1000)
        }

        secureOrg === "Streamname" ? body.streamname = secureOrgValue : body.orga = secureOrgValue
        if (tokenSetup.ip !== "") { body.ip = tokenSetup.ip }
        if (tokenSetup.referer !== "") { body.referer = tokenSetup.referer }

        props.createToken(body);
    }

    useEffect(() => {
        props.updateOverviewInfo(tokenSetup, secureOrg, secureOrgValue)
    }, [tokenSetup, secureOrgValue])

    useEffect(() => {
        if (secureOrg === "Organisation Hash") {
            setSecureOrgValue(JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)).hash)
        }
        if (secureOrg === "Streamname") {
            if (urlParams.has("stream")) {
                setSecureOrgValue(urlParams.get("stream"))
            }
        }
    }, [secureOrg])

    return (
        <Grid item xs={12} className={classes.root}>
            <ContentContainer>
                <DividerTitle title={content.createSecurePlayoutToken.createTokenFor.title} />
                <FormControl className={classes.formControl}>
                    <RadioGroup
                        className={classes.radioGroup}
                        value={secureOrg}
                        onChange={handleSwitchedRadio}
                    >
                        <FormControlLabel value="Streamname" control={<Radio />} label="Streamname" />
                        <FormControlLabel value="Organisation Hash" control={<Radio />} label="Organisation Hash" />
                    </RadioGroup>
                    <div className={classes.textfieldRow}>
                        <TextField
                            className={classes.textfield}
                            fullWidth
                            color="primary"
                            label={secureOrg}
                            value={secureOrgValue}
                            onChange={handleSecureOrgInserted}
                        />
                    </div>
                </FormControl>
            </ContentContainer>
            <ContentContainer>
                <DividerTitle title={content.createSecurePlayoutToken.expirationDate.title} />
                <ExpirationDate
                    date={tokenSetup.expirationDate}
                    updateValue={updateTokenSetup}
                />
            </ContentContainer>
            <ContentContainer>
                <DividerTitle title={content.createSecurePlayoutToken.specificOptions.title} />
                <SpecificOptions
                    ip={tokenSetup.ip}
                    referer={tokenSetup.referer}
                    updateValue={updateTokenSetup}
                />
            </ContentContainer>
            <ContentContainer>
                <DividerTitle title={content.createSecurePlayoutToken.addTags.title} />
                <AddTags
                    tags={tags}
                    addNewTag={addNewTag}
                    deleteTag={deleteTag}
                />
            </ContentContainer>
            <div className={classes.section}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateSecureToken}
                >
                    {content.createSecurePlayoutToken.button}
                </Button>
            </div>
        </Grid>
    );
}