import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Hidden } from '@material-ui/core';
import { AddBox, ArrowRightAlt, Reorder, Delete, DeleteSweep, PlaylistPlay, PlaylistAdd, Stop } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import content from '../utilities/helper/content';
import * as link from '../utilities/helper/link-config';
import * as type from '../utilities/context/type';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as methods from '../utilities/helper/methods';

import StreamPreviewItem from '../components/streams/StreamPreviewItem';
import RedirectLink from '../components/global/RedirectLink';
import Title from '../components/global/Title';
import Count from '../components/dashboard/Count';
import MobilePreviewItem from '../components/streams/MobilePreviewItem';
import ContentContainer from '../components/global/ContentContainer';
import RedirectNewDashboard from '../components/global/RedirectNewDashboard';
import UserRoleIntroBanner from '../components/global/UserRoleBanner';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    headerWrapper: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 0
        }

    },
    header: {
        background: theme.palette.grey[100],
        color: theme.palette.primary.main,
        borderBottom: 'none'
    },
    tableHeader: {
        color: theme.palette.primary.main
    },
    label: {
        fontWeight: 700
    },
    container: {
        paddingBottom: theme.spacing(1),
    },
    title: {
        fontWeight: 900,
        lineHeight: 1,
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('500')]: {
            marginBottom: 0
        }
    },
    hash: {
        fontWeight: 400,
        linHeight: 1,
    },
    bold: {
        fontWeight: 900,
        lineHeight: 1
    },
    infoItem: {
    },
    accordion: {
        marginTop: theme.spacing(2)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    countOverview: {
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    }
}));


export default function Dashboard() {
    const classes = useStyles();
    document.title = "nanoStream Cloud";
    const { dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const [streamlist, setStreamlist] = useState([]);
    const [orga, setOrga] = useState({
        name: "",
        trial: false,
        playerKey: "",
        hash: ""
    });


    const tableCells = [
        { id: "state", label: "State" },
        { id: "id", label: "Stream Id" },
        { id: "streamname", label: "Stream Name" },
        { id: "updatedAt", label: "Updated At" },
        { id: "tags", label: "Tags" },
        { id: "open", label: "" }
    ];

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname);

        if (localStorage.getItem(localStorageHelper.BINTU_ORGA)) {
            let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA))
            setOrga(orga);
            bintu.getStreamCollection(apikey, "live", [], orga.created_at, new Date(Date.now()).toISOString(), 5, 0,
                (success) => {
                    setStreamlist(success);
                }, (error) => {
                    dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                })
        }
    }, [])

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} >
                    <div className={classes.headerWrapper}>
                        <Typography color="primary" variant="h4" className={classes.title}>
                            {orga.name}
                        </Typography>
                        <RedirectLink to={link.CREATE_STREAM}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                startIcon={<AddBox />}
                            >
                                {content.createStream.button}
                            </Button>
                        </RedirectLink>
                    </div>
                    {
                        orga.trial
                            ?
                            <Typography color="secondary" variant="button" className={classes.bold}>
                                {content.dashboard.freeTrial}
                            </Typography>
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <UserRoleIntroBanner />
                </Grid>
                <RedirectNewDashboard />
                {
                    localStorage.getItem(localStorageHelper.BINTU_ORGA)
                        ?
                        <Grid item xs={12}>
                            <Title title="Stream Overview" />
                            <div className={classes.countOverview}>
                                {
                                    [
                                        { label: "Total", state: "" },
                                        { label: "created", state: "created" },
                                        { label: "live", state: "live" },
                                        { label: "ended", state: "ended" },
                                        { label: "deleted", state: "deleted" },
                                    ].map((count, index) => (
                                        <RedirectLink
                                            key={index}
                                            to={{
                                                pathname: link.STREAMS,
                                                search: `${count.state !== "" ? `state=${count.state}` : ""}`
                                            }}
                                        >
                                            <Count count="streams" id={count.label} state={count.state} />
                                        </RedirectLink>
                                    ))
                                }
                            </div>
                        </Grid>
                        : null
                }
                <Title title="Your 5 latest live streams" />
                <ContentContainer>
                    {
                        streamlist.length === 0
                            ?
                            <Typography variant="button" color="textSecondary">
                                {content.dashboard.liveStreams.noStreams}
                            </Typography>
                            :
                            <TableContainer className={classes.container}>
                                <Table stickyHeader className={classes.container}>
                                    <TableHead>
                                        <TableRow>
                                            <Hidden smUp>
                                                <TableCell className={classes.header}>
                                                    <Typography className={classes.label} variant="body1">
                                                        All Streams
                                                    </Typography>
                                                </TableCell>
                                            </Hidden>
                                            <Hidden xsDown>
                                                {
                                                    tableCells.map((cell, index) => (
                                                        <TableCell key={index} className={classes.header}>
                                                            <Typography className={classes.label} variant="body1">
                                                                {cell.label}
                                                            </Typography>
                                                        </TableCell>
                                                    ))
                                                }
                                            </Hidden>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <Hidden smUp>
                                            {
                                                streamlist.map((stream, id) => (
                                                    <MobilePreviewItem key={id} stream={stream} />
                                                ))
                                            }
                                        </Hidden>
                                        <Hidden xsDown>
                                            {
                                                streamlist.map((stream, id) => (
                                                    <StreamPreviewItem key={id} stream={stream} />
                                                ))
                                            }
                                        </Hidden>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                    <div className={classes.buttonWrapper}>
                        <RedirectLink to={link.STREAMS}>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                endIcon={<ArrowRightAlt />}
                            >
                                See all streams
                            </Button>
                        </RedirectLink>
                    </div>
                </ContentContainer>
            </Grid>
        </div >
    );
}
