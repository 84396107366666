import React, { useEffect, useReducer, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Collapse, CssBaseline, Divider, Drawer, Hidden, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Add, AddBox, ChevronLeft } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';


import Context from '../../utilities/context/Context';
import * as reducer from '../../utilities/context/reducer';
import * as link from '../../utilities/helper/link-config';
import * as ls from '../../utilities/helper/localStorage-vars';

import logo from '../../assets/white-logo.png'
import logo_o from '../../assets/orange-half-atw.png'

import RedirectLink from '../global/RedirectLink';
import ScrollToTop from './ScrollToTop';
import MenuItems from './Menu';
import RedirectBanner from '../global/RedirectBanner';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.common.white,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    desktopToolbar: {
        background: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    logoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto'
    },
    white: {
        color: theme.palette.common.white
    },
    menuButton: {
        marginRight: 36,
        color: theme.palette.common.white
    },
    hide: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        // background: theme.palette.primary.main,
    },
    paper: {
        borderRight: 0
    },
    drawerPaper: {
        width: 240
    },
    drawerOpen: {
        width: drawerWidth,
        // background: theme.palette.primary.main,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // background: theme.palette.primary.main,
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 1),
        // background: theme.palette.primary.main,
        ...theme.mixins.toolbar,
    },
    toolbarOpen: {
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: '100%',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            maxWidth: `100%`,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            maxWidth: `calc(100% - ${theme.spacing(7) + 1}px)`,
        }
    },
    contentAppbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...theme.mixins.toolbar
    },
    contentOpenToolbar: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc(100% - ${drawerWidth}px)`,
        }
    },
    logo: {
        maxHeight: 30
    },
    atw: {
        maxWidth: '80%',
        padding: theme.spacing(2),
    }
}));

const drawerWidth = 240;

export default function Layout(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { window } = props;
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useReducer(reducer.reducer, reducer.initialState);

    const handleOpenDrawer = () => {
        setOpen(!open);
    };

    return (
        <Context.Provider value={{ state, dispatch }}>
            <div className={clsx({ [classes.root]: state.access })}>
                <ScrollToTop />
                <CssBaseline />
                <Collapse in={state.access}>
                    <AppBar
                        elevation={0}
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                    >
                        <Toolbar className={classes.desktopToolbar}>
                            <IconButton
                                color="inherit"
                                onClick={handleOpenDrawer}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.logoWrapper}>
                                <RedirectLink to={link.DASHBOARD}>
                                    <img src={logo} className={classes.logo} />
                                </RedirectLink>
                            </div>
                            <Hidden smUp>
                                <RedirectLink to={link.CREATE_STREAM}>
                                    <IconButton
                                        color="inherit"
                                    >
                                        <AddBox className={classes.white} />
                                    </IconButton>
                                </RedirectLink>
                            </Hidden>
                        </Toolbar>
                    </AppBar>
                    <Hidden smUp >
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={open}
                            onClose={handleOpenDrawer}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true
                            }}
                        >
                            <div className={classes.logoWrapper}>
                                <img src={logo_o} className={classes.atw} />
                            </div>
                            <Divider />
                            <MenuItems openDrawer={open} clickedMenuItem={handleOpenDrawer} />
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx(classes.paper, {
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open,
                                }),
                            }}
                        >
                            <div className={clsx(classes.toolbar, {
                                [classes.toolbarOpen]: open,
                            })}>
                                <IconButton className={classes.icon} onClick={handleOpenDrawer}>
                                    {open ? <ChevronLeft color="primary" /> : <MenuIcon color="primary" />}
                                </IconButton>
                            </div>
                            <MenuItems openDrawer={open} clickedMenuItem={null} />
                        </Drawer>
                    </Hidden>
                </Collapse>
                <main className={clsx({
                    [classes.content]: state.access,
                    [classes.contentOpenToolbar]: open
                })}>

                    <div className={clsx({
                        [classes.contentAppbar]: state.access,
                    })} />
                    <RedirectBanner />
                    {props.children}
                </main>
            </div>
        </Context.Provider>
    )
}