import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Grid } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import Context from '../../utilities/context/Context';
import content from '../../utilities/helper/content';
import * as type from '../../utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as link from '../../utilities/helper/link-config';
import * as method from '../../utilities/helper/methods';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import Table from './Table';
import PlayoutTable from './PlayoutTable';
import Title from '../global/Title';
import OutlinedTable from './Table';
import H5LiveCodeSnippet from '../player/H5LiveCodeSnippet';
import QuickTokenCreator from '../../components/token/QuickTokenCreator';
import TokenOverview from '../token/TokenOverview';
import CodeAccordion from './Acoordion';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        color: theme.palette.background.paper,
    },
    item: {
        marginTop: theme.spacing(2)
    },
    h5liveWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    accordion: {
        marginTop: theme.spacing(1)
    },
    titleWrapper: {
        marginBottom: theme.spacing(2)
    }
}))

export default function StreamOverview(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
    const [stream, setStream] = useState(props.stream);
    const [info, setInfo] = useState([]);
    const [enableSecure, setEnableSecure] = useState(false);
    const [openTokenCreator, setOpenTokenCreator] = useState(false);
    const [tokenPlayout, setTokenPlayout] = useState(false);
    const [tokenSetup, setTokenSetup] = useState(false);
    const [isOrgaToken, setIsOrgaToken] = useState(true);
    const [tokenInformations, setTokenInformations] = useState({
        referer: "-",
        ip: "-"
    })

    const createInfo = (stream) => {
        console.log(stream)
        let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
        let updatedInfo = [
            { label: "State", state: stream.data.state },
            { label: "Stream ID", value: stream.data.id },
            { label: "Created at", date: { date: `${moment(stream.data.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)` } },
            { label: "Stream Tags", tags: stream.data.tags }
        ];
        if (orga.webrtc) {
            updatedInfo.push({ label: "nanoStream Webcaster", booleanValue: stream.data.webrtc === true ? "true" : "false" })
        }
        if (orga.metadata) {
            updatedInfo.push({ label: "Timecode", booleanValue: stream.data.timecode === true ? "true" : "false" });
            if (stream.data.timecode) {
                updatedInfo.push({ label: "Timecode Interval in ms", value: stream.data.timecode_interval });
            }
        }
        if (orga.transcoding) {
            updatedInfo.push({ label: "Transcoding", booleanValue: stream.data.transcoding === true ? "true" : "false" });
            updatedInfo.push({ label: "Push URL", value: stream.data.pushUrl !== null ? stream.data.pushUrl : "-" });
        }
        if (orga.vod) {
            updatedInfo.push({ label: "live processing", value: stream.data.opcode !== null ? stream.data.opcode : "-" });
        }
        if (orga.secure) {
            updatedInfo.push({ label: "Secure Playback", booleanValue: "true" });
        }
        setInfo(updatedInfo)
    }

    const handleClickedOpenTokenCreator = () => {
        setOpenTokenCreator(!openTokenCreator);
    }

    const handleCreateSecureToken = (setup, isForOrgaHash) => {
        let body = setup;
        if (isForOrgaHash) body.orga = orga.hash
        if (!isForOrgaHash) body.streamname = stream.data.playout.rtmp[0].streamname;
        setTokenInformations({
            ip: body.ip ? body.ip : "-",
            referer: body.referer ? body.referer : "-"
        });
        bintu.createSecureToken(apikey, body,
            (success) => {
                let security = success.h5live.security;
                let url = `${link.URL}${link.PLAYOUT}/${stream.data.id}?security.expires=${security.expires}&security.options=${security.options}&security.tag=${security.tag}&security.token=${security.token}`
                setTokenPlayout(url);
                setTokenSetup(security);
                setOpenTokenCreator(false);
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: { title: "An error occured", message: "Could not create secure playback token. Please try again." }, alert: "error" });
            });
    }

    useEffect(() => {
        setEnableSecure(props.enableSecure);
        if (props.enableSecure) {
            setTokenSetup(method.GET_PLAYER_HASH(orga.hash, apikey));
        }
    }, [props.enableSecure])

    useEffect(() => {
        if (enableSecure) {
            let orgaToken = method.GET_PLAYER_HASH(orga.hash, apikey)
            if ((tokenSetup !== undefined && tokenSetup.tag !== null) && (orgaToken.tag !== null)) {
                setIsOrgaToken(tokenSetup.tag === orgaToken.tag);
            }
        }
    }, [tokenSetup])

    useEffect(() => {
        setStream(props.stream);
        // if (info.length === 0 && props.stream.data !== undefined) {
        //     createInfo(props.stream);
        // }
        // if (info.length !== 0 && props.stream.data.tags !== undefined) {
        //     let updatedInfo = info;
        //     updatedInfo[3] = { ...info[3], tags: props.stream.data.tags };
        //     setInfo(updatedInfo);
        // }
        if (props.stream.data !== undefined) {
            createInfo(props.stream);
        }
    }, [props.stream])

    useEffect(() => {
        if (stream.liveUrls !== undefined) {
            setTokenPlayout(stream.liveUrls[0].url);
        }
    }, [stream])

    return (
        <Grid container>
            <Grid item xs={12} className={classes.item}>
                {
                    stream.data !== undefined && info.length !== 0
                        ?
                        <Table
                            label={content.stream.info.streamInfoTable}
                            list={info}
                        />
                        : null

                }
            </Grid>
            <Grid item xs={12} className={classes.item}>
                {
                    stream.data && stream.data.ingest !== undefined
                        ?
                        <Table
                            label={content.stream.info.ingestTable}
                            list={[
                                { label: "RTMP Ingest Streamname", value: stream.data.ingest.rtmp.streamname },
                                { label: "RTMP Ingest Url", value: stream.data.transcoding ? (stream.data.ingest.rtmp.url).replace("stream", "vtrans") : stream.data.ingest.rtmp.url },
                            ]}
                        />
                        : null
                }
            </Grid>
            <Grid item xs={12}>
                {
                    stream.data && stream.data.playout !== undefined
                        ?
                        <div className={classes.titleWrapper}>
                            <Title
                                title={content.stream.playouts.title}
                                button={enableSecure ? content.createQuickPlayoutToken.button : false}
                                startIcon={<Lock />}
                                clicked={handleClickedOpenTokenCreator}
                            />
                            <Collapse in={openTokenCreator}>
                                <QuickTokenCreator
                                    createToken={handleCreateSecureToken}
                                />
                            </Collapse>
                            <div className={classes.item}>
                                {
                                    enableSecure
                                        ?
                                        <Collapse in={tokenSetup}>
                                            <TokenOverview
                                                informations={tokenInformations}
                                                token={tokenSetup}
                                            />
                                        </Collapse>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }
                {
                    stream.data && stream.data.playout !== undefined
                        ?
                        <div>
                            <PlayoutTable
                                label={content.stream.playouts.h5live.title}
                                underline={enableSecure ? `${stream.data.transcoding ? `${content.stream.playouts.h5live.underline.transcodedSingleStream} ` : ``} ${isOrgaToken ? content.stream.playouts.h5live.underline.secureOrgaToken : content.stream.playouts.h5live.underline.secureSpecificToken}` : `${stream.data.transcoding ? `${content.stream.playouts.h5live.underline.transcodedSingleStream} ${isOrgaToken ? content.stream.playouts.h5live.underline.secureOrgaToken : content.stream.playouts.h5live.underline.secureSpecificToken}` : ``}`}
                                openInNew="NanoPlayer Documentation"
                                to={link.H5LIVE_DOCS}
                                list={[{ type: "live", url: tokenPlayout }]}
                                streamnames={[stream.data.ingest.rtmp.streamname]}
                            />
                            {
                                stream.data
                                    ?
                                    <Collapse in={stream.data}>
                                        <H5LiveCodeSnippet
                                            security={tokenSetup}
                                            streams={[stream.data]}
                                        />
                                    </Collapse>
                                    : null
                            }
                            {
                                !enableSecure
                                    ? <div className={classes.item}>
                                        <OutlinedTable
                                            label={content.stream.playouts.rtmp.title}
                                            list={[
                                                { label: "RTMP Streamname", value: stream.data.playout.rtmp[0].streamname },
                                                { label: "RTMP URL", value: stream.data.playout.rtmp[0].url }
                                            ]}
                                        />
                                    </div>
                                    : null

                            }
                            <Grid item xs={12} className={classes.item}>
                                {
                                    stream.vodUrls && !(stream.vodUrls.length === 0)
                                        ?
                                        <PlayoutTable
                                            label={content.stream.playouts.vod.title}
                                            list={stream.vodUrls}
                                            streamnames={[stream.data.ingest.rtmp.streamname]}
                                        />
                                        : null
                                }
                            </Grid>
                            <div className={classes.item}>
                                {
                                    stream.data.opcode === "thumbs"
                                        ?
                                        <Table
                                            label={content.stream.playouts.thumbnail.title}
                                            underline={content.stream.playouts.thumbnail.underline}
                                            list={[
                                                { label: "Thumbnail", playout: `https://bintu-vod.nanocosmos.de/vod/thumbnails/${stream.data.ingest.rtmp.streamname}.jpg`, open: true },
                                            ]}
                                        />
                                        : null
                                }
                            </div>
                            <div className={classes.item}>
                                {
                                    stream.data.playout.hls
                                        ?
                                        <Table
                                            label={content.stream.playouts.hls.title}
                                            underline={content.stream.playouts.hls.underline}
                                            list={[
                                                { label: "Live (HLS Legacy / Standard Latency)", playout: stream.data.playout.hls[0].url, open: true },
                                            ]}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }
            </Grid>
        </Grid>
    )
}