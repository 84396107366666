import axios from 'axios';

// const BINTU_URL = "https://bintu.nanocosmos.de"
// const BINTU_URL = process.env.BINTU_BASE_URL || "https://bintu-dev.nanocosmos.de"
const BINTU_URL = window.BINTU_API || "https://bintu.nanocosmos.de"
const TOKEN_API = window.TOKEN_API || "https://token.nanostream.cloud/secure/token";

// VALIDATE
export const validateUser = (email, password, success, error) => {
    let body = {
        email: email,
        password: password
    };

    axios.post(`${BINTU_URL}/user/validate`, body)
        .then((res) => {
            success(res.data);
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "The Login failed." })
        });
}

// CREATE
export const createUser = (email, password, organisation, success, error) => {
    let body = {
        email: email,
        password: password,
        organisation: organisation
    };

    axios.post(`${BINTU_URL}/user`, body)
        .then((res) => {
            success(res.data);
        }).catch((res) => {
            console.log(res);
            error({ title: "An error occured", message: "You could not sign up." })
        });
}

export const createStreamGroup = (apikey, enableMetadata, metadata, timecode, enableTranscoding, profiles, tags, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apikey
        }
    };

    let transcodingProfiles = profiles.map((e, i) => { return { profile: profiles[i].identifier, tags: [`${i + 1}. Transcode`, ...profiles[i].tags] } })

    let body = {
        tags: profiles.length > 0 ? ["Passthrough", ...tags] : tags,
        webrtc: true,
        ...enableMetadata && { timecode: metadata },
        ...enableMetadata && { interval: timecode },
        ...(enableTranscoding && profiles.length > 0) && { transcodes: transcodingProfiles },
        ...(enableTranscoding && profiles.length > 0) && { transcoding: true }
    };

    axios.post(`${BINTU_URL}/stream`, body, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not create stream." })
        })
}

export const createSecureToken = (apiKey, body, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };

    axios.post(TOKEN_API, body, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not create playback token." })
        })
}

export const createInviteToken = (apiKey, success, error) => {
    console.log(apiKey)
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey,
        }
    };
    axios.post(`${BINTU_URL}/invitetoken`, {}, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not create invite token." })
        })
}

export const redeemToken = (token, email, password, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
        }
    };

    let body = {
        email: email,
        password: password,
        token: token
    };

    axios.post(`${BINTU_URL}/invitetoken/redeem`, body, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not post redeem token." })
        })
}

export const createTag = (apikey, tag, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apikey
        }
    };
    let body = {
        tag: tag
    };
    axios.post(`${BINTU_URL}/tag`, body, headers)
        .then((res) => {
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not create tag." })
        })
}

export const createWebhook = (apikey, webhook, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apikey
        }
    };
    let body = {
        webhook: webhook
    };
    axios.put(`${BINTU_URL}/organisation/webhook`, body, headers)
        .then((res) => {
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not create custom web hook." })
        })
}


// GET
export const getInviteTokenCollection = (apiKey, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/invitetoken`, headers)
        .then((res) => {
            console.log(res);
            success(res.data);
        }).catch((res) => {
            console.log(res);
            error({ title: "An error occured", message: "The invite token collection could not be fetched." })
        })
}


export const getOrga = (apiKey, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/organisation`, headers)
        .then((res) => {
            console.log(res);
            success(res.data);
        }).catch((res) => {
            console.log(res);
            error({ title: "An error occured", message: "The Orga Informations could not be fetched." })
        })
}

export const getAllStreams = (apiKey, offset, quantity, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/stream?order=desc&quantity=${quantity}&offset=${offset}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not fetch stream list." })
        })
}

export const publicGetStream = (id, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
        }
    };
    axios.get(`${BINTU_URL}/stream/${id}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not get the stream." })
        })
}

export const getStream = (apiKey, id, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/stream/${id}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not get the stream." })
        })
}

export const getStreamv2 = (apiKey, id, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/stream/${id}/group`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not get the stream." })
        })
}


export const getStreamCollection = (apiKey, state, tags, from, until, quantity, offset, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };

    let stateQuery = state === "" ? "" : `&state=${state}`
    let tagQuery = `${tags.length > 0 ? `&tags[]=${tags.join("&tags[]=")}` : ""}`;
    axios.get(`${BINTU_URL}/stream?order=desc&from=${from}&until=${until}${stateQuery}${tagQuery}&quantity=${quantity}&offset=${offset}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not filter streams." })
        })
}

export const getOrgaUsage = (apiKey, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/organisation/usage`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not count stream collections." })
        })
}

export const getTagCollection = (apiKey, offset, limit, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/tag?offset=${offset}&limit=${limit}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not fetch tag collection." })
        })
}

export const getTagsByRegex = (apiKey, regex, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/tag?tags[]=${regex}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not fetch tags by regex." })
        })
}

export const getRecentlyUsedTags = (apiKey, startDate, endDate, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/tag/recently_used?from=${startDate}&to=${endDate}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not fetch tags by regex." })
        })
}

export const getUser = (apiKey, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    axios.get(`${BINTU_URL}/user`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not fetch user." })
        })
}

// COUNT
export const countAllStreams = (apiKey, state, tags, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };

    let stateQuery = state === "" ? false : `state=${state}`
    let tagQuery = tags.length > 0 ? `?tags[]=${tags.join("&tags[]=")}` : false;

    axios.get(`${BINTU_URL}/stream/count${tagQuery ? tagQuery : ""}${stateQuery ? `${tagQuery ? "&" : "?"}${stateQuery}` : ""}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not count all streams." })
        })
}

export const countStream = (apiKey, state, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    let query = state === "total" ? "" : `?state=${state}`;
    axios.get(`${BINTU_URL}/stream/count${query}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not count stream collections." })
        })
}

// SET
export const setPushUrl = (apiKey, streamId, profile, streamname, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    const body = {
        url: `rtmp://127.0.0.1/${profile}/${streamname}`
    }

    axios.put(`${BINTU_URL}/stream/${streamId}/push_url`, body, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not set push url." })
        })
}

export const updateStreamTags = (apiKey, streamId, tags, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };
    const body = {
        tags: tags
    }

    axios.put(`${BINTU_URL}/stream/${streamId}/tag`, body, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not set tags." })
        })
}

export const hideVod = (apikey, hide, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apikey
        }
    };
    let body = {
        publicVod: hide
    };
    axios.put(`${BINTU_URL}/organisation/vod`, body, headers)
        .then((res) => {
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not (un)hide VOD." })
        })
}

export const setTimecodeInterval = (apikey, timecode, interval, streamid, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apikey
        }
    };
    console.log(timecode, interval)
    let body = {
        timecode,
        ...timecode === true && { interval },
    };
    console.log(body)
    axios.put(`${BINTU_URL}/stream/${streamid}/timecode`, body, headers)
        .then((res) => {
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not set timecode." })
        })
}

export const setOpCode = (apikey, streamid, opcode, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apikey
        }
    };
    let body = {
        opcode
    };
    axios.put(`${BINTU_URL}/stream/${streamid}/opcode`, body, headers)
        .then((res) => {
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: `Could not set opcode to ${opcode}` })
        })
}

export const deleteStream = (apiKey, streamId, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };

    axios.delete(`${BINTU_URL}/stream/${streamId}`, headers)
        .then((res) => {
            console.log(res)
            success(res.data)
        }).catch((res) => {
            console.log(res)
            error({ title: "An error occured", message: "Could not delete stream." })
        })
}

export const deleteUser = (apiKey, userId, success, error) => {
    let headers = {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json",
            'X-BINTU-APIKEY': apiKey
        }
    };

    let data = {
        userId: userId
    }

    const options = {
        method: 'DELETE',
        url: `${BINTU_URL}/user`,
        headers: {
            'Content-Type': 'application/json',
            'x-bintu-apikey': apiKey
        },
        data: { userId: userId }
    };

    axios.request(options)
        .then((response) => {
            console.log(response.data)
            success(response.data)
        }).catch((response) => {
            error({ title: "An error occured", message: "Could not delete user." })
        });
}


export const resetPassword = (email, success, error) => {
    const options = {
        method: 'POST',
        url: `${BINTU_URL}/user/password`,
        headers: { 'Content-Type': 'application/json' },
        data: { email }
    };

    axios.request(options).then(function (response) {
        console.log(response.data);
        success(response.data)
    }).catch(function (error) {
        error({ title: "An error occured", message: "Could not reset password." })
    });
}

export const setPassword = (email, password, token, success, error) => {
    const options = {
        method: 'PUT',
        url: `${BINTU_URL}/user/password`,
        headers: { 'Content-Type': 'application/json' },
        data: { email, password, token }
    };

    axios.request(options).then(function (response) {
        console.log(response.data);
        success(response.data)
    }).catch(function () {
        error({ title: "An error occured", message: "Could not set new password." })
    });
}
