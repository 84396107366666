import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: theme.spacing(1, 2, 0, 0),
        // color: theme.palette.common.white,
        borderRadius: theme.spacing(0.5),
        background: theme.palette.common.white
        // border: `1px solid #f3f3f3`
    },
    item: {
        background: theme.palette.grey[100],
        color: theme.palette.text.secondary,
        margin: theme.spacing(1, 1, 0, 0),
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(0.5),
        alignItems: 'center',
        width: 'auto'
    },
    enabled: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    name: {
        marginLeft: theme.spacing(0.5)
    }
}))

export default function EnabledOptions(props) {
    const classes = useStyles();

    return (
        <Tooltip title={`Contact sales to enable ${props.item.label}.`} disableHoverListener={props.item.value}>
            <div className={clsx(classes.root, classes.item, {
                [classes.enabled]: props.item.value,
            })}>
                {
                    props.item.value
                        ? props.item.icon
                        : <HighlightOff />
                }
                <Typography className={classes.name} variant="body1">
                    {props.item.label}
                </Typography>
            </div>
        </Tooltip>
    )
}