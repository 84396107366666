import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { fade, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { AddBox, Dashboard, ExitToApp, Group, GroupAdd, LiveHelp, LiveTv, Lock, People, PieChart, QuestionAnswer, Search, VideoLibrary, VpnLock } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';

import * as link from '../../utilities/helper/link-config';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';
import content from '../../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    list: {
        padding: 0,
    },
    label: {
        lineHeight: 1,
        marginLeft: theme.spacing(1)
    },
    menuItem: {
        // background: theme.palette.primary.main,
        color: theme.palette.grey[700],
        textDecoration: 'none',
        height: '100%',
        // width: '100%',
        padding: theme.spacing(1, 1),
        margin: `${theme.spacing(1)}px auto`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(0.5),
        '&:hover': {
            cursor: 'pointer',
            background: fade(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main
        }
    },
    openDrawer: {
        width: '100%',
        margin: theme.spacing(1),
        justifyContent: 'flex-start',
    },
}))

export default function MenuItems(props) {
    const classes = useStyles();
    const [enableSecure, setEnableSecure] = useState(false);

    const [menu] = useState([
        { title: "Home", link: link.DASHBOARD, icon: <Dashboard /> },
        { title: "Streams", link: link.STREAMS, icon: <ListIcon /> },
        { title: "Create Stream", link: link.CREATE_STREAM, icon: <AddBox /> },
        { title: "Metrics", link: link.METRICS, icon: <PieChart /> },
        { title: "Organisation", link: link.USER, icon: <People /> },
    ])

    const [bottomMenu] = useState([
        { title: "About", link: link.ABOUT, icon: <LiveHelp /> },
        { title: "Support", link: link.SUPPORT, icon: <QuestionAnswer /> },
        { title: "Logout", link: link.LOGOUT, icon: <ExitToApp /> },
    ])

    let active = {
        background: 'rgba(237, 125, 14, 0.2)',
        color: '#ed7d0e',
    }

    useEffect(() => {
        if (localStorage.getItem(localStorageHelper.BINTU_ORGA)) {
            setEnableSecure(JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)).secure)
        }
    }, [localStorage.getItem(localStorageHelper.BINTU_ORGA)])


    return (
        <div className={classes.root}>
            <div>
                <List>
                    {
                        menu.map((item) => (
                            <ListItem className={classes.list} key={item.title}>
                                <NavLink
                                    to={item.link}
                                    className={clsx(classes.menuItem, {
                                        [classes.openDrawer]: props.openDrawer
                                    })}
                                    onClick={props.clickedMenuItem}
                                    isActive={(match, location) => {
                                        if (match) {
                                            return match.isExact
                                        }
                                    }}
                                    activeStyle={active}
                                >
                                    {item.icon}
                                    {
                                        props.openDrawer
                                            ?
                                            <Typography className={classes.label} variant="body1">
                                                {item.title}
                                            </Typography>
                                            : null
                                    }
                                </NavLink>
                            </ListItem>
                        ))
                    }
                    {
                        enableSecure
                            ?
                            <ListItem className={classes.listItem} button>
                                <NavLink
                                    exact to={link.PLAYBACK_TOKEN_HELPER}
                                    onClick={props.clickedMenuItem}
                                    className={clsx(classes.menuItem, {
                                        [classes.openDrawer]: props.openDrawer
                                    })}
                                    activeStyle={active}
                                >
                                    <Lock />
                                    <Typography variant="body1" className={classes.label}>
                                        {content.menu.createSecurePlayoutToken}
                                    </Typography>
                                </NavLink>
                            </ListItem>
                            : null
                    }
                </List>
            </div>
            <div>
                <List>
                    {
                        bottomMenu.map((item) => (
                            <ListItem className={classes.list} key={item.title}>
                                <NavLink
                                    to={item.link}
                                    onClick={props.clickedMenuItem}
                                    className={clsx(classes.menuItem, {
                                        [classes.openDrawer]: props.openDrawer
                                    })}
                                    isActive={(match, location) => {
                                        if (match) {
                                            return match.isExact
                                        }
                                    }}
                                    activeStyle={active}
                                >
                                    {item.icon}
                                    {
                                        props.openDrawer
                                            ?
                                            <Typography className={classes.label} variant="body1">
                                                {item.title}
                                            </Typography>
                                            : null
                                    }
                                </NavLink>
                            </ListItem>
                        ))
                    }
                </List>
            </div>
        </div>
    )
}