import React, { useEffect, useState, useContext, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Collapse } from '@material-ui/core';
import { Duo, More, PersonAdd, VideoLibrary } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import content from '../utilities/helper/content';
import * as type from '../utilities/context/type';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import DeleteUser from '../components/organisation/DeleteUser';
import ResetPassword from '../components/organisation/ResetPassword';
import KeyAccordion from '../components/organisation/KeyAccordion';
import PackageOverview from '../components/organisation/PackageOverview';
import PageHeader from '../components/global/PageHeader';
import Title from '../components/global/Title';
import OrgaInfo from '../components/organisation/OrgaInfo';
import Tags from '../components/organisation/Tags';
import ContentContainer from '../components/global/ContentContainer';
import CreateTag from '../components/organisation/CreateTag';
import UserList from '../components/organisation/UserList';
import InviteTokenList from '../components/organisation/InviteTokenList';
import SpinningLoadingLogo from '../components/utilities/SpinningLoadingLogo';
import CreateInviteToken from '../components/organisation/CreateInviteToken';
import Webhook from '../components/organisation/Webhook';
import UpdateVOD from '../components/organisation/UpdateVOD';

const useStyles = makeStyles((theme) => ({
    item: {
        marginTop: theme.spacing(1)
    },
    header: {
        fontWeight: 900,
        lineHeight: 1,
        marginRight: theme.spacing(1)
    },
    headerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
}));


export default function Organisation() {
    const classes = useStyles();
    document.title = "Organisation";

    const { dispatch } = useContext(Context);
    const [apikey] = useState(localStorage.getItem(localStorageHelper.BINTU_APIKEY));
    const [orga] = useState(JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)));
    const [user, setUser] = useState([]);
    const [token, setToken] = useState([]);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingInviteToken, setLoadingInviteToken] = useState(true);
    const [removeUser, setRemoveUser] = useState(false);
    const [resetUser, setResetUser] = useState(false);
    const [updateVOD, setUpdateVod] = useState(false);
    const [createTag, setCreateTag] = useState(false);
    const [inviteToken, setInviteToken] = useState(null);
    const [refreshTags, setRefreshTags] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState("");
    const [selectedUser, setSelectedUser] = useState({
        email: "",
        id: "",
        created_at: ""
    });

    const handleClickedCustomizeVOD = () => {
        setUpdateVod(!updateVOD);
    }

    const handleCreateInviteToken = () => {
        createInviteToken();
    }

    const handleCreateTag = () => {
        setCreateTag(!createTag)
    }

    const handleRefreshTags = (refresh) => {
        setRefreshTags(refresh);
        setCreateTag(!createTag)
    }

    const handleResetPassword = (user) => {
        setSelectedUser(user);
        setResetUser(true);
    }

    const handleDeleteUser = (user) => {
        setSelectedUser(user);
        setRemoveUser(true);
    }

    const redirectToStreams = (tag) => {
        // setRedirectRoute(<Redirect to={{
        //     pathname: link.STREAMS,
        //     search: `tags=${tag}`
        // }} />)
    }

    const confirmResetPassword = () => {
        // deleteuser
        const message = {
            title: `Reset password`,
            message: `A mail including a token was sent to ${selectedUser.email}. Use the token to reset the password.`
        }
        dispatch({ type: type.SHOW_ERROR, error: message, alert: "info" });
        setResetUser(false);
    }

    const confirmDeleteUser = () => {
        // deleteuser
        bintu.deleteUser(apikey, selectedUser.id,
            (success) => {
                const message = {
                    title: `Success`,
                    message: `You successfully deleted ${selectedUser.email}.`
                }
                dispatch({ type: type.SHOW_ERROR, error: message, alert: "success" });
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
        getAllUser();
        setRemoveUser(false);
    }

    const createInviteToken = () => {
        bintu.createInviteToken(apikey,
            (success) => {
                setInviteToken(success.token);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            });
    }

    const closeModal = (modal) => () => {
        switch (modal) {
            case "delete":
                setRemoveUser(false);
                break;
            case "reset":
                setResetUser(false);
                break;
            default: break;
        }

    }

    const getAllUser = () => {
        setLoadingUser(true);
        bintu.getUser(apikey,
            (success) => {
                setUser(success);
                setLoadingUser(false);
            }, (error) => {
                setLoadingUser(false);
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const getAllToken = () => {
        bintu.getInviteTokenCollection(apikey,
            (success) => {
                setToken(success);
                setLoadingInviteToken(true);
            }, (error) => {
                setLoadingInviteToken(true);
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }


    useEffect(() => {
        if (refreshTags) {
            setRefreshTags(false);
        }
    }, [refreshTags])

    useEffect(() => {
        
        methods.SEND_GA_ANALYTICS(window.location.pathname);

        getAllUser();
        // getAllToken();
    }, [])


    return (
        <div className={classes.root}>
            {redirectRoute}
            <Grid container>
                <PageHeader title={content.organisation.title} />
                <PackageOverview />
                <OrgaInfo />
                <Title title={content.organisation.keys.title} />
                <Grid item xs={12} className={classes.item}>
                    <KeyAccordion />
                </Grid>
                <Grid item xs={12}>
                    <Webhook />
                </Grid>
                <Title
                    title={content.organisation.user.title}
                    button={content.inviteToken.button}
                    startIcon={<PersonAdd />}
                    clicked={handleCreateInviteToken}
                />
                <Grid item xs={12} className={classes.item}>
                    <Collapse in={inviteToken !== null}>
                        <CreateInviteToken token={inviteToken} />
                    </Collapse>
                </Grid>
                {
                    loadingUser
                        ? <SpinningLoadingLogo />
                        : null

                }
                <Grid item xs={12}>
                    <Collapse in={!loadingUser}>
                        <Collapse in={removeUser}>
                            <DeleteUser
                                user={selectedUser}
                                close={closeModal("delete")}
                                deleteUser={confirmDeleteUser}
                            />
                        </Collapse>
                        <UserList
                            resetPassword={handleResetPassword}
                            deleteUser={handleDeleteUser}
                            user={user}
                        />
                    </Collapse>
                </Grid>
                {/* <Title
                    title="All Invite Token"
                />
                {
                    loadingInviteToken
                        ? <SpinningLoadingLogo />
                        : null

                } 
                <Grid item xs={12}>
                    <Collapse in={!loadingInviteToken}>
                        <InviteTokenList
                            token={token}
                        />
                    </Collapse>
                </Grid> */}
                <Title
                    title={content.organisation.tags.title}
                    button={content.createTag.button}
                    startIcon={<More />}
                    clicked={handleCreateTag}
                />
                <Grid item xs={12} className={classes.item}>
                    <Collapse in={createTag}>
                        <CreateTag updatedTags={handleRefreshTags} />
                    </Collapse>
                </Grid>
                <ContentContainer>
                    <Tags
                        refresh={refreshTags}
                        clickedTag={redirectToStreams}
                    />
                </ContentContainer>
                {
                    orga && orga.vod
                        ?
                        <Fragment>
                            <Title
                                title={content.vod.header}
                                button={content.vod.button}
                                startIcon={<Duo />}
                                clicked={handleClickedCustomizeVOD}
                            />
                            <Grid item xs={12} className={classes.item}>
                                <Collapse in={updateVOD}>
                                    <UpdateVOD update={handleClickedCustomizeVOD} />
                                </Collapse>
                            </Grid>
                        </Fragment>
                        : null
                }
            </Grid>
        </div>
    );
}
