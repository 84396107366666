import React, { useState } from 'react';
import clsx from 'clsx'

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Hidden, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import content from '../../utilities/helper/content';

import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    label: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginRight: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    header: {
        background: theme.palette.grey[100],
        padding: theme.spacing(0.5, 0),
    },
    hover: {
        cursor: 'pointer'
    },
    container: {
    },
    date: {
        lineHeight: 1,
        fontSize: '0.9em'
    },
    email: {
        color: theme.palette.primary.main,
        fontWeight: 400
    },
    cell: {
        padding: theme.spacing(0.5, 0)
    },
    button: {
        marginBottom: theme.spacing(2)
    },
    infoWrapper: {
        padding: theme.spacing(0.5, 0),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    sortlabel: {
    },
    activeIcon: {
        '& path': {
            fill: theme.palette.primary.main
        }
    },
    iconWrapper: {
        marginRight: theme.spacing(1)
    }
}));

export default function UserList(props) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('email');

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const sortTable = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);

    }

    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeleteUser = (user) => () => {
        props.deleteUser(user)
    }

    const DeleteIcon = (props) => {
        return (
            <IconButton size="small" onClick={handleDeleteUser(props.user)}>
                <Delete fontSize="inherit" />
            </IconButton>
        )
    }

    return (
        <ContentContainer>
            {
                props.user === []
                    ?
                    <Typography variant="button" color="textSecondary">
                        {content.organisation.user.noUser}
                    </Typography>
                    :
                    <TableContainer className={classes.container}>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <Hidden smUp>
                                        <TableCell className={classes.header}>
                                            <Typography className={classes.label} variant="body1" onClick={handleRequestSort("email")}>
                                                User
                                            <TableSortLabel
                                                    classes={{
                                                        root: classes.sortlabel,
                                                        icon: classes.icon,
                                                        active: classes.activeIcon
                                                    }}
                                                    active={orderBy === "email"}
                                                    direction={order}
                                                />
                                            </Typography>
                                        </TableCell>
                                    </Hidden>
                                    <Hidden xsDown>
                                        {
                                            [
                                                { id: "email", label: "Email", sort: true },
                                                { id: "id", label: "Id", sort: false },
                                                { id: "created_at", label: "Created At", sort: true },
                                                { id: "delete", label: "", sort: false },
                                            ].map((headCell, index) => (
                                                <TableCell
                                                    key={index}
                                                    className={
                                                        clsx(classes.header, {
                                                            [classes.hover]: headCell.sort,
                                                        })}
                                                    onClick={handleRequestSort(headCell.id)}
                                                >
                                                    {
                                                        headCell.sort
                                                            ?
                                                            <Tooltip title={`Sort by ${headCell.label}`}>
                                                                <Typography className={classes.label} variant="body1">
                                                                    {headCell.label}
                                                                    {
                                                                        headCell.sort
                                                                            ?
                                                                            <TableSortLabel
                                                                                classes={{
                                                                                    root: classes.sortlabel,
                                                                                    icon: classes.icon,
                                                                                    active: classes.activeIcon
                                                                                }}
                                                                                active={orderBy === headCell.id}
                                                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                                            // onClick={handleRequestSort(headCell.id)}
                                                                            />
                                                                            : null
                                                                    }
                                                                </Typography>
                                                            </Tooltip>
                                                            :
                                                            <Typography className={classes.label} variant="body1">
                                                                {headCell.label}
                                                            </Typography>
                                                    }
                                                </TableCell>
                                            ))
                                        }
                                    </Hidden>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(sortTable(props.user, getComparator(order, orderBy))).map((user) => (
                                    <TableRow hover key={user.email}>
                                        <Hidden smUp>
                                            <TableCell className={classes.infoWrapper}>
                                                <div>
                                                    <Typography className={classes.email} variant="subtitle2">
                                                        {user.email}
                                                    </Typography>
                                                    {/* <Typography variant="subtitle2">
                                                        {user.id}
                                                    </Typography> */}
                                                    <Typography color="textSecondary" variant="subtitle2" className={classes.date}>
                                                        {moment(user.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                                                    </Typography>
                                                </div>
                                                <div className={classes.iconWrapper}>
                                                    {
                                                        props.user.length === 1
                                                            ? null
                                                            : <DeleteIcon user={user} />
                                                    }
                                                </div>
                                            </TableCell>
                                        </Hidden>
                                        <Hidden xsDown>
                                            <TableCell className={classes.cell}>
                                                <Typography className={classes.email} variant="subtitle2">
                                                    {user.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography variant="subtitle2">
                                                    {user.id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography color="textSecondary" variant="subtitle2" className={classes.date}>
                                                    {moment(user.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {
                                                    props.user.length === 1
                                                        ? null
                                                        :
                                                        <Tooltip title="Delete User">
                                                            <DeleteIcon user={user} />
                                                        </Tooltip>
                                                }
                                            </TableCell>
                                        </Hidden>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </ContentContainer>
    );
}
