import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icon, ButtonBase, Grid, CircularProgress } from '@material-ui/core';

import Context from '../../utilities/context/Context';
import * as type from '../..//utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import SpinningLoadingLogo from '../utilities/SpinningLoadingLogo';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            minWidth: "100%",
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: "auto",
            width: "auto",
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    circleBackground: {
        color: theme.palette.grey[200]
    },
    typoWrapper: {
        marginLeft: theme.spacing(1),
        textAlign: 'left'
    }
}));

export default function Count(props) {
    const classes = useStyles();
    const { dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const thickness = 8;

    const countStreams = (state) => {
        bintu.countStream(apikey, state,
            (success) => {
                setCount(success.count)
                setLoading(false);
                bintu.countStream(apikey, "",
                    (response) => {
                        setPercentage((success.count / response.count) * 100)
                    }, () => {

                    })
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                setLoading(false);
            });
    }

    const countUser = () => {
        bintu.getUser(apikey,
            (success) => {
                setCount(success.length);
                setLoading(false);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                setLoading(false);
            })
    }

    const countTags = () => {
        bintu.getTagCollection(apikey, 0, 0,
            (success) => {
                setCount(success.overall_tag_count);
                setLoading(false);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
                setLoading(false);
            })
    }

    useEffect(() => {
        setLoading(true);
        if (localStorage.getItem(localStorageHelper.BINTU_APIKEY)) {
            switch (props.count) {
                case "streams": countStreams(props.state); break;
                case "user": countUser(); break;
                case "tags": countTags(); break;
                default: break;
            }
        }
    }, []);

    return (
        <ButtonBase focusRipple className={classes.root}>
            {
                loading
                    ? <SpinningLoadingLogo />
                    :
                    <div className={classes.root}>
                        <ContentContainer>
                            <div className={classes.cardContent}>
                                <div style={{ position: 'relative', }}>
                                    <CircularProgress
                                        className={classes.circleBackground}
                                        thickness={thickness}
                                        variant="determinate"
                                        value="100"
                                    />
                                    <CircularProgress
                                        style={{ position: 'absolute', left: 0, transform: "rotate(-90deg)", webkitTransform: "rotate(-89deg)" }}
                                        thickness={thickness}
                                        color="primary"
                                        variant="determinate"
                                        value={percentage}
                                    />
                                </div>
                                <div className={classes.typoWrapper}>
                                    <Typography style={{ fontWeight: 700, }} variant="h4" color="primary">
                                        {count}
                                    </Typography>
                                    <Typography style={{ fontWeight: 700 }} variant="button" color="textSecondary">
                                        {props.id} Streams
                                    </Typography>
                                </div>
                            </div>
                        </ContentContainer>
                    </div>

            }
        </ButtonBase>
    );
}
