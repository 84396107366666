import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import DividerTitle from '../global/DividerTitle';
import content from '../../utilities/helper/content';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
    }
}));

export default function ChooseStartIndex(props) {
    const classes = useStyles();
    const [radioValues] = useState([
        { identifier: "first", value: 1, label: "1st transcode" },
        { identifier: "second", value: 2, label: "2nd transcode" },
        { identifier: "third", value: 3, label: "3rd transcode" }
    ])

    const handleChange = (event) => {
        props.setStartIndex(parseInt(event.target.value))
    };

    return (
        <ContentContainer>
            <DividerTitle title={content.createStream.addVtrans.startIndex} />
            <FormControl>
                <RadioGroup className={classes.radioGroup} name="select" value={props.startIndex} onChange={handleChange}>
                    <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="Highest quality"
                        disabled={false}
                    />
                    {
                        radioValues.map((radio, index) => (
                            <FormControlLabel
                                key={index}
                                value={radio.value}
                                control={<Radio color="primary" />}
                                label={radio.label}
                                disabled={props.profiles[radio.identifier].disabled}
                            />
                        ))
                    }
                </RadioGroup>
            </FormControl>
        </ContentContainer>
    )
}