import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        background: theme.palette.grey[100],
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2)
    },
    noPadding: {
        padding: 0
    }
}))

export default function ContentContainer(props) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, {
            [classes.noPadding]: props.noPadding
        })}>
            {props.children}
        </div>
    )
}