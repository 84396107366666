import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination, Hidden } from '@material-ui/core';

import StreamPreviewItem from './StreamPreviewItem';
import MobilePreviewItem from './MobilePreviewItem';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        minHeight: '100%',
        maxHeight: '85vh'
    },
    header: {
        color: theme.palette.primary.main,
        background: theme.palette.grey[100],
        borderBottom: 'none'
    },
    label: {
        fontWeight: 700
    },
    pagination: {
        maxWidth: '100%'
    },
}));

export default function StreamPreviewTable(props) {
    const classes = useStyles();
    var streamlist = props.streamlist;

    const emptyRows = props.quantity - Math.min(props.quantity, streamlist.length - props.page * props.quantity);

    const tableCells = [
        { id: "state", label: "State" },
        { id: "id", label: "Stream Id" },
        { id: "streamname", label: "Stream Name" },
        { id: "updatedAt", label: "Updated At" },
        { id: "tags", label: "Tags" },
        { id: "open", label: "" }
    ];

    const handleChangedPage = (event, newPage) => {
        props.changedPage(newPage);
    }

    const handleChangedRowsPerPage = (event) => {
        props.changedQuantity(+event.target.value);
    }

    return (
        <ContentContainer>
            <TableContainer className={classes.container}>
                <Table size="medium" stickyHeader className={classes.container}>
                    <TableHead>
                        <TableRow>
                            <Hidden smUp>
                                <TableCell className={classes.header}>
                                    <Typography className={classes.label} variant="body1">
                                        All Streams
                                    </Typography>
                                </TableCell>
                            </Hidden>
                            <Hidden xsDown>
                                {
                                    tableCells.map((cell, index) => (
                                        <TableCell key={index} className={classes.header}>
                                            <Typography className={classes.label} variant="body1">
                                                {cell.label}
                                            </Typography>
                                        </TableCell>
                                    ))
                                }
                            </Hidden>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Hidden smUp>
                            {
                                streamlist.slice(props.page * props.quantity, props.page * props.quantity + props.quantity).map((stream, id) => (
                                    <MobilePreviewItem key={id} stream={stream} />
                                ))
                            }
                            {
                                emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )
                            }
                        </Hidden>
                        <Hidden xsDown>
                            {
                                streamlist.slice(props.page * props.quantity, props.page * props.quantity + props.quantity).map((stream, id) => (
                                    <StreamPreviewItem key={id} stream={stream} />
                                ))
                            }
                            {
                                emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )
                            }
                        </Hidden>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                className={classes.pagination}
                rowsPerPageOptions={[10, 25, 50, 100, 150]}
                count={props.count}
                rowsPerPage={props.quantity}
                page={props.page}
                onChangePage={handleChangedPage}
                onChangeRowsPerPage={handleChangedRowsPerPage}
            />
        </ContentContainer>
    );
}