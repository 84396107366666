import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '45%',
        },

        [theme.breakpoints.up('md')]: {
            width: 250,
        }
    },
}));

export default function Dropdown(props) {
    const classes = useStyle();
    const { title, identifier, value, values } = props.value;

    const updateDropdownValue = (event, el) => {
        props.onChange(event.target.value, identifier, el.props.index)
    }

    return (
        <div className={classes.root}>
            <div className={classes.dropdownWrapper}>
                <FormControl fullWidth>
                    <InputLabel color="secondary">{title}</InputLabel>
                    <Select
                        onChange={updateDropdownValue}
                        value={value}
                        className={classes.select}
                    >
                        {
                            values.map((singleValue, index) => (
                                <MenuItem
                                    key={`${title}-${singleValue}-${index}`}
                                    value={singleValue}
                                    index={index}
                                >
                                    {singleValue}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}