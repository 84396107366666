import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Collapse } from '@material-ui/core';

import content from '../../utilities/helper/content';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import StateSelection from './StateSelection';
import DateSelection from './DateSelection';
import AddTags from '../helper/AddTags';
import DividerTitle from '../global/DividerTitle';
import Tag from '../global/Tag';
import WhiteContainer from '../global/WhiteContainer';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        marginTop: theme.spacing(2)
    },
    filterWrapper: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('800')]: {
            flexDirection: 'row',
        },
    },
    filter: {
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up('800')]: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(0)
        },
    },
    resetFilter: {
        paddingTop: theme.spacing(0.5)
    },
    reset: {
        cursor: 'pointer'
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    button: {
        width: '100%'
    }
}))

export default function Filter(props) {
    const classes = useStyles();
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
    const [tags, setTags] = useState([]);
    const [addTags, setAddTags] = useState(false);
    const [updatedFilter, setUpdatedFilter] = useState(false);
    const [filter, setFilter] = useState({
        state: "",
        from: localStorage.getItem(localStorageHelper.BINTU_ORGA) ? JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)).created_at : new Date(Date.now()).toISOString(),
        until: moment(new Date(Date.now())).endOf('day').toISOString(),
        tags: []
    });

    const handleAddTagsClicked = () => {
        setAddTags(!addTags);
    }

    const fetchRecentTags = () => {
        let date = new Date();
        let today = moment(date).format("YYYY-MM-DD");
        let fiveDaysAgo = moment(date).subtract(5, 'days').format("YYYY-MM-DD")
        bintu.getRecentlyUsedTags(apikey, fiveDaysAgo, today,
            (success) => {
                setTags(success)
            }, (error) => {
                console.log("Could not fetch recent tags.")
            })
    }

    const deleteTag = (tag) => {
        let updatedTagArray = filter.tags.filter((singleTag) => singleTag !== tag);
        setFilter({
            ...filter,
            tags: updatedTagArray
        });
        setUpdatedFilter(true);
    }

    const addNewTag = (tag) => {
        let updatedTagArray = filter.tags.concat([tag]);
        setFilter({
            ...filter,
            tags: updatedTagArray
        });
        setUpdatedFilter(true);
    }

    const updateState = (state) => {
        setFilter({
            ...filter,
            state: state
        });
        setUpdatedFilter(true);
    }

    const updateDate = (date, identifier) => {
        setFilter({
            ...filter,
            [identifier]: date
        });
        setUpdatedFilter(true);
    }

    const handleClickedReset = () => {
        props.resetFilter({
            state: "",
            from: orga ? orga.created_at : new Date(Date.now()).toISOString(),
            until: moment(new Date(Date.now())).endOf('day').toISOString(),
            tags: []
        });
    }

    const resetDate = (id) => {
        let date = id === "from" ? orga ? orga.created_at : new Date(Date.now()).toISOString() : new Date(Date.now()).toISOString();
        setFilter({
            ...filter,
            [id]: date
        });
        setUpdatedFilter(true);
    }

    useEffect(() => {
        if (updatedFilter) {
            props.settedFilter(filter)
            setUpdatedFilter(false);
        }
        if (filter.tags.length > 0) {
            setAddTags(true);
        }
        window.history.pushState({}, "", `stream?from=${filter.from}&until=${filter.until}${filter.state !== "" ? `&state=${filter.state}` : ""}${filter.tags.length > 0 ? `&tags=${filter.tags.join(",")}` : ""}`);
    }, [filter])

    useEffect(() => {
        if (!props.reset) {
            setFilter({
                state: "",
                from: orga ? orga.created_at : new Date(Date.now()).toISOString(),
                until: moment(new Date(Date.now())).endOf('day').toISOString(),
                tags: []
            });
        }
    }, [props.reset])

    useEffect(() => {
        if (props.filter !== filter) {
            setFilter(props.filter);
        }
    }, [props.filter])

    useEffect(() => {
        fetchRecentTags();
    }, [])

    return (
        <div className={classes.root}>
            {/* <Title title="Apply Filter" /> */}
            <WhiteContainer>
                <div className={classes.filterWrapper}>
                    <div className={classes.filter}>
                        <StateSelection
                            setState={updateState}
                            state={props.reset ? props.filter.state : filter.state}
                        />
                    </div>
                    <div className={classes.filter}>
                        <DateSelection
                            date={props.reset ? props.filter : filter}
                            setDate={updateDate}
                            resetDate={resetDate}
                        />
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button
                            className={classes.button}
                            onClick={handleAddTagsClicked}
                            variant="outlined"
                            color="primary"
                        >
                            {content.filter.tags.button}
                        </Button>
                    </div>
                </div>
                <Collapse in={addTags}>
                    <AddTags
                        tags={filter.tags}
                        addNewTag={addNewTag}
                        deleteTag={deleteTag}
                    />
                    {
                        tags.map((tag, index) => (
                            <Tag
                                key={index}
                                tag={tag}
                                handleClicked={addNewTag}
                            />
                        ))
                    }
                </Collapse>
                <Collapse in={props.reset}>
                    <div className={classes.resetFilter}>
                        <Typography
                            className={classes.reset}
                            color="primary"
                            variant="subtitle2"
                            onClick={handleClickedReset}
                        >
                            {content.filter.reset}
                        </Typography>
                    </div>
                </Collapse>
            </WhiteContainer>
        </div>
    )
}