import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, TableRow, TableCell, IconButton } from '@material-ui/core';

import * as link from '../../utilities/helper/link-config';
import * as method from '../../utilities/helper/methods';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import StateSection from '../stream-overview/State';
import Tag from '../global/Tag';
import { OndemandVideo } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    date: {
        marginRight: theme.spacing(1),
    },
    stateDateWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1)
    },
    tags: {
        marginTop: theme.spacing(1)
    },
    infoWrapper: {
        margin: theme.spacing(0.4)
    },
    info: {
        marginBottom: theme.spacing(0.5)
    },
    value: {
        fontWeight: 500,
        lineHeight: 1,
        fontSize: 16
    },
    description: {
        color: theme.palette.text.secondary,
        lineHeight: 1
    },
    moreTags: {
        fontWeight: '700',
        lineHeight: 1,
        display: 'inline-block',
        marginLeft: theme.spacing(1)
    }
}))

export default function MobilePreviewItem(props) {
    const classes = useStyles();
    const [redirectRoute, setRedirectRoute] = useState(null);

    let stream = props.stream
    let streamInfo = {
        state: stream.state,
        id: stream.id,
        streamname: stream.ingest.rtmp.streamname,
        updatedAt: moment(stream.updatedAt),
        playout: stream.rtmpPlayoutCount,
        tags: stream.tags
    }

    const handleShowStreamClicked = () => {
        setRedirectRoute(
            <Redirect push to={`${link.STREAM}/${streamInfo.id}`} />
        );
    }

    const handleOpenPlayerClicked = (event) => {
        let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA))
        let apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
        let security = orga.secure ? method.GET_PLAYER_HASH(orga.hash, apikey) : false;
        window.open(`${link.PLAYOUT}/${streamInfo.id}${security ? `?security.expires=${security.expires}&security.options=${security.options}&security.tag=${security.tag}&security.token=${security.token}` : ""}`, "_blank")
    }

    return (
        <TableRow hover className={classes.root}>
            {redirectRoute}
            <TableCell className={classes.tableCell}>
                <div className={classes.stateDateWrapper}>
                    <StateSection state={streamInfo.state} />
                    <Typography color="textSecondary" variant="subtitle2" className={classes.date}>
                        {streamInfo.updatedAt.utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                    </Typography>
                    {
                        streamInfo.state === "live"
                            ?
                            <IconButton size="small" onClick={handleOpenPlayerClicked}>
                                <OndemandVideo color="primary" fontSize="inherit" />
                            </IconButton>
                            : null
                    }
                </div>
                <div className={classes.infoWrapper} onClick={handleShowStreamClicked}>
                    {
                        [
                            { description: "Stream Id", value: streamInfo.id },
                            { description: "Stream name", value: streamInfo.streamname }
                        ].map((info, index) => (
                            <div key={index} className={classes.info}>
                                <Typography key={index} className={classes.value} variant="body1">
                                    {info.value}
                                </Typography>
                                <Typography className={classes.description} variant="overline">
                                    {info.description}
                                </Typography>
                            </div>
                        ))
                    }
                </div>
                {
                    streamInfo.tags.length > 4
                        ?
                        <div className={classes.tags} onClick={handleShowStreamClicked}>
                            {
                                [
                                    streamInfo.tags[0],
                                    streamInfo.tags[1],
                                    streamInfo.tags[2],
                                    streamInfo.tags[3],
                                ].map((tag, index) => (
                                    <Tag
                                        tag={tag}
                                        key={index}
                                    />
                                ))
                            }
                            <Typography className={classes.moreTags} color="primary" onClick={handleShowStreamClicked}>
                                + {streamInfo.tags.length - 4} Tags
                            </Typography>
                        </div>
                        :
                        <div className={classes.tags} onClick={handleShowStreamClicked}>
                            {
                                streamInfo.tags.map((tag, index) => (
                                    <Tag
                                        tag={tag}
                                        key={index}
                                    />
                                ))
                            }
                        </div>
                }
            </TableCell>
        </TableRow>
    )
}