import React, { useEffect, useState, useContext } from 'react';

import clsx from 'clsx';

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import codeStyle from "react-syntax-highlighter/dist/esm/styles/prism/material-light";

import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import Context from '../../utilities/context/Context';
import content from '../../utilities/helper/content';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as type from '../../utilities/context/type';
import * as link from '../../utilities/helper/link-config';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';
import * as method from '../../utilities/helper/methods';

import CodeAccordion from '../stream-overview/Acoordion';
import JsonObject from '../stream-overview/JsonObject';
import CopyButton from '../global/CopyButton';
import Title from '../global/Title';
import QuickTokenCreator from '../token/QuickTokenCreator';
import WhiteContainer from '../global/WhiteContainer';
import DividerTitle from '../global/DividerTitle';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        marginTop: theme.spacing(2)
    },
    item: {
        marginTop: theme.spacing(2)
    },
    h5liveWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    text: {
        marginRight: theme.spacing(1)
    }
}))

const createNewEntry = (stream, index, security) => {
    return {
        index,
        h5live: {
            rtmp: {
                streamname: stream.playout.h5live[0].rtmp.streamname,
            },
            ...security && {
                security: {
                    token: security.token,
                    expires: security.expires,
                    tag: security.tag,
                    options: security.options
                }
            }
        }
    }
}

const createCodeSnippet = (streamlist, security) => {
    let entries = [];
    let urlParams = new URLSearchParams(window.location.search);
    let hasStartIndex = urlParams.has("startIndex") ? urlParams.get("startIndex") : false;

    for (let i = 0; i < streamlist.length; i++) {
        let token = security ? security[i] : security
        entries.push(createNewEntry(streamlist[i], i, token));
    }

    let config = {
        source: {
            defaults: {
                service: "bintu"
            },
            entries,
            ...hasStartIndex && { 
                options: {
                    adaption: {
                        rule: "deviationOfMean2"
                    },
                    startIndex: hasStartIndex,
                },
            },
        },
        playback: {
            autoplay: true,
            automute: true,
            muted: false,
        }
    };


    return `<div id="playerDiv"></div>
<script src="${link.NANOPLAYER_RELEASE_SRC}"></script>
<script>
var player;
var config = ${JSON.stringify(config, null, '\t')};
document.addEventListener('DOMContentLoaded', function () {
    player = new NanoPlayer("playerDiv");
    player.setup(config).then(function (config) {
        console.log("setup success");
        console.log("config: " + JSON.stringify(config, undefined, 4));
    }, function (error) {
        alert(error.message);
    });
});
</script>`
}


export default function H5LiveCodeSnippet(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);
    let streamlist = props.streams;
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
    const [enableSecure, setEnableSecure] = useState(false);
    const [codeSnippet, setCodeSnippet] = useState("");
    const [openSecure, setOpenSecure] = useState(false);
    const [createdSecure, setCreatedSecure] = useState(false);

    async function generateCodeSnippet(streams) {
        setCodeSnippet(createCodeSnippet(streams, false));
    }

    const handleOpenSecure = () => {
        if (createdSecure) setCreatedSecure(false)
        setOpenSecure(!openSecure);
    }

    const createSecureToken = (body) => {
        return new Promise((resolve, reject) => {
            bintu.createSecureToken(apikey, body,
                (success) => {
                    resolve(success)
                },
                (error) => {
                    dispatch({ type: type.SHOW_ERROR, error: { title: "An error occured", message: "Could not create secure playback token. Please try again." }, alert: "error" });
                });
        })
    }

    async function handleCreateSecureToken(setup, isForOrgaHash) {
        let secureTokenlist = [];
        let body = setup;
        if (isForOrgaHash) {
            body.orga = orga.hash;
            await createSecureToken(body).then((success) => {
                secureTokenlist.push(success.h5live.security);
            });
        }
        if (!isForOrgaHash) {
            for (var i = 0; i < streamlist.length; i++) {
                body.streamname = streamlist[i].playout.rtmp[0].streamname;
                await createSecureToken(body).then((success) => {
                    secureTokenlist.push(success.h5live.security);
                });
            }
        }
        setCodeSnippet(createCodeSnippet(streamlist, secureTokenlist));
        setCreatedSecure(true);
        setOpenSecure(false);
    }

    const checkCodeUpdate = () => {
        if (Array.isArray(props.security)) {
            if (props.security.length === props.streams.length) {
                setCodeSnippet(createCodeSnippet(props.streams, props.security));
            }
        }
        else {
            let security = new Array(props.streams.length).fill(props.security);
            setCodeSnippet(createCodeSnippet(props.streams, security));
        }
        if (!props.security) {
            let security = new Array(props.streams.length).fill(method.GET_PLAYER_HASH(orga.hash, apikey));
            setCodeSnippet(createCodeSnippet(props.streams, security));
        }
    }

    useEffect(() => {
        if (orga.secure) checkCodeUpdate();
    }, [props.security])


    useEffect(() => {
        if (props.streams !== undefined && props.streams.length > 0 && codeSnippet === "") {
            if (orga.secure) {
                checkCodeUpdate();
            }
            if (!orga.secure) {
                generateCodeSnippet(props.streams);
            }
        }
    }, [props.streams])

    useEffect(() => {
        setEnableSecure(orga ? orga.secure : false);
    }, [])

    return (
        <div className={clsx({
            [classes.root]: !props.withTitle
        })}>
            {
                props.withTitle
                    ?
                    <Title
                        title={content.codeSnippet.title}
                        button={enableSecure ? content.createQuickPlayoutToken.button : false}
                        startIcon={<Lock />}
                        clicked={handleOpenSecure}
                    />
                    : null
            }
            <Collapse in={openSecure}>
                <QuickTokenCreator
                    createToken={handleCreateSecureToken}
                />
            </Collapse>
            <Collapse in={createdSecure}>
                <WhiteContainer>
                    <DividerTitle title={content.createQuickPlayoutToken.success.title} />
                    <Typography className={classes.infoText} variant="subtitle1" color="textSecondary">
                        {content.createQuickPlayoutToken.success.underline}
                    </Typography>
                </WhiteContainer>
            </Collapse>
            <div className={clsx({
                [classes.root]: props.withTitle
            })}>
                <CodeAccordion title={`${enableSecure ? content.codeSnippet.accordion.secure : content.codeSnippet.accordion.nonSecure}`}>
                    <div className={classes.h5liveWrapper}>
                        <Typography className={classes.text} variant="body1" color="textSecondary">
                            {content.codeSnippet.underline}<br />
                            {createdSecure ? content.codeSnippet.secureSpecificToken : content.codeSnippet.missingToken}
                        </Typography>
                        <CopyButton
                            iconButton={false}
                            copy={codeSnippet}
                        />
                    </div>
                    <JsonObject>
                        <SyntaxHighlighter showLineNumbers language="html" style={codeStyle}>
                            {codeSnippet}
                        </SyntaxHighlighter>
                    </JsonObject>
                </CodeAccordion>
            </div>
        </div>
    )
}