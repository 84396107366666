import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0.5),
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}))

export default function SocialMedia(props) {
    const classes = useStyles();

    return (
        <IconButton className={classes.root} size={props.size} onClick={() => { window.open(props.link, "_blank") }}>
            {props.icon}
        </IconButton>
    )
}