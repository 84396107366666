import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, fade, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { ArrowRightAlt, Camera, Dashboard, ErrorOutline, Feedback, HourglassEmpty, KeyboardArrowDown, KeyboardArrowRight, MultilineChart, OpenInNew, PieChart, PieChartTwoTone, Timer, Warning } from '@material-ui/icons';

import * as link from '../../utilities/helper/link-config';

import world from '../../assets/world.jpeg';
import logo from '../../assets/orange-nsc.png';
import UserRoleIntroBanner from './UserRoleBanner';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        // backgroundImage: `linear-gradient(to bottom, ${theme.palette.info.light}, ${fade("#020305", 0.5)}),
        // url(${world})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: 'cover',
        // backgroundPosition: "center bottom",
        // borderRadius: theme.spacing(2),
        // padding: theme.spacing(3),
        marginTop: theme.spacing(1)
    },
    header: {
        color: theme.palette.common.white,
        fontWeight: 700,
    },
    background: {
        backgroundColor: "#001F3A",
        color: theme.palette.common.white
    },
    outline: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    box: {
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1.5),
        backgroundColor: fade(theme.palette.primary.main, 0.2)
    },
    avatar: { backgroundColor: "#001F3A" },
    div: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }
    // header: {
    //     color: theme.palette.common.white,
    //     fontWeight: 700,
    //     lineHeight: 1
    // },
    // overline: {
    //     color: theme.palette.common.white
    // },
    // underline: {
    //     color: theme.palette.common.white,
    //     [theme.breakpoints.up('md')]: {
    //         maxWidth: '50%'
    //     }
    // },
    // button: {
    //     marginTop: theme.spacing(1),
    //     marginRight: theme.spacing(1)
    // }
}))

export default function RedirectBanner(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [redirect, setRedirect] = useState(null)

    const handleOpenInNew = (openLink) => () => {
        window.open(openLink, '_blank');
    }

    const handleRedirect = (openLink) => () => {
        window.open(openLink, "_self")
    }

    useEffect(() => {

    }, [props])

    return (
        <Dialog open={open}>
            <DialogTitle className={classes.outline}>Upgrade to nanoStream Cloud Dashboard V4!</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    <b>As of March 1, 2024, Dashboard V2 will no longer be supported.</b> <br />
                    Switch to Dashboard V4 now and benefit from an enhanced, user-friendly interface and additional features!
                </Typography>
                <Box className={classes.box}>
                    <Typography variant="body1" color="primary">
                        <b>Discover the features and functions from V4</b>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Learn more about the features and functionalities we have implemented in V4 by visiting our <Link href={'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Overview'} target='_blank'>docs</Link>.
                        If you are struggeling or have any questions, please don't hesitate to contact our <Link href={link.CONTACT} target='_blank'>team</Link>. <br />
                        We look forward to assisting you!
                    </Typography>
                </Box>
                <UserRoleIntroBanner />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" endIcon={<ArrowRightAlt />} component="a" href={link.NEW_DASHBOARD}>
                    Switch to V4
                </Button>
                <Button variant="outlined" color="primary" autoFocus onClick={() => setOpen(false)}>
                    Stay here!
                </Button>
            </DialogActions>
        </Dialog >
    )
}