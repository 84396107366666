export const VIDEOBITRATE = {
    value: '1 Mbps',
    values: ["100 Kbps", "200 Kbps", "300 Kbps", "400 Kbps", "500 Kbps", "800 Kbps", "1 Mbps", "1.5 Mbps", "2 Mbps", "2.5 Mbps", "3 Mbps", "4 Mbps", "5 Mbps", "6 Mbps", "7.5 Mbps"]
};
export const AUDIOBITRATE = {
    value: '64 Kbps',
    values: ["8 Kbps", "16 Kbps", "32 Kbps", "56 Kbps", "64 Kbps", "96 Kbps", "128 Kbps", "256 Kbps"]
};

export const FRAMERATE = {
    value: 30,
    values: [3, 5, 6, 10, 15, 20, 25, 30]
};
export const RESOLUTION = {
    height: 360,
    width: 640,
    values: ['320x240', '640x360', '640x480', '800x600', '1280x720', '1920x1080']
};