import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

import * as link from '../utilities/helper/link-config';
import * as methods from '../utilities/helper/methods';

import PageHeader from '../components/global/PageHeader';
import content from '../utilities/helper/content';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    button: {
        marginRight: theme.spacing(1)
    }
}))

export default function Metrics(props) {
    const classes = useStyles();
    document.title = "Metrics"

    useEffect(() => {
        methods.SEND_GA_ANALYTICS(window.location.pathname);
    }, [])

    return (
        <div className={classes.root}>
            <Grid container>
                <PageHeader title={content.metrics.title} underline={content.metrics.underline} />
                <Button
                    className={classes.button}
                    color="primary"
                    // size="small"
                    variant="contained"
                    endIcon={<OpenInNew />}
                    onClick={() => { window.open(link.METRICS_LOGIN, "_blank") }}
                >
                    Login
                </Button>
                <Button
                    color="primary"
                    // size="small"
                    variant="contained"
                    endIcon={<OpenInNew />}
                    onClick={() => { window.open(link.METRICS_DOCS, "_blank") }}
                >
                    Learn more
                </Button>
            </Grid>
        </div>
    )
}