import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';

import ContentContainer from '../global/ContentContainer';
import RedirectLink from '../global/RedirectLink';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        margin: theme.spacing(0, 1),
        textAlign: 'left',
        [theme.breakpoints.up('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 300
        },
    },
    header: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    label: {
        fontWeight: 700,
        marginLeft: theme.spacing(0.4),
        lineHeight: 1
    },
    text: {
        marginTop: theme.spacing(0.5),
        lineHeight: 1.2
    }
}))

export default function QuickLink(props) {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <ContentContainer>
                <div className={classes.header}>
                    {props.icon}
                    <Typography className={classes.label} variant="h6">
                        {props.label}
                    </Typography>
                </div>
                <Typography className={classes.text} color="textSecondary" variant="body1" gutterBottom>
                    {props.text}
                </Typography>
                {
                    props.window
                        ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<ArrowRightAlt />}
                            onClick={() => { window.open(props.window, '_blank') }}
                        >
                            Go
                        </Button>
                        : null
                }
                {
                    props.link
                        ?
                        <RedirectLink to={props.link}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<ArrowRightAlt />}
                            >
                                Go
                            </Button>
                        </RedirectLink>
                        : null
                }
            </ContentContainer>
        </div>
    )
}