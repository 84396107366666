import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        overflow: 'scroll',
        maxWidth: '100%'
    },
    codeWrapper: {
        maxWidth: '100%'
    },
    code: {
        maxWidth: '100%',
        overflowWrap: 'break-word'
    },
    codeSnippet: {
        padding: theme.spacing(0.5, 2),
    },
    interactionHandler: {
        backgroundColor: theme.palette.primary.light,
        borderTopLeftRadius: theme.spacing(0.5),
        borderTopRightRadius: theme.spacing(0.5),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: theme.spacing(0.5, 1)
    },
    icon: {
        color: theme.palette.common.white
    }
}));

export default function JsonObject(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.codeSnippet}>
                <code className={classes.codeWrapper}>
                    <pre className={classes.code} id={"code-snippet"}>
                        {props.children}
                    </pre>
                </code>
            </div>
        </div>
    );
}
