let _ = require("lodash");
let moment = require("moment");
let bintu = require("../bintu/bintu-requests");
let lsHelper = require("../helper/localStorage-vars");

export const SEND_GA_ANALYTICS = (pathname) => {
    if (window.ga) {
        window.ga('send', {
            hitType: 'pageview',
            page: pathname
        });
    }
}

export const GET_PARAMS = () => {
    const params = new URLSearchParams(window.location.search);

    const obj = {};

    const setNestedValue = (key, value) => {
        return key.split('.').reduceRight(function (acc, key) {
            var newValue = {};
            newValue[key] = acc;
            return newValue;
        }, value)
    }
    for (const key of params.keys()) {
        let nestedObject = setNestedValue(key, params.get(key));
        if (nestedObject) {
            _.merge(obj, nestedObject)
        }
    }
    return obj;
}

export const GET_PLAYER_HASH = (orgaHash, apikey) => {
    let security = JSON.parse(localStorage.getItem(lsHelper.H5LIVE_HASH_SECURE));

    let date = new Date().getTime() / 1000;
    if (security !== null && security.expires > date) return security;
    if (security === null || security.expires < date) {
        let body = {
            tag: "nanostream-cloud-dashboard-player-token",
            expires: Math.round(moment(new Date(Date.now())).add(24, 'hours') / 1000),
            orga: orgaHash,
        }
        bintu.createSecureToken(apikey, body,
            (success) => {
                localStorage.setItem(lsHelper.H5LIVE_HASH_SECURE, JSON.stringify(success.h5live.security))
                return success.h5live.security;
            }, (error) => {
                console.log(error)
            })
    }
}

export const RESOLVE_PLAYER_TOKEN_OPTIONS = (options) => {
    const optionList = {
        none: 0,
        expires: 1,
        ip: 2,
        referer: 4,
        tag: 8,
        orga: 16,
        options: 32
    };
    let x = (options & optionList.expires);

    return x;
}