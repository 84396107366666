import React, { useState } from 'react';
import clsx from 'clsx'

import moment from 'moment';

import content from '../../utilities/helper/content';
import * as link from '../../utilities/helper/link-config';

import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Icon, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';

import CopyButton from '../global/CopyButton';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    label: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginRight: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    header: {
        background: theme.palette.grey[100],
        padding: theme.spacing(0.5, 0),
    },
    hover: {
        cursor: 'pointer'
    },
    copy: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tokenWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    redeemed: {
        fontWeight: 900,
        marginRight: theme.spacing(1)
    },
    email: {
        color: theme.palette.primary.main,
        fontWeight: 400
    },
    cell: {
        padding: theme.spacing(0.5, 0)
    },
    button: {
        marginBottom: theme.spacing(2)
    },
    infoWrapper: {
        marginTop: theme.spacing(0.5),
    },
    sortlabel: {
    },
    activeIcon: {
        '& path': {
            fill: theme.palette.primary.main
        }
    }
}));

export default function InviteTokenList(props) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('redeemed');

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const sortTable = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);

    }

    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const openInNewTab = (playout) => (event) => {
        event.preventDefault();
        window.open(playout, '_blank')
    }

    return (
        <ContentContainer>
            {
                props.token === []
                    ?
                    <Typography variant="button" color="textSecondary">
                        {content.inviteToken.list.noInviteToken}
                    </Typography>
                    :
                    <TableContainer className={classes.container}>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <Hidden smUp>
                                        <TableCell className={classes.header}>
                                            <Typography className={classes.label} variant="body1" onClick={handleRequestSort("redeemed")}>
                                                {content.inviteToken.list.title}
                                            <TableSortLabel
                                                    classes={{
                                                        root: classes.sortlabel,
                                                        icon: classes.icon,
                                                        active: classes.activeIcon
                                                    }}
                                                    active={orderBy === "redeemed"}
                                                    direction={order}
                                                />
                                            </Typography>
                                        </TableCell>
                                    </Hidden>
                                    <Hidden xsDown>
                                        {
                                            [
                                                { id: "token", label: "Token", sort: false },
                                                { id: "redeemed", label: "Redeemed", sort: true },
                                                { id: "email", label: "Email", sort: true },
                                                { id: "url", label: "Invite Link", sort: false },
                                            ].map((headCell, index) => (
                                                <TableCell
                                                    key={index}
                                                    className={
                                                        clsx(classes.header, {
                                                            [classes.hover]: headCell.sort,
                                                        })}
                                                    onClick={handleRequestSort(headCell.id)}
                                                >
                                                    {
                                                        headCell.sort
                                                            ?
                                                            <Tooltip title={`Sort by ${headCell.label}`}>
                                                                <Typography className={classes.label} variant="body1">
                                                                    {headCell.label}
                                                                    {
                                                                        headCell.sort
                                                                            ?
                                                                            <TableSortLabel
                                                                                classes={{
                                                                                    root: classes.sortlabel,
                                                                                    icon: classes.icon,
                                                                                    active: classes.activeIcon
                                                                                }}
                                                                                active={orderBy === headCell.id}
                                                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                                            />
                                                                            : null
                                                                    }
                                                                </Typography>
                                                            </Tooltip>
                                                            :
                                                            <Typography className={classes.label} variant="body1">
                                                                {headCell.label}
                                                            </Typography>
                                                    }
                                                </TableCell>
                                            ))
                                        }
                                    </Hidden>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(sortTable(props.token, getComparator(order, orderBy))).map((token) => (
                                    <TableRow hover key={token.email}>
                                        <Hidden smUp>
                                            <TableCell className={classes.cell}>
                                                <div className={classes.tokenWrapper}>
                                                    {
                                                        token.redeemed
                                                            ? null
                                                            :
                                                            <Typography color="textSecondary" variant="subtitle2" className={classes.redeemed}>
                                                                Not
                                                            </Typography>
                                                    }
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Redeemed
                                                    </Typography>
                                                </div>
                                                <div className={classes.infoWrapper}>
                                                    <Typography className={classes.email} variant="subtitle2">
                                                        {`${link.URL}${link.INVITE_TOKEN}/${token.token}`}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                        </Hidden>
                                        <Hidden xsDown>
                                            <TableCell className={classes.cell}>
                                                <Typography className={classes.email} variant="subtitle2">
                                                    {token.token}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {
                                                        token.redeemed
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography variant="subtitle2">
                                                    {token.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={clsx(classes.cell, classes.copy)} >
                                                <Typography variant="subtitle2">
                                                    {
                                                        <Link
                                                            color="primary"
                                                            underline="always"
                                                            href={`${link.URL}${link.INVITE_TOKEN}/${token.token}`}
                                                            onClick={openInNewTab(`${link.URL}${link.INVITE_TOKEN}/${token.token}`)}
                                                        >
                                                            {`${link.URL}${link.INVITE_TOKEN}/${token.token}`}
                                                        </Link>
                                                    }
                                                </Typography>
                                                <CopyButton iconButton={true} copy={`${link.URL}${link.INVITE_TOKEN}/${token.token}`} />
                                            </TableCell>
                                        </Hidden>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </ContentContainer >
    );
}
