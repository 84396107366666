import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';

import * as webcaster from '../../utilities/helper/webcaster-helper';
import content from '../../utilities/helper/content';

import DividerTitle from '../global/DividerTitle';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        marginTop: theme.spacing(2)
    },
    dropdownRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    button: {
        marginTop: theme.spacing(1)
    },
}))

export default function AddOpcode(props) {
    const classes = useStyles();
    const [opcode, setOpcode] = useState("none");
    const [values] = useState([
        { title: "None", id: "none" },
        // { title: "VOD Recording", id: "rec" },
        { title: "Thumbnails", id: "thumbs" },
        // { title: "VOD Recording + Thumbnails", id: "rec+thumbs" },
    ])

    const updateDropdownValue = (event) => {
        setOpcode(event.target.value);
        if (props.submit) return;
        props.updateSetup(event.target.value);
    }

    const submit = () => {
        props.updateSetup(opcode);
    }

    useEffect(() => {
        props.opcode === null ? setOpcode("none") : setOpcode(props.opcode)
    }, [props.opcode])

    return (
        <Grid className={classes.root} item xs={12}>
            <ContentContainer>
                <DividerTitle title={"Choose the live processing for your stream"} />
                <div className={classes.dropdownRow}>
                    <FormControl fullWidth>
                        <InputLabel color="secondary">{"Apply live processing"}</InputLabel>
                        <Select
                            onChange={updateDropdownValue}
                            value={opcode}
                            className={classes.select}
                        >
                            {
                                values.map((singleValue, index) => (
                                    <MenuItem
                                        key={`${singleValue.id}`}
                                        value={singleValue.id}
                                        index={index}
                                    >
                                        {singleValue.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                {
                    props.submit
                        ?
                        <div>
                            <Button
                                className={classes.button}
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={submit}
                            >
                                {content.updateOpcode.submit}
                            </Button>
                        </div>
                        : null
                }
            </ContentContainer>
        </Grid>
    )
}