import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

import DividerTitle from '../global/DividerTitle';
import content from '../../utilities/helper/content';
import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        }
    },
    button: {
        margin: theme.spacing(0.5, 1, 0, 0),
    },
    underline: {
        marginBottom: theme.spacing(1.5),
        lineHeight: 1.2
    },
}));

export default function DeleteUser(props) {
    const classes = useStyles();

    return (
        <ContentContainer>
            <DividerTitle title={`${content.organisation.user.delete.title}: ${props.user.email}?`} />
            <Typography variant="body1" className={classes.underline} color="textSecondary">
                {content.organisation.user.delete.description}
            </Typography>
            <div className={classes.content}>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={props.close}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.button}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={props.deleteUser}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </ContentContainer>
    );
}
