import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import Context from '../../utilities/context/Context';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as reducer from '../../utilities/context/reducer';
import * as type from '../../utilities/context/type';
import * as link from '../../utilities/helper/link-config';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import SpinningLoadingLogo from '../utilities/SpinningLoadingLogo';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        marginTop: theme.spacing(2)
    },
    webcasterWrapper: {
        [theme.breakpoints.up('xs')]: {
            maxWidth: '500px',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '900px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '800px',
        }
        // width: '100%',
        // maxWidth: '100%'
        // margin: '0 auto',
    }
}));

export default function NanoStreamWebcaster(props) {
    const classes = useStyles();
    let apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const streams = props.streams;

    async function createConfig() {

        let identifier = 'nanostream-webcaster';
        let customConfig = {
            nanostream: {
                apikey: apikey
            },
            audio: props.audio,
            video: props.video,
            playback: {
                url: '',
                ingest: {
                    rtmp: {
                        url: streams[0].data.transcoding ? (streams[0].data.ingest.rtmp.url).replace("stream", "vtrans") : streams[0].data.ingest.rtmp.url,
                        streamname: streams[0].data.ingest.rtmp.streamname
                    },
                    streamid: ''
                }
            },
            style: {
                components: {
                    stats: {
                        webcaster: true
                    },
                    share: {
                        disable: true
                    },
                    settings: {
                        broadcast: false,
                        transcoding: false,
                        share: false
                    }
                }
            }
        };
        
        new window.initWebcaster(customConfig, identifier);
    }

    useEffect(() => {
        if (window.initWebcaster && streams.length > 0) {
            createConfig();
        }
    }, [props.streams])

    useEffect(() => {
        return () => {
            if (window.unmountWebcaster) {
                window.unmountWebcaster();
            }
        }
    }, [])



    return (
        <div className={classes.root}>
            <div className={classes.webcasterWrapper}>
                <div className={classes.webcaster} id="nanostream-webcaster"></div>
            </div>
        </div>
    )
}
