import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        lineHeight: 1.1,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
}))

export default function Title(props) {
    const classes = useStyles();

    const handleClickedTitleButton = () => {
        props.clicked();
    }

    return (
        <Grid item xs={12} className={classes.root}>
            <Typography variant="h5" className={classes.title}>
                {props.title}
            </Typography>
            <div className={classes.buttonWrapper}>
                {
                    props.button
                        ?
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            startIcon={props.startIcon}
                            endIcon={props.endIcon}
                            onClick={handleClickedTitleButton}
                        >
                            {props.button}
                        </Button>
                        : null
                }
            </div>
        </Grid>
    )
}