import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import { Mail, Lock, ArrowForward, VpnKey } from '@material-ui/icons'

import Context from '../../utilities/context/Context';
import * as type from '../../utilities/context/type';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import OutlinedTextfield from './OutlinedTextfield';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        borderRadius: theme.spacing(0.5)
    },
    forgotPassword: {
        margin: theme.spacing(1),
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

export default function SignUp(props) {
    const classes = useStyles();
    const { dispatch } = useContext(Context);
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [textfields, setTextfields] = useState({
        email: {
            value: "",
            id: "email",
            autoComplete: "email",
            label: "E-Mail",
            type: "text",
            error: false,
            icon: <Mail color="primary" />
        },
        inviteToken: {
            value: props.inviteToken,
            id: "inviteToken",
            autoComplete: "token",
            label: "Invite token",
            type: "text",
            error: false,
            disabled: true,
            icon: <VpnKey color="primary" />
        },
        password: {
            value: "",
            id: "password",
            autoComplete: "new-password",
            label: "Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />
        },
        confirmPassword: {
            value: "",
            id: "confirmPassword",
            autoComplete: "new-password",
            label: "Confirm Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />
        }
    })

    const setValue = (value, property) => {
        let settedValue = value;
        if (property === textfields.email.id) {
            settedValue = settedValue.toLowerCase();
        }
        setTextfields({
            ...textfields,
            [property]: {
                ...textfields[property],
                value: settedValue
            }
        });
    }

    const handleSubmit = (event, property) => {
        if (property === textfields.confirmPassword.id) {
            createUser(event);
        }
    }

    const checkIfValid = (value, property) => {
        switch (property) {
            case "email":
                setTextfields({
                    ...textfields,
                    [property]: {
                        ...textfields[property],
                        error: !mailRegex.test(value)
                    }
                });
                break;
            case "confirmPassword":
                let valid = textfields.confirmPassword.value === textfields.password.value
                setTextfields({
                    ...textfields,
                    password: {
                        ...textfields.password,
                        error: !valid
                    },
                    confirmPassword: {
                        ...textfields.confirmPassword,
                        error: !valid
                    }
                });
                break;
            default: break;
        }
    }

    const createUser = (event) => {
        event.preventDefault();

        let mailInvalid = !mailRegex.test(textfields.email.value);
        let passwordInvalid = textfields.password.value === "";
        let confirmPasswordInvalid = textfields.password.value !== textfields.confirmPassword.value || textfields.confirmPassword.value === "";

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            password: {
                ...textfields.password,
                error: passwordInvalid
            },
            confirmPassword: {
                ...textfields.confirmPassword,
                error: confirmPasswordInvalid
            }
        });

        if (mailInvalid || passwordInvalid || confirmPasswordInvalid) return;

        bintu.redeemToken(props.inviteToken, textfields.email.value, textfields.password.value,
            (response) => {
                console.log(response)
                localStorage.setItem(localStorageHelper.AUTHORIZED, true);
                localStorage.setItem(localStorageHelper.BINTU_APIKEY, response.apikey.key)
                localStorage.setItem(localStorageHelper.BINTU_MAIL, textfields.email.value);
                localStorage.setItem(localStorageHelper.BINTU_ORGA, JSON.stringify(response.organisation));
                props.signin();
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    return (
        <div className={classes.root}>
            <form autoComplete>
                <div>
                    {
                        [
                            textfields.inviteToken,
                            textfields.email,
                            textfields.password,
                            textfields.confirmPassword
                        ].map((textfield, index) => (
                            <OutlinedTextfield
                                key={`${textfield.id}-${index}`}
                                id={`${textfield.id}-${index}`}
                                autoComplete={textfield.autoComplete}
                                disabled={textfield.disabled}
                                label={textfield.label}
                                type={textfield.type}
                                error={textfields[textfield.id].error}
                                icon={textfield.icon}
                                value={textfield.value}
                                changed={(event) => { setValue(event.target.value, textfield.id) }}
                                blured={(event) => { checkIfValid(event.target.value, textfield.id) }}
                                submit={(event) => { handleSubmit(event, textfield.id) }}
                            />
                        ))
                    }
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={createUser}
                    >
                        Sign Up
                </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={props.signin}
                    >
                        Login
                </Button>
                </div>
            </form>
        </div>
    )
}