import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Context from '../../utilities/context/Context';
import content from '../../utilities/helper/content';
import * as bintu from '../../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../../utilities/helper/localStorage-vars';
import * as type from '../../utilities/context/type';

import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    orgaInfo: {
        fontWeight: 700,
        color: theme.palette.primary.light,
        lineHeight: 1,
        fontSize: theme.typography.h6.fontSize
    },
    underline: {
        lineHeight: 1
    },
    infoWrapper: {
        '&:first-of-type': {
            marginBottom: theme.spacing(2)
        }
    }
}))

export default function OrgaInfo(props) {
    const classes = useStyles();
    const { dispatch } = useContext(Context);
    const [apikey] = useState(localStorage.getItem(localStorageHelper.BINTU_APIKEY));
    const [orga, setOrga] = useState({})

    useEffect(() => {
        bintu.getOrga(apikey,
            (success) => {
                setOrga(success)
                localStorage.setItem(localStorageHelper.BINTU_ORGA, JSON.stringify(success));
            },
            (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            });
    }, [])

    return (
        <ContentContainer>
            <div className={classes.infoWrapper}>
                <Typography variant="body1" className={classes.orgaInfo}>
                    {orga.name}
                </Typography>
                <Typography className={classes.underline} variant="overline" color="textSecondary">
                    {content.organisation.info.name}
                </Typography>
            </div>
            <div className={classes.infoWrapper}>
                <Typography variant="body1" className={classes.orgaInfo}>
                    {orga.hash}
                </Typography>
                <Typography className={classes.underline} variant="overline" color="textSecondary">
                    {content.organisation.info.hash}
                </Typography>
            </div>
        </ContentContainer>
    )
}