import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { VideoLibrary, Lock, Equalizer, Movie, Videocam, Storage, Duo, FeaturedPlayList } from '@material-ui/icons';

import * as localStorageHelper from '../../utilities/helper/localStorage-vars';

import EnabledOptions from './EnabledOptions';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    options: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    icon: {
        fontSize: '20px',
    }
}))

export default function PackageOverview(props) {
    const classes = useStyles();
    const [orga, setOrga] = useState(
        localStorage.getItem(localStorageHelper.BINTU_ORGA)
            ? JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA))
            : {
                h5live: false,
                hash: "",
                hls: false,
                isEnabled: false,
                meetEnabled: false,
                metadata: false,
                metrics: "",
                secure: false,
                sla: "",
                transcoding: false,
                trial: false,
                vod: false,
                webrtc: false
            });

    useEffect(() => {
        if (localStorage.getItem(localStorageHelper.BINTU_ORGA)) {
            setOrga(JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA)));
        }
    }, [])

    return (
        <Grid item xs={12} className={classes.root}>
            <div className={classes.options}>
                {
                    [
                        { label: "Webcaster", value: orga.webrtc, icon: <Videocam className={classes.icon} /> },
                        { label: "H5Live", value: orga.h5live, icon: <VideoLibrary className={classes.icon} /> },
                        // { label: "Legacy HLS", value: orga.hls, icon: <Storage className={classes.icon} /> },
                        { label: "Secure", value: orga.secure, icon: <Lock className={classes.icon} /> },
                        { label: "Transcoding", value: orga.transcoding, icon: <Equalizer className={classes.icon} /> },
                        { label: "VOD", value: orga.vod, icon: <Movie className={classes.icon} /> },
                        { label: "nanoMeet", value: orga.meetEnabled, icon: <Duo className={classes.icon} /> },
                        { label: "Metadata", value: orga.metadata, icon: <FeaturedPlayList className={classes.icon}/> }
                    ].map((item, index) => (
                        <EnabledOptions key={index} item={item} />
                    ))
                }
            </div>
        </Grid>
    )
}