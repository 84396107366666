import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Slider, Button, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@material-ui/core';

import Context from '../../utilities/context/Context';
import * as reducer from '../../utilities/context/reducer';
import * as type from '../../utilities/context/type';
import * as link from '../../utilities/helper/link-config';

import ContentContainer from '../global/ContentContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    sliderWrapper: {
        [theme.breakpoints.up('xs')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 280
        }
    },
    radioGroup: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        }
    },
    label: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    qualityLabel: {
    },
    profile: {
        color: theme.palette.text.secondary
    }
}))

export default function SwitchStream(props) {
    const classes = useStyles();
    const [streams, setStreams] = useState([]);
    const [quality, setQuality] = useState("");
    const [adaptionRule, setAdaptionRule] = useState(true);
    const [transcodingOptions, setTranscodingOptions] = useState({});
    const [rules] = useState([
        { label: "Auto", useAdaption: true },
        { label: "Manual", useAdaption: false }
    ])

    const updateAdaption = (event) => {
        let updatedRule = event.target.value === "true" ? true : false
        setAdaptionRule(updatedRule);
        if (window.setAdaption) {
            window.setAdaption(updatedRule)
        }
    }

    const updateQuality = (event) => {
        if (adaptionRule) setAdaptionRule(false);
        let streamname = event.target.value
        setQuality(streamname);
        let index = streams.findIndex((stream) => stream.playout.rtmp[0].streamname === streamname)
        if (window.switchStream) {
            window.switchStream(index)
        }
    }

    window.getSwitchedStream = (streamname) => {
        console.log(streamname)
        setQuality(streamname)
    }

    const getTranscodedProfile = (index) => {
        let profile = `Profile ${index}`
        if (Object.keys(transcodingOptions).length > 0) {
            if (transcodingOptions.hasOwnProperty(`vtrans${index}`)) {
                let opt = transcodingOptions[`vtrans${index}`];
                profile = ` ${opt.bitrate === undefined && opt.fps === undefined && opt.height === undefined && opt.width === undefined ? `(Profile ${index})` : `(${opt.width === undefined || opt.height === undefined ? "" : `${opt.width}x${opt.height}`} ${opt.bitrate === undefined ? "" : `${opt.bitrate}K`} ${opt.fps === undefined ? "" : `${opt.fps} fps`})`}`
            }
        }
        return profile
    }


    useEffect(() => {
        if (props.streams.length > 0) {
            setStreams(props.streams);
            if (props.streams[props.startIndex].playout.rtmp[0] !== undefined) {
                setQuality(props.streams[props.startIndex].playout.rtmp[0].streamname)
            }
        }
    }, [props.streams, props.startIndex])

    useEffect(() => {
        if (props.transcodingOptions) {
            setTranscodingOptions(props.transcodingOptions)
        }
    }, [props.transcodingOptions])


    return (
        <div>
            {
                streams[0] !== undefined && streams[0].playout.rtmp[0] !== undefined
                    ?
                    <ContentContainer>
                        <div>
                            <FormControl>
                                <Typography className={classes.label} variant="h6">
                                    Switch Rule
                                </Typography>
                                <RadioGroup
                                    className={classes.radioGroup}
                                    name="adaptionRule"
                                    value={adaptionRule}
                                    onChange={updateAdaption}
                                >
                                    {
                                        rules.map((rule, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={rule.useAdaption}
                                                control={<Radio />}
                                                label={rule.label}
                                            />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl>
                                <Typography className={classes.label} variant="h6">
                                    Switch Quality
                                </Typography>
                                <RadioGroup
                                    className={classes.radioGroup}
                                    name="adaptionQualities"
                                    value={quality}
                                    onChange={updateQuality}
                                >
                                    {
                                        streams.map((stream, index) => (
                                            <FormControlLabel
                                                key={stream.playout.rtmp[0].streamname}
                                                value={stream.playout.rtmp[0].streamname}
                                                control={<Radio />}
                                                label={
                                                    <Typography className={classes.qualityLabel} variant="body1">
                                                        {stream.playout.rtmp[0].streamname}
                                                        <span className={classes.profile}>
                                                            {index === 0 ? " (Highest)" : getTranscodedProfile(index)}
                                                        </span>
                                                    </Typography>
                                                }
                                            />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </ContentContainer>
                    : null
            }
        </div>
    )
}