import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Collapse, Typography, TextField } from '@material-ui/core';
import { Edit, Delete, Lock, Camera, FeaturedPlayList, Queue } from '@material-ui/icons';

import Context from '../utilities/context/Context';
import * as type from '../utilities/context/type';
import * as bintu from '../utilities/bintu/bintu-requests';
import * as localStorageHelper from '../utilities/helper/localStorage-vars';
import * as link from '../utilities/helper/link-config';
import * as method from '../utilities/helper/methods';

import SpinningLoadingLogo from '../components/utilities/SpinningLoadingLogo';
import PageHeader from '../components/global/PageHeader';
import StreamOverview from '../components/stream-overview/StreamOverview';
import AddTags from '../components/helper/AddTags';
import ContentContainer from '../components/global/ContentContainer';
import DividerTitle from '../components/global/DividerTitle';
import CouldNotFind from '../components/global/CouldNotFind';
import content from '../utilities/helper/content';
import AddOpcode from '../components/helper/AddOpcode';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        minHeight: 'calc(100vh - 64px)',
        flexGrow: 1,
        maxWidth: '100%'
    },
    item: {
        marginTop: theme.spacing(2)
    },
    h5liveWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontWeight: 900,
        lineHeight: 1,
        marginRight: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(0.5, 1, 0, 0),
    },
    collapseButton: {
        marginTop: theme.spacing(1)
    },
    underline: {
        marginBottom: theme.spacing(1.5),
        lineHeight: 1.2
    },
    accordion: {
        marginTop: theme.spacing(1)
    },
    buttonWrapper: {
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('500')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        }
    },
}))

export default function Stream(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);
    const apikey = localStorage.getItem(localStorageHelper.BINTU_APIKEY);
    const orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA));
    const [loading, setLoading] = useState(true);
    const [redirectRoute, setRedirectRoute] = useState(null);
    const [stream, setStream] = useState({});
    const [timecode, setTimecode] = useState(false);
    const [enableSecure, setEnableSecure] = useState(false);
    const [enableMetadata, setEnableMetadata] = useState(false);
    const [enableVod, setEnableVod] = useState(false);
    const [tags, setTags] = useState([]);
    const [interval, setInterval] = useState(500);
    const [opcode, setOpcode] = useState(null);
    const [openTags, setOpenTags] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openInterval, setOpenInterval] = useState(false);
    const [openOpcode, setOpenOpcode] = useState(false);

    let streamid = props.match.params.id;
    document.title = `${streamid ? `Stream Overview: ${streamid}` : "Stream Overview"}`;

    const getStream = (streamid) => {
        bintu.getStreamv2(apikey, streamid,
            (success) => {
                let isPassthrough = success.streamgroup === streamid;
                setEnableVod(isPassthrough);
                setEnableMetadata(isPassthrough);
                setLoading(false);
                setTags(success.tags);
                let vodUrls = success.playout.web.filter((url) => url.type === "vod");
                let liveUrls = success.playout.web.filter((url) => url.type === "live");

                let security = orga.secure ? method.GET_PLAYER_HASH(orga.hash, apikey) : false;

                setStream({
                    identifier: "main",
                    data: success,
                    vodUrls: vodUrls,
                    liveUrls: [{ type: "live", url: `${link.URL}${link.PLAYOUT}/${streamid}${security ? `?security.expires=${security.expires}&security.options=${security.options}&security.tag=${security.tag}&security.token=${security.token}` : ""}` }]
                });
                setInterval(success.timecode_interval)
                setTimecode(success.timecode);
                setOpcode(success.opcode);
            }, (error) => {
                setLoading(false);
                setStream(false);
            });
        setLoading(false);
    }


    const updateStreamTags = () => {
        bintu.updateStreamTags(apikey, streamid, tags,
            (success) => {
                getStream(streamid);
                setOpenTags(false);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const deleteTag = (tag) => {
        let updatedTagArray = tags.filter((singleTag) => singleTag !== tag);
        setTags(updatedTagArray);
    }

    const addNewTag = (tag) => {
        if (tags.includes(tag)) return
        let updatedTagArray = tags.concat([tag]);
        setTags(updatedTagArray);
    }

    const deleteStream = () => {
        bintu.deleteStream(apikey, streamid,
            (success) => {
                setRedirectRoute(<Redirect push to={`${link.STREAMS}`} />);
                dispatch({ type: type.SHOW_ERROR, error: { title: "Success!", message: `You deleted the stream ${streamid} successfully.` }, alert: "success" });
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const updateInterval = () => {
        bintu.setTimecodeInterval(apikey, !(Number(interval) === 0), Number(interval), streamid,
            (success) => {
                getStream(streamid);
                setOpenInterval(false);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const updateOpcode = (opcode) => {
        bintu.setOpCode(apikey, streamid, opcode,
            (success) => {
                setOpcode(opcode);
                getStream(streamid);
                setOpenOpcode(false);
            }, (error) => {
                dispatch({ type: type.SHOW_ERROR, error: error, alert: "error" });
            })
    }

    const addTimecode = (event) => {
        setTimecode(true);
        setInterval(event.target.value)
    }

    const bluredTimecode = () => {
        if (Number(interval) === 0) {
            setTimecode(false);
            return;
        }
        if (interval === "" || Number(interval) < 500 || Number(interval) > 3600000 || /[^0-9]+/.test(interval)) {
            setInterval(500);
        }
    }


    const editTags = () => {
        setOpenTags(!openTags);
        setOpenDelete(false);
        setOpenInterval(false);
        setOpenOpcode(false);
    }

    const openDeleteStream = () => {
        setOpenDelete(!openDelete);
        setOpenTags(false);
        setOpenInterval(false);
        setOpenOpcode(false);
    }

    const editInterval = () => {
        setOpenInterval(!openInterval);
        setOpenDelete(false);
        setOpenTags(false);
        setOpenOpcode(false);
    }


    const openWebcaster = () => {
        setRedirectRoute(<Redirect push to={`${link.WEBCASTER}/${streamid}`} />)
    }

    const editOpcode = () => {
        setOpenOpcode(!openOpcode);
        setOpenDelete(false);
        setOpenTags(false);
        setOpenInterval(false);
    }


    useEffect(() => {
        setLoading(true);
        let orga = JSON.parse(localStorage.getItem(localStorageHelper.BINTU_ORGA))
        setEnableSecure(orga ? orga.secure : false)
        setEnableMetadata(orga ? orga.metadata : false)
        setEnableVod(orga ? orga.vod : false)
        streamid ? getStream(streamid) : setStream(false);
    }, [])

    useEffect(() => {
        method.SEND_GA_ANALYTICS("/stream");
    }, [])

    return (
        <div className={classes.root}>
            {redirectRoute}
            {
                loading
                    ? <SpinningLoadingLogo />
                    : null
            }
            {
                stream
                    ?
                    <Grid container>
                        <Grid item xs={12}>
                            <PageHeader title={content.stream.title} />
                            <div className={classes.buttonWrapper}>
                                <Button
                                    className={classes.button}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    startIcon={<Edit />}
                                    onClick={editTags}
                                >
                                    {content.updateTags.button}
                                </Button>
                                {
                                    enableMetadata
                                        ?
                                        <Button
                                            className={classes.button}
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            startIcon={<FeaturedPlayList />}
                                            onClick={editInterval}
                                        >
                                            {content.updateInterval.button}
                                        </Button>
                                        : null
                                }
                                {
                                    enableVod
                                        ?
                                        <Button
                                            className={classes.button}
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            startIcon={<Queue />}
                                            onClick={editOpcode}
                                        >
                                            {content.updateOpcode.button}
                                        </Button>
                                        : null

                                }
                                <Button
                                    className={classes.button}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    startIcon={<Camera />}
                                    onClick={openWebcaster}
                                >
                                    {content.startWebcaster.button}
                                </Button>
                                <Button
                                    className={classes.button}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    startIcon={<Delete />}
                                    onClick={openDeleteStream}
                                >
                                    {content.deleteStream.button}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openTags}>
                                <ContentContainer>
                                    <DividerTitle title={content.updateTags.title} />
                                    <AddTags
                                        tags={tags}
                                        addNewTag={addNewTag}
                                        deleteTag={deleteTag}
                                    />
                                    <Button
                                        className={classes.collapseButton}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={updateStreamTags}
                                    >
                                        {content.updateTags.submit}
                                    </Button>
                                </ContentContainer>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openInterval}>
                                <ContentContainer>
                                    <DividerTitle title="Choose timecode injection period." />
                                    <Typography variant="body1" className={classes.underline} color="textSecondary">
                                        You can set the time injection interval between 500 ms and 3600000 ms (1 hour), recommended: 1000-5000 ms.
                                        Set to 0 to remove time injection.
                                    </Typography>
                                    <TextField
                                        label="Timecode (ms)"
                                        value={interval}
                                        onChange={addTimecode}
                                        onBlur={bluredTimecode}
                                    />
                                    <div>
                                        <Button
                                            className={classes.collapseButton}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={updateInterval}
                                        >
                                            {content.updateInterval.submit}
                                        </Button>
                                    </div>
                                </ContentContainer>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openOpcode}>
                                <AddOpcode
                                    opcode={opcode}
                                    updateSetup={updateOpcode}
                                    submit
                                />
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openDelete}>
                                <ContentContainer>
                                    <DividerTitle title={content.deleteStream.title} />
                                    <Typography variant="body1" className={classes.underline} color="textSecondary">
                                        {content.deleteStream.underline}
                                    </Typography>
                                    <div>
                                        <Button
                                            className={classes.button}
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            onClick={openDeleteStream}
                                        >
                                            {content.deleteStream.cancel}
                                        </Button>
                                        <Button
                                            className={classes.button}
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={deleteStream}
                                        >
                                            {content.deleteStream.submit}
                                        </Button>
                                    </div>
                                </ContentContainer>
                            </Collapse>
                        </Grid>
                        <StreamOverview stream={stream} enableSecure={enableSecure} />
                    </Grid>
                    :
                    <CouldNotFind
                        title={content.playout.notFound.title}
                        underline={content.playout.notFound.underline}
                        to={link.STREAMS}
                        redirectLabel="Back to streamlist"
                    />
            }
        </div >
    )
}